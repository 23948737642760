import { createContext } from "react";
export const SelectedContentHubListContext = createContext<{
  selectedSite?: string;
  selectedList?: string;
  selectedItem?: string;
  selectedView?: string;
  setSelectedSite: (siteName?: string) => void;
  setSelectedList: (listName?: string) => void;
  setSelectedItem: (itemId?: string) => void;
  setSelectedView: (viewId?: string) => void;
}>({
  setSelectedItem: () => {},
  setSelectedList: () => {},
  setSelectedSite: () => {},
  setSelectedView: () => {},
});
