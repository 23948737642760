import { ItemChangeSet } from "../../../Types/ContentHub/ContentHubItemElement";
import {
  OverlayDrawer,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerBody,
  Button,
  Caption1,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { ReactNode, useContext, useEffect, useState } from "react";
import { ContentHubListsElement } from "../../../Types/ContentHub/ContentHubListsElement";
import { formatDate } from "../../../Utils/Utils";
import { ContentHubUser } from '../../../Types/General/ContentHubUser';

import "./ContentHubItemHistory.css";
import * as UserService from "../../../Services/General/UserService";
import { TeamsFxContext } from "../../../Context/TeamsFxContext";
import { ContentHubAttachments } from "../../../Types/ContentHub/ContentHubAttachments";

export const ContentHubItemHistory = ({
  listDefinition,
  itemChanges,
  historyOpen,
  onClosedDrawer,
}: {
  listDefinition: ContentHubListsElement;
  itemChanges: ItemChangeSet[];
  historyOpen: boolean;
  onClosedDrawer: () => void;
}) => {
  const [userMap, setUserMap] = useState<{ [key: string]: ContentHubUser }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      const users: { [key: string]: ContentHubUser } = {};

      // Fetch user details for each item change
      for (const change of itemChanges) {
        if (change.userId && !users[change.userId]) {
          const user = await UserService.getUserById(
            change.userId,
            teamsUserCredential!
          );
          users[change.userId] = user;
        }
      }
      setUserMap(users);
      setLoading(false);
    };

    if (itemChanges.length > 0) {
      fetchUserDetails();
    }
  }, [itemChanges]);

  const closeDrawer = () => {
    onClosedDrawer();
  };

  return (
    <OverlayDrawer
      size="large"
      position="start"
      modalType="modal"
      open={historyOpen}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular></Dismiss24Regular>}
              onClick={() => {
                closeDrawer();
              }}
            ></Button>
          }
        >
          Item History
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>
        <Accordion multiple>
          {itemChanges.map((changes: ItemChangeSet) => {
            const user = userMap[changes.userId]; // Retrieve user details from the map
            return (
              <AccordionItem
                className="accordionItem"
                value={changes.version}
                key={changes.version}
              >
                <AccordionHeader>
                  <div>
                    <div>Version: {changes.version}</div>
                    <div>
                      <Caption1>
                        Date: {formatDate(changes.timestamp)}{" "}
                      </Caption1>
                    </div>
                    <div>
                      Modified By:{" "}
                      {loading ? (
                        <Skeleton>
                          <SkeletonItem size={16} />
                        </Skeleton>
                      ) : (
                        user?.name || changes.userId
                      )}
                    </div>
                  </div>
                </AccordionHeader>
                <AccordionPanel>
                  <div className="accordionPanel">
                    {changes.changes.map((fieldChange) => {
                      const field = listDefinition.fields.find(
                        (x) => x.id === fieldChange.fieldId
                      );

                      switch (fieldChange.type) {
                        case "string":
                          return (
                            <div
                              className="fieldValue"
                              key={fieldChange.fieldId}
                            >
                              <b>{field?.displayName}</b>: {fieldChange.value as string}
                            </div>
                          );

                        case "datetime":
                          return (
                            <div
                              className="fieldValue"
                              key={fieldChange.fieldId}
                            >
                              <b>{field?.displayName}</b>:{" "}
                              {formatDate(fieldChange.value as Date)}
                            </div>
                          );

                        case "person":
                          if (fieldChange.value) {
                            return (
                              <div
                                className="fieldValue"
                                key={fieldChange.fieldId}
                              >
                                <b>{field?.displayName}</b>:{" "}
                                {(fieldChange.value as ContentHubUser).name}
                              </div>
                            );
                          }

                          break;

                        case "person[]":
                          return <div key={fieldChange.fieldId}></div>;

                        case "attachments": {
                          const casted =
                            fieldChange.value as ContentHubAttachments;
                          return (
                            <div key={fieldChange.fieldId}>
                              <div>
                                <b>
                                  Attachments Added:{" "}
                                  {casted?.filenames &&
                                  casted.filenames.length > 0
                                    ? casted.filenames[0].toString()
                                    : "None"}
                                </b>
                              </div>
                              <div></div>
                            </div>
                          );
                        }
                      }
                      return (
                        <div className="fieldValue" key={fieldChange.fieldId}>
                          <b>{field?.displayName}</b>: {fieldChange.value as ReactNode}
                        </div>
                      );
                    })}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </DrawerBody>
    </OverlayDrawer>
  );
};
