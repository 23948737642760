import { Button, Dropdown, Input, Option } from "@fluentui/react-components";
import {
  BooleanOperatorSearchFilter,
  IContentHubSearchFilter,
  StringSearchFilter,
  StringSearchTypes,
} from "../../../Types/ContentHub/ContentHubSearchFilter";
import {
  AddFilled,
  BackspaceRegular,
  BranchFilled,
  DeleteRegular,
  FilterAddRegular,
} from "@fluentui/react-icons";
import { useCallback, useEffect, useState } from "react";

export const StringFilterEditor = ({
  operator,
  initialValue,
  removable = false,
  valueChanged,
  valueType,
  options = undefined,
}: {
  operator?: "And" | "Or";
  removable?: boolean;
  initialValue?: IContentHubSearchFilter | null;
  valueChanged?: (filterVal: IContentHubSearchFilter | null) => void;
  valueType: string;
  options?: string[];
}) => {
  const [fieldText, setFieldText] = useState<string>("");

  const [dropdownVal, setDropdownVal] = useState<StringSearchTypes>(
    StringSearchTypes.IsExactly
  );

  const [currentFilter, setCurrentFilter] = useState<IContentHubSearchFilter>(
    initialValue ??
      ({
        type: "operator",
        operator: operator ?? "Or",
        filters: [],
      } as BooleanOperatorSearchFilter)
  );
  const addItem = useCallback(
    (f: IContentHubSearchFilter) => {
      const casted = currentFilter as BooleanOperatorSearchFilter;
      casted.filters.push(f);
      setCurrentFilter({ ...casted });
      setFieldText("");
    },
    [currentFilter]
  );

  const removeItem = useCallback((index: number) => {
    setCurrentFilter((f) => {
      const v = f as BooleanOperatorSearchFilter;
      v.filters.splice(index, 1);
      return { ...v };
    });
  }, []);

  useEffect(() => {
    if (currentFilter.type !== "operator") {
      setCurrentFilter(
        (cf) =>
          ({
            operator: "And",
            type: "operator",
            filters: [cf],
          } as BooleanOperatorSearchFilter)
      );
    } else {
      valueChanged?.(currentFilter);
    }
  }, [currentFilter, valueChanged]);

  const dropdownToString = (value: StringSearchTypes) => {
    switch (value) {
      case StringSearchTypes.IsExactly:
        return "Is exactly";
      case StringSearchTypes.Contains:
        return "Contains";
      case StringSearchTypes.StartsWith:
        return "Starts With";
      case StringSearchTypes.EndsWith:
        return "Ends With";
      default:
        return "";
    }
  };

  const convertOperatorToString = (op: string) => {
    switch (op) {
      case "And":
        return "Meet all conditions";
      case "Or":
        return "Meet one or more conditions";
      default:
        return "";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--colorNeutralBackground1)",
        gap: 5,
      }}
    >
      <div style={{ gap: 5, display: "inline-flex" }}>
        {removable && (
          <Button
            size="small"
            icon={<DeleteRegular />}
            title="Remove filter"
            onClick={() => valueChanged?.(null)}
          />
        )}
        <Dropdown
          size="small"
          defaultValue={convertOperatorToString(
            (currentFilter as BooleanOperatorSearchFilter).operator ?? "Or"
          )}
          onOptionSelect={(_e, d) => {
            setCurrentFilter(
              (f) =>
                ({
                  ...f,
                  operator: d.optionValue,
                } as BooleanOperatorSearchFilter)
            );
          }}
        >
          <Option value="Or">{convertOperatorToString("Or")}</Option>
          <Option value="And">{convertOperatorToString("And")}</Option>
        </Dropdown>
      </div>
      <div
        style={{
          borderLeft: "solid",
          gap: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ flex: 1, paddingLeft: 10 }}>
          {currentFilter.type === "operator" &&
            (currentFilter as BooleanOperatorSearchFilter).filters.map(
              (f, i) => {
                if (f.type === "operator") {
                  const op = (f as BooleanOperatorSearchFilter).operator;

                  return (
                    <StringFilterEditor
                      key={i}
                      initialValue={f}
                      operator={op}
                      removable={true}
                      options={options}
                      valueType={valueType}
                      valueChanged={(newVal) => {
                        if (newVal) {
                          setCurrentFilter((f) => {
                            const newV = f as BooleanOperatorSearchFilter;
                            newV.filters[i] = newVal;
                            return { ...newV };
                          });
                        } else {
                          removeItem(i);
                        }
                      }}
                    ></StringFilterEditor>
                  );
                } else if (f.type === valueType) {
                  const filterType = (f as StringSearchFilter).searchType;
                  return (
                    <div key={i}>
                      <Button
                        size="small"
                        icon={<DeleteRegular />}
                        title="Remove filter"
                        onClick={() => removeItem(i)}
                      />{" "}
                      {dropdownToString(filterType).toLowerCase()}{" "}
                      <q>{(f as StringSearchFilter).term}</q>
                    </div>
                  );
                }
                return undefined;
              }
            )}
        </div>
        <div
          style={{ flex: 1, display: "inline-flex", gap: 5, marginLeft: 10 }}
        >
          <Button
            size="small"
            icon={<FilterAddRegular />}
            title="Add filter"
            onClick={() => {
              addItem({
                searchType: dropdownVal,
                term: fieldText,
                type: valueType,
              } as StringSearchFilter);
            }}
          />
          <Button
            size="small"
            icon={<BranchFilled />}
            title="Add condition group"
            onClick={() => {
              addItem({
                type: "operator",
                operator: "Or",
                filters: [],
              } as BooleanOperatorSearchFilter);
            }}
          />
          {options === undefined && (
            <>
              <Dropdown
                style={{ minWidth: "fit-content", width: 100 }}
                size="small"
                defaultValue={dropdownToString(dropdownVal)}
                onOptionSelect={(_e, d) =>
                  setDropdownVal(d.optionValue as StringSearchTypes)
                }
              >
                {Object.values(StringSearchTypes).map((t) => (
                  <Option key={t} value={t}>
                    {dropdownToString(t)}
                  </Option>
                ))}
              </Dropdown>
              <Input
                type="text"
                placeholder="Search terms"
                style={{ flex: 1 }}
                value={fieldText}
                size="small"
                onChange={(v) => {
                  setFieldText(v.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter")
                    addItem({
                      searchType: dropdownVal,
                      term: fieldText,
                      type: valueType,
                    } as StringSearchFilter);
                }}
              />
            </>
          )}
          {options && (
            <>
              <Dropdown
                style={{ minWidth: "fit-content", flex: 1 }}
                size="small"
                onOptionSelect={(_e, d) => setFieldText(d.optionValue ?? "")}
                value={fieldText}
                clearable
              >
                {options.map((t, i) => (
                  <Option key={i} value={t}>
                    {t}
                  </Option>
                ))}
              </Dropdown>
            </>
          )}
          <Button
            size="small"
            icon={<BackspaceRegular />}
            title="Clear"
            onClick={() => {
              setFieldText("");
            }}
          />
          <Button
            size="small"
            icon={<AddFilled />}
            title="Add filter"
            disabled={
              options && options.find((o) => o === fieldText) === undefined
                ? true
                : false
            }
            onClick={() => {
              addItem({
                searchType: dropdownVal,
                term: fieldText,
                type: valueType,
              } as StringSearchFilter);
            }}
          />
        </div>
      </div>
    </div>
  );
};
