import { useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "../Context/TeamsFxContext";
import { User } from "../Types/General/User";
import {
  getUserMembershipIds,
  callUserInfoFunction,
} from "../Services/userInfo";

const useWorkflowsUser = () => {
  const [needConsent, setNeedConsent] = useState(false);
  const [userInfo, setUserInfo] = useState<User>();
  const [membershipIds, setMembershipIds] = useState<string[]>([]);
  const [errorResponse, setErrorResponse] = useState<Error>();
  const { teamsUserCredential } = useContext(TeamsFxContext);

  const fetchUserInfo = async () => {
    try {
      if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
      }
      if (needConsent) {
        await teamsUserCredential!.login(["User.Read"]);
        setNeedConsent(false);
      }
      try {
        const functionRes = await callUserInfoFunction(teamsUserCredential);
        const memberships = await getUserMembershipIds(teamsUserCredential);

        setUserInfo(functionRes);
        setMembershipIds(memberships);
        return functionRes;
      } catch (error) {
        if (
          (error as Error).message.includes(
            "The application may not be authorized."
          )
        ) {
          setNeedConsent(true);
        } else {
          throw error;
        }
      }
    } catch (error) {
      setErrorResponse(error as Error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return {
    userInfo: userInfo,
    error: errorResponse,
    membershipIds: membershipIds,
  };
};

export default useWorkflowsUser;
