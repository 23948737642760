const ApplicationConfiguration = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  apiName: process.env.REACT_APP_FUNC_NAME,
  version: process.env.VERSION,
  appId: process.env.REACT_APP_ID,
};

export default ApplicationConfiguration;
