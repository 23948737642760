import {
  BreadcrumbItem,
  Menu,
  MenuTrigger,
  BreadcrumbButton,
  MenuPopover,
  MenuList,
  MenuItem,
  makeStyles,
  tokens,
  MenuProps,
  Input,
} from "@fluentui/react-components";
import { CheckmarkRegular, EditRegular } from "@fluentui/react-icons";
import { useId, useState } from "react";
const useBreadcrumbDropdownStyles = makeStyles({
  placeholder: {
    color: tokens.colorNeutralForeground3,
  },
});
type OptionType<T = unknown> = { value: T; displayText: string };
export const BreadcrumbDropdown = ({
  open,
  options,
  placeholder,
  onOpenChange,
  selectedOption,
  onOptionSelected,
  editable,
  onEdit,
}: {
  open?: boolean;
  options: OptionType[];
  placeholder?: string;
  onOpenChange?: (open: boolean) => void;
  selectedOption?: OptionType;
  onOptionSelected?: (selectedOption: OptionType | undefined) => void;
  editable?: boolean;
  onEdit?: (newValue: OptionType) => void;
}) => {
  const classes = useBreadcrumbDropdownStyles();
  const onOpenChangeHandler: MenuProps["onOpenChange"] = (_e, data) => {
    onOpenChange?.(data.open);
  };

  const [editionMode, setEditionMode] = useState<boolean>(false);
  const inputFieldId = useId();

  return (
    <BreadcrumbItem>
      {editionMode === false && (
        <Menu open={open} onOpenChange={onOpenChangeHandler}>
          <MenuTrigger>
            {selectedOption !== undefined ? (
              <BreadcrumbButton>{selectedOption.displayText}</BreadcrumbButton>
            ) : (
              <BreadcrumbButton className={classes.placeholder}>
                {placeholder}
              </BreadcrumbButton>
            )}
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {options.map((s, i) => (
                <MenuItem key={i} onClick={() => onOptionSelected?.(s)}>
                  {s.displayText}
                </MenuItem>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      )}
      {selectedOption !== undefined &&
        editable === true &&
        editionMode === false && (
          <BreadcrumbButton
            icon={<EditRegular />}
            onClick={() => setEditionMode(true)}
          ></BreadcrumbButton>
        )}
      {selectedOption !== undefined &&
        editable === true &&
        editionMode === true && (
          <>
            <form
              style={{ margin: 0 }}
              onSubmit={(e) => {
                e.preventDefault();
                const fieldValue = e.currentTarget.elements.namedItem(
                  inputFieldId
                ) as HTMLInputElement;
                onEdit?.({
                  ...selectedOption,
                  displayText: fieldValue.value,
                });
                setEditionMode(false);
              }}
            >
              <Input
                type="text"
                id={inputFieldId}
                defaultValue={selectedOption.displayText}
                autoFocus
              />
              <BreadcrumbButton
                type="submit"
                icon={<CheckmarkRegular />}
              ></BreadcrumbButton>
            </form>
          </>
        )}
    </BreadcrumbItem>
  );
};
