import { useEffect, useState } from "react";
import { ContentHubListsElement } from "../Types/ContentHub/ContentHubListsElement";

export function useContentHubChoiceField(list: ContentHubListsElement, fieldName: string) {
    const [options, setOptions] = useState<string[]>([]);
    const [dropdownOptions, setDropdownOptions] = useState<{ label: string, value: string }[]>([]);
    useEffect(() => {
        const values = list.fields.find(f => f.name === fieldName)?.options ?? [];
        setOptions(values);
        setDropdownOptions(values.map(v => ({ label: v, value: v })));
    }, [list, fieldName])

    return { options, dropdownOptions };
}