import { useReducer } from "react"
import { ContentHubItemElement } from "../Types/ContentHub/ContentHubItemElement"
import { PropertySort } from "../Types/ContentHub/PropertySort"
import { SearchItemsRequestFilter } from "../Types/ContentHub/SearchItemsRequest"
export type ContentHubListState = ({
    siteId?: string,
    listId?: string,
    initialized: false
} | {
    siteId: string,
    listId: string,
    initialized: true
}) & {
    viewId?: string,
    searchTerms?: string,
    filters?: (SearchItemsRequestFilter & { viewFilter?: boolean })[],
    sort?: PropertySort[],
    continuationString?: string
    items: ContentHubItemElement[],
    errors: { activity?: ContentHubListState["activity"], error: string }[]
} & ({
    activity?: undefined
    activityArguments?: undefined
} | {
    activity: ActionStartSetItemsActivity["activity"],
    activityArguments?: undefined
} | {
    activity: ActionStartCreateItemActivity["activity"]
    activityArguments: ActionStartCreateItemActivity["arguments"]
} | {
    activity: ActionStartUpdateItemActivity["activity"]
    activityArguments: ActionStartUpdateItemActivity["arguments"]
} | {
    activity: ActionStartDeleteItemActivity["activity"]
    activityArguments: ActionStartDeleteItemActivity["arguments"]
} | {
    activity: ActionStartInitializeActivity["activity"]
    activityArguments: ActionStartInitializeActivity["arguments"]
})
export const DefaultContentHubListState: ContentHubListState = {
    items: [],
    initialized: false,
    errors: []
}
type ActionInitialized = {
    type: "initialized"
}
type ActionClearItems = {
    type: "clearItems"
    errors?: ContentHubListState["errors"]
}
type ActionClearErrors = {
    type: "clearErrors"
}

type ActionSetErrors = {
    type: "setErrors",
    errors: { activity?: ContentHubListState["activity"], error: string }[],
}

type ActionSetView = {
    type: "setView",
    viewId?: string
    errors?: ContentHubListState["errors"]
}

type ActionSetParameters = {
    type: "setParameters",
    filters?: ContentHubListState["filters"],
    searchTerms?: string,
    sort?: PropertySort[]
    errors?: ContentHubListState["errors"]
}

type ActionAddItems = {
    type: "addItems",
    items: ContentHubItemElement[],
    continuationString?: string,
    prepend?: boolean
    errors?: ContentHubListState["errors"]
}

type ActionDeleteItem = {
    type: "deleteItem",
    itemId: string
    errors?: ContentHubListState["errors"]
}

type ActionUpdateItem = {
    type: "updateItem",
    item: ContentHubItemElement
    errors?: ContentHubListState["errors"]
}

type ActionSetItems = {
    type: "setItems",
    items: ContentHubItemElement[],
    continuationString?: string,
    errors?: ContentHubListState["errors"]
}

type ActionStartInitializeActivity = {
    type: "startActivity",
    activity: "initializing",
    arguments: {
        siteId: string,
        listId: string
    }
    errors?: ContentHubListState["errors"]
}


type ActionStartSetItemsActivity = {
    type: "startActivity",
    activity: "settingItems" | "addingItems"
    errors?: ContentHubListState["errors"]
}

type ActionStartCreateItemActivity = {
    type: "startActivity",
    activity: "creatingItem"
    arguments: {

        fields: Record<string, unknown>,
        files?: File[]
    }
    errors?: ContentHubListState["errors"]
}
type ActionStartUpdateItemActivity = {
    type: "startActivity",
    activity: "updatingItem"
    arguments: {
        itemId: string,
        fields: Record<string, unknown>,
        files?: File[]
    }
    errors?: ContentHubListState["errors"]
}
type ActionStartDeleteItemActivity = {
    type: "startActivity",
    activity: "deletingItem"
    arguments: {
        itemId: string
    }
    errors?: ContentHubListState["errors"]
}

export type ContentHubListReducerAction = ActionInitialized | ActionClearItems | ActionSetParameters | ActionAddItems | ActionSetItems | ActionDeleteItem | ActionUpdateItem | ActionStartSetItemsActivity | ActionStartCreateItemActivity | ActionStartUpdateItemActivity | ActionStartDeleteItemActivity | ActionSetView | ActionStartInitializeActivity | ActionSetErrors | ActionClearErrors;
export const useContentHubListReducer = () => {


    const reducer = (state: ContentHubListState, action: ContentHubListReducerAction): ContentHubListState => {
        switch (action.type) {
            case "initialized":
                if (state.activity !== "initializing") return { ...state };
                return { ...state, initialized: true, listId: state.activityArguments.listId, siteId: state.activityArguments.siteId, errors: [] }
            case "setView":
                return { ...state, viewId: action.viewId, errors: action.errors ?? [] }
            case "clearErrors":
                return { ...state, activity: undefined, activityArguments: undefined, errors: [] }
            case "setErrors":
                return { ...state, activity: undefined, activityArguments: undefined, errors: action.errors }
            case "clearItems":
                return {
                    ...state,
                    items: [],
                    continuationString: undefined,
                    activity: undefined,
                    activityArguments: undefined,
                    errors: action.errors ?? []
                }
            case "addItems":
                return {
                    ...state,
                    items: action.prepend === true ? [...action.items, ...state.items] : [...state.items, ...action.items],
                    continuationString: action.continuationString,
                    activity: undefined,
                    activityArguments: undefined,
                    errors: action.errors ?? []
                }
            case "setParameters":
                return {
                    ...state,
                    searchTerms: action.searchTerms,
                    filters: action.filters,
                    sort: action.sort,
                    activity: undefined,
                    activityArguments: undefined,
                    errors: action.errors ?? []
                }
            case "setItems":
                return {
                    ...state,
                    items: [...action.items],
                    continuationString: action.continuationString,
                    activity: undefined,
                    activityArguments: undefined,
                    errors: action.errors ?? []
                }
            case "deleteItem":
                return {
                    ...state,
                    items: state.items.filter(f => f.id !== action.itemId),
                    activity: undefined,
                    activityArguments: undefined,
                    errors: action.errors ?? []
                }
            case "updateItem":
                return {
                    ...state,
                    items: state.items.map(i => i.id !== action.item.id ? i : action.item),
                    activity: undefined,
                    activityArguments: undefined,
                    errors: action.errors ?? []
                }
            case "startActivity":
                switch (action.activity) {
                    case "addingItems":
                    case "settingItems":
                        return {
                            ...state,
                            activity: action.activity,
                            activityArguments: undefined,
                            errors: action.errors ?? []
                        }
                    case "initializing":
                        return {
                            ...DefaultContentHubListState,
                            initialized: false,
                            activity: action.activity,
                            activityArguments: action.arguments,
                            siteId: undefined,
                            listId: undefined,
                            errors: action.errors ?? []
                        }
                    case "creatingItem":
                        return {
                            ...state,
                            activity: action.activity,
                            activityArguments: action.arguments,
                            errors: action.errors ?? []
                        }
                    case "updatingItem":
                        return {
                            ...state,
                            activity: action.activity,
                            activityArguments: action.arguments,
                            errors: action.errors ?? []
                        }
                    case "deletingItem":
                        return {
                            ...state,
                            activity: action.activity,
                            activityArguments: action.arguments,
                            errors: action.errors ?? []
                        }
                    default:
                        return { ...state }
                }

            default:
                return { ...state }
        }
    }

    return useReducer(reducer, { ...DefaultContentHubListState })
}