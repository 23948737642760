import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ContentHubSiteElement } from "../Types/ContentHub/ContentHubSiteElement";
import { ContentHubListsElement } from "../Types/ContentHub/ContentHubListsElement";
import { TeamsFxContext } from "./TeamsFxContext";
import * as ContentHubService from "../Services/ContentHub/ContentHubService";
import * as axios from "axios";
import { ListViewDefinition } from "../Types/ContentHub/ListViewDefinition";

export const ContentHubContext = createContext<{
  sites: ContentHubSiteElement[];
  lists: Record<string, ContentHubListsElement[]>;
  views: Record<string, ListViewDefinition[]>;
  permissions: string[];
  isLoading: boolean;
  error?: Error;
  reload: () => void;
}>({
  sites: [],
  lists: {},
  views: {},
  permissions: [],
  isLoading: true,
  reload: () => {},
});

export const ContentHubContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contentHubSites, setSites] = useState<ContentHubSiteElement[]>([]);
  const [contentHubLists, setLists] = useState<
    Record<string, ContentHubListsElement[]>
  >({});
  const [contentHubListViews, setListViews] = useState<
    Record<string, ListViewDefinition[]>
  >({});
  const [contentHubPermissions, setPermissions] = useState<string[]>([]);
  useEffect(() => {
    if (teamsUserCredential) {
      setIsLoading(true);
    }
  }, [teamsUserCredential]);

  useEffect(() => {
    try {
      if (isLoading === false) return;
      if (!teamsUserCredential) {
        throw new Error("User is not authenticated");
      }
      (async () => {
        try {
          const accessibleResources =
            await ContentHubService.getAccessibleResources(teamsUserCredential);

          const lists: Record<string, ContentHubListsElement[]> = {};
          for (const site of accessibleResources.sites) {
            lists[site.id] = accessibleResources.lists.filter(
              (l) => l.siteId === site.id
            );
          }

          const views: Record<string, ListViewDefinition[]> = {};

          for (const list of accessibleResources.lists) {
            views[list.id] = accessibleResources.views.filter(
              (v) => v.listId === list.id
            );
          }

          setPermissions(accessibleResources.permissions);
          setSites(accessibleResources.sites);
          setLists(lists);
          setListViews(views);
          setError(undefined);
        } catch (err) {
          if (axios.default.isAxiosError(err)) {
            let funcErrorMsg = "";

            if (err?.code === "ERR_NETWORK") {
              funcErrorMsg = "Unable to retrieve sites and lists.";
            } else {
              funcErrorMsg = err.message;
              if (err.response?.data?.error) {
                funcErrorMsg += ": " + err.response.data.error;
              }
            }

            throw new Error(funcErrorMsg);
          }
          throw err;
        } finally {
          setIsLoading(false);
        }
      })();
    } catch (err) {
      setSites([]);
      setLists({});
      setListViews({});
      setError(err as Error);
      setIsLoading(false);
    }
  }, [isLoading]);
  return (
    <ContentHubContext.Provider
      value={{
        sites: contentHubSites,
        lists: contentHubLists,
        views: contentHubListViews,
        isLoading: isLoading,
        permissions: contentHubPermissions,
        error: error,
        reload: () => setIsLoading(true),
      }}
    >
      {children}
    </ContentHubContext.Provider>
  );
};
