import { ObjectSchema, number, object, string } from "yup";
import {
  ContentHubAttachments, ContentHubAttachmentsSchema
} from "../../../Types/ContentHub/ContentHubAttachments";
import { ContentHubUser } from '../../../Types/General/ContentHubUser';
import { counterpartyToSingleValue } from "../../../Types/General/Counterparty";
import { dropDownToString } from "../../../Types/General/DropDownData";
import {
  ContentHubUserSchema,
} from "../../../Types/General/User";

//export type BalanaceTransferReviewStatus = 'Pending' | 'Approved' | 'Revision Required';
export type BalanceTransferYesNo = "Yes" | "No";
export type BalanceTransferStatusOptions =
  | "Pending"
  | "Ready for Credit Approval"
  | "Ready for Billing Analyst"
  | "Ready for Billing Manager"
  | "Billing Manager Approved"
  | "Completed"
  | "Denied"
  | "Revision Required";

export interface BalanceTransfer {
  requestor: ContentHubUser;
  counterpartyFrom: string;
  accountNumberFrom: string;
  transactionAmountFrom: number;
  counterpartyTo: string;
  accountNumberTo: string;
  transactionAmountTo: number;
  requestorComments: string;
  status: string;
  clientApprovalAttached: BalanceTransferYesNo;
  creditDocumentationAttached: BalanceTransferYesNo;
  balanceTransferTemplateAttached: BalanceTransferYesNo;
  finalCustomer: BalanceTransferYesNo;
  billingAnalyst: ContentHubUser | null;
  _attachments?: ContentHubAttachments | null;
  _workflowStep: string;
}

export const BalanceTransferSchema: ObjectSchema<BalanceTransfer> = object({
  requestor: ContentHubUserSchema,
  counterpartyFrom: string()
    .defined()
    .required("Select Counterparty")
    .transform((value) => counterpartyToSingleValue(value, "name")),
  accountNumberFrom: string()
    .defined()
    .required("Provide account number")
    .matches(/^\d+$/, { message: "Value must be numeric." }),
  transactionAmountFrom: number()
    .required("Provide transaction amount")
    //.min(1, "Please provide a value above 0.00")
    .default(0),
  counterpartyTo: string()
    .defined()
    .required("Select Counterparty")
    .transform((value) => counterpartyToSingleValue(value, "name")),
  accountNumberTo: string()
    .defined()
    .required("Provide account number")
    .matches(/^\d+$/, { message: "Value must be numeric." }),
  transactionAmountTo: number()
    .required("Provide transaction amount")
    //.min(1, "Please provide a value above 0.00")
    .default(0),
  requestorComments: string().defined().required("Add Comments"),
  status: string<BalanceTransferStatusOptions>()
    .required()
    .default("Ready for Credit Approval"),
  clientApprovalAttached: string<BalanceTransferYesNo>()
    .required("Select an option")
    .transform((value) => dropDownToString(value))
    .matches(/(Yes)/, "Client Approval must be attached"),
  creditDocumentationAttached: string<BalanceTransferYesNo>()
    .required("Select an option")
    .transform((value) => dropDownToString(value))
    .matches(/(Yes)/, "Credit Documentation must be attached"),
  balanceTransferTemplateAttached: string<BalanceTransferYesNo>()
    .required("Select an option")
    .transform((value) => dropDownToString(value))
    .matches(/(Yes)/, "Balance Transfer Template must be attached"),
  finalCustomer: string<BalanceTransferYesNo>()
    .required("Select an option")
    .transform((value) => dropDownToString(value)),
  billingAnalyst: ContentHubUserSchema.nullable(),
  _attachments: ContentHubAttachmentsSchema.required("Please add attachments"),
  _workflowStep: string().defined().default("NewRequest"),
});
