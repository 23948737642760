import { ComparableTypeFilterEditor } from "../../../Components/ContentHub/ContentHubSearchControls/ComparableTypeFilterEditor";
import { PersonFilterEditor } from "../../../Components/ContentHub/ContentHubSearchControls/PersonFilterEditor";
import { StringFilterEditor } from "../../../Components/ContentHub/ContentHubSearchControls/StringFilterEditor";
import { ContentHubItemElement } from "../../../Types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../Types/ContentHub/ContentHubListsElement";
import { SortingDirection } from "../../../Types/ContentHub/PropertySort";
import { ContentHubListRegistry } from "../../../Types/ContentHub/Registry/ContentHubListRegistry";
import { ContentHubUser } from "../../../Types/General/ContentHubUser";
import { formatCurrency, formatDate } from "../../../Utils/Utils";
import { BalanceTransfer, BalanceTransferSchema } from "./BalanceTransfer";
import { BalanceTransferForm } from "./BalanceTransferForm";

export const BalanceTransferListRegistry: ContentHubListRegistry<BalanceTransfer> =
  {
    name: "BalanceTransfer",
    header: "Balance Transfer",
    schema: BalanceTransferSchema,
    form: BalanceTransferForm,
    columns: [
      { key: "id", displayName: "ID", visible: false },
      {
        key: "sequentialId",
        displayName: "ID",
        style: { width: "50px" },
        sort: { propertyName: "sequentialId" },
        filter: {
          propertyName: "sequentialId",
          renderer(currentValue, callback) {
            return (
              <ComparableTypeFilterEditor
                initialValue={currentValue}
                valueType="int"
                valueChanged={(v) => {
                  callback("sequentialId", v);
                }}
              ></ComparableTypeFilterEditor>
            );
          },
        },
      },
      {
        key: "counterpartyFrom",
        displayName: "Counterparty (From)",
        style: { width: "50px" },
        sort: { propertyName: "counterpartyFrom" },
        filter: {
          propertyName: "fields.counterpartyFrom",
          renderer(currentValue, callback, list) {
            return (
              <StringFilterEditor
                initialValue={currentValue}
                valueType={
                  list.fields.find((f) => f.name === "counterpartyFrom")!.type
                }
                valueChanged={(v) => {
                  callback("counterpartyFrom", v);
                }}
              ></StringFilterEditor>
            );
          },
        },
      },
      {
        key: "accountNumberFrom",
        displayName: "Account Number (From)",
        style: { width: "50px" },
        sort: { propertyName: "accountNumberFrom" },
        filter: {
          propertyName: "fields.accountNumberFrom",
          renderer(currentValue, callback, list) {
            return (
              <StringFilterEditor
                initialValue={currentValue}
                valueType={
                  list.fields.find((f) => f.name === "accountNumberFrom")!.type
                }
                valueChanged={(v) => {
                  callback("accountNumberFrom", v);
                }}
              ></StringFilterEditor>
            );
          },
        },
      },
      {
        key: "counterpartyTo",
        displayName: "Counterparty (To)",
        style: { width: "50px" },

        sort: { propertyName: "counterpartyTo" },
        filter: {
          propertyName: "fields.counterpartyTo",
          renderer(currentValue, callback, list) {
            return (
              <StringFilterEditor
                initialValue={currentValue}
                valueType={
                  list.fields.find((f) => f.name === "counterpartyTo")!.type
                }
                valueChanged={(v) => {
                  callback("counterpartyTo", v);
                }}
              ></StringFilterEditor>
            );
          },
        },
      },
      {
        key: "accountNumberTo",
        displayName: "Account Number (To)",
        style: { width: "50px" },

        sort: { propertyName: "accountNumberTo" },
        filter: {
          propertyName: "fields.accountNumberTo",
          renderer(currentValue, callback, list) {
            return (
              <StringFilterEditor
                initialValue={currentValue}
                valueType={
                  list.fields.find((f) => f.name === "accountNumberTo")!.type
                }
                valueChanged={(v) => {
                  callback("accountNumberTo", v);
                }}
              ></StringFilterEditor>
            );
          },
        },
      },
      {
        key: "transactionAmountFrom",
        displayName: "Transaction Amount (From)",
        style: { width: "50px" },

        sort: { propertyName: "transactionAmountFrom" },
        filter: {
          propertyName: "fields.transactionAmountFrom",
          renderer(currentValue, callback, list) {
            return (
              <ComparableTypeFilterEditor
                initialValue={currentValue}
                valueType={
                  list.fields.find((f) => f.name === "transactionAmountFrom")!
                    .type
                }
                valueChanged={(v) => {
                  callback("transactionAmountFrom", v);
                }}
              ></ComparableTypeFilterEditor>
            );
          },
        },
      },
      {
        key: "transactionAmountTo",
        displayName: "Transaction Amount (To)",
        style: { width: "50px" },

        sort: { propertyName: "transactionAmountTo" },
        filter: {
          propertyName: "fields.transactionAmountTo",
          renderer(currentValue, callback, list) {
            return (
              <ComparableTypeFilterEditor
                initialValue={currentValue}
                valueType={
                  list.fields.find((f) => f.name === "transactionAmountTo")!
                    .type
                }
                valueChanged={(v) => {
                  callback("transactionAmountTo", v);
                }}
              ></ComparableTypeFilterEditor>
            );
          },
        },
      },
      {
        key: "status",
        displayName: "Status",
        style: { width: "50px" },
        sort: {
          propertyName: "status",
        },
        filter: {
          propertyName: "fields.status",
          renderer(currentValue, callback, list) {
            return (
              <StringFilterEditor
                initialValue={currentValue}
                valueType={list.fields.find((f) => f.name === "status")!.type}
                options={
                  list.fields.find((f) => f.name === "status")!.options ??
                  undefined
                }
                valueChanged={(v) => {
                  callback("status", v);
                }}
              ></StringFilterEditor>
            );
          },
        },
      },
      {
        key: "requestor",
        displayName: "Requestor",
        style: { width: "50px" },
        sort: {
          propertyName: "requestor.name",
        },
        filter: {
          propertyName: "fields.requestor",
          renderer(currentValue, callback, list) {
            return (
              <PersonFilterEditor
                initialValue={currentValue}
                valueType={
                  list.fields.find((f) => f.name === "requestor")!.type
                }
                valueChanged={(v) => {
                  callback("requestor", v);
                }}
              ></PersonFilterEditor>
            );
          },
        },
      },
      {
        key: "createdDate",
        displayName: "Created Date",
        style: { width: "50px" },
        sort: {
          propertyName: "createdDate",
          defaultSortIndex: 1,
          defaultSortingDirection: SortingDirection.Descending,
        },
        filter: {
          propertyName: "createdDate",
          renderer(currentValue, callback) {
            return (
              <ComparableTypeFilterEditor
                initialValue={currentValue}
                valueType="datetime"
                valueChanged={(v) => {
                  callback("createdDate", v);
                }}
              ></ComparableTypeFilterEditor>
            );
          },
        },
      },
    ],
    mapper: function (
      item: ContentHubItemElement<BalanceTransfer>,
      list: ContentHubListsElement
    ) {
      const requestor = item.fields.requestor.value as ContentHubUser;
      return {
        id: item.id,
        sequentialId: `${list.sequentialIdPrefix ?? ""}${item.sequentialId}`,
        counterpartyFrom: item.fields.counterpartyFrom.value,
        accountNumberFrom: item.fields.accountNumberFrom.value,
        transactionAmountFrom: formatCurrency(
          item.fields.transactionAmountFrom.value
        ),
        counterpartyTo: item.fields.counterpartyTo.value,
        accountNumberTo: item.fields.accountNumberTo.value,
        transactionAmountTo: formatCurrency(
          item.fields.transactionAmountTo.value
        ),
        status: item.fields.status.value,
        requestor: requestor.name,
        createdDate: formatDate(new Date(item.createdDate)),
      };
    },
  };
