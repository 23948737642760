// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "../Context/TeamsFxContext";
import ApplicationConfiguration from "../ApplicationConfiguration";
import Routers from "./Router";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */

export default function App() {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: ApplicationConfiguration.initiateLoginEndpoint!,
      clientId: ApplicationConfiguration.clientId!,
    });
  return (
    <TeamsFxContext.Provider
      value={{ theme, themeString, teamsUserCredential }}
    >
      <FluentProvider
        theme={
          themeString === "dark"
            ? teamsDarkTheme
            : themeString === "contrast"
            ? teamsHighContrastTheme
            : {
                ...teamsLightTheme,
                colorNeutralBackground3: "#eeeeee",
              }
        }
        style={{ background: tokens.colorNeutralBackground3 }}
      >
        {loading ? (
          <Spinner
            size="medium"
            style={{ marginTop: "auto", marginBottom: "auto" }}
            labelPosition="below"
            label="Loading application..."
          />
        ) : (
          <Routers />
        )}
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
