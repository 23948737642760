import {
  Button,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarTitle,
  Spinner,
} from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import {
  getApprovalHistory,
  getApprovalItem,
} from "../../../Services/Approvals/ApprovalService";
import { ApprovalHistoryState } from "../../../Types/Approvals/ApprovalHistoryState";
import {
  ApprovalRequest,
} from "../../../Types/Approvals/ApprovalRequest";
import { ApprovalStatus } from "../../../Types/Approvals/ApprovalStatus";
import { ApprovalCard } from "../../Approvals/ApprovalDrawer/ApprovalCard";
import { TeamsFxContext } from "../../../Context/TeamsFxContext";
import { ArrowClockwiseRegular } from "@fluentui/react-icons";

export const ContentHubItemApprovals = ({
  siteId,
  listId,
  itemId,
  status,
  history,
  approvals,
  onLoadStatusChange,
  onLoadComplete,
  onError,
}: {
  siteId: string;
  listId: string;
  itemId: string;
  status: ApprovalStatus;
  history?: ApprovalHistoryState[];
  approvals?: ApprovalRequest[];
  onLoadStatusChange?: (loading: boolean) => void;
  onLoadComplete?: (
    historyItems: ApprovalHistoryState[],
    approvalItems: ApprovalRequest[]
  ) => void;
  onError?: (error: unknown) => void;
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const [approvalItems, setApprovalItems] = useState<ApprovalRequest[]>(
    approvals ?? []
  );
  const [historyItems, setHistoryItems] = useState<ApprovalHistoryState[]>(
    history ?? []
  );
  const [shouldReload, setShouldReload] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setApprovalItems(approvals ?? []);
  }, [approvals]);
  useEffect(() => {
    setHistoryItems(history ?? []);
  }, [history]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!teamsUserCredential) return;
    if (history === undefined || approvals === undefined || shouldReload) {
      setErrorMessage(undefined);
      onLoadStatusChange?.(true);
      setIsLoading(true);
      (async () => {
        try {
          const approvalHistory = await getApprovalHistory(
            `${siteId}/${listId}/${itemId}`,
            teamsUserCredential
          );
          setHistoryItems(approvalHistory);

          const uniqueIds = new Set(approvalHistory.map((h) => h.requestId));
          const items: ApprovalRequest[] = [];
          const array: string[] = [];

          uniqueIds.forEach((v) => array.push(v));
          for (const id of array) {
            items.push(await getApprovalItem(id, teamsUserCredential));
            setApprovalItems(items);
          }
          onLoadComplete?.(approvalHistory, items);
        } catch (error) {
          console.log(error);
          setErrorMessage(
            "An error occurred while retrieving the approval requests."
          );
          setHistoryItems([]);
          setApprovalItems([]);
          onError?.(error);
        }
        setIsLoading(false);
        setShouldReload(false);
        onLoadStatusChange?.(false);
      })();
    }
  }, [siteId, listId, itemId, status, teamsUserCredential, shouldReload]);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      {errorMessage === undefined && !isLoading && approvalItems.length > 0 && (
        <Button
          icon={<ArrowClockwiseRegular />}
          onClick={() => setShouldReload(true)}
        >
          Refresh
        </Button>
      )}
      {!isLoading &&
        approvalItems.map((r, i) => (
          <div key={i} style={{ flex: "1" }}>
            <ApprovalCard
              request={r}
              history={historyItems}
              collapsed={r.status !== ApprovalStatus.Pending}
              onUpdate={() => {
                setShouldReload(true);
              }}
              showLinks={false}
              showParameters={false}
              showSource={false}
            ></ApprovalCard>
          </div>
        ))}
      {approvalItems.length === 0 && !isLoading && (
        <MessageBar intent={errorMessage !== undefined ? "error" : "info"}>
          {errorMessage !== undefined ? (
            <MessageBarBody>
              <MessageBarTitle>Error</MessageBarTitle>
              {errorMessage}
            </MessageBarBody>
          ) : (
            <MessageBarBody>
              <MessageBarTitle>No approvals</MessageBarTitle>
              There are no approval requests associated with this item.
            </MessageBarBody>
          )}
          <MessageBarActions>
            <Button
              icon={<ArrowClockwiseRegular />}
              onClick={() => setShouldReload(true)}
            >
              Refresh
            </Button>
          </MessageBarActions>
        </MessageBar>
      )}
      {isLoading && (
        <Spinner size="medium" label="Loading" style={{ padding: "6px 0" }} />
      )}
    </div>
  );
};
