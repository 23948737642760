import { useCallback, useContext, useEffect, useState } from "react";

import { ContentHubContext } from "../Context/ContentHubContext";
import { ContentHubSitesRegistry } from "../Forms/ContentHubSitesRegistry";
import { ContentHubListRegistry } from "../Types/ContentHub/Registry/ContentHubListRegistry";
import { ContentHubSiteRegistry } from "../Types/ContentHub/Registry/ContentHubSiteRegistry";

export const useContentHubRegistry = () => {
  const contentHubContext = useContext(ContentHubContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [registry, setRegistry] = useState<ContentHubSiteRegistry[]>([]);

  useEffect(() => {
    if (contentHubContext.isLoading === false) {
      const registeredSites = ContentHubSitesRegistry.map((sr) => {
        const site = contentHubContext.sites.find(
          (s) =>
            s.name.localeCompare(sr.name, undefined, {
              sensitivity: "base",
            }) === 0
        );
        if (site === undefined) return null;
        return {
          id: site.id,
          name: sr.name,
          text: sr.text,
          lists: sr.lists
            .map((lr) => {
              const list = contentHubContext.lists[site!.id].find(
                (l) =>
                  l.name.localeCompare(lr.name, undefined, {
                    sensitivity: "base",
                  }) === 0
              );
              if (list === undefined) return null;

              return {
                ...lr,
                id: list.id,
                views: contentHubContext.views[list.id],
              };
            })
            .filter((lr) => lr !== null)
            .map((lr) => lr!),
        } as ContentHubSiteRegistry;
      })
        .filter((sr) => sr !== null)
        .map((sr) => sr!);

      setRegistry(registeredSites);
      setIsLoading(false);
    }
  }, [
    contentHubContext.isLoading,
    contentHubContext.lists,
    contentHubContext.sites,
    contentHubContext.views,
  ]);

  const getSiteRegistry = useCallback((
    id: string | undefined
  ): ContentHubSiteRegistry | undefined => {
    if (id === undefined) return undefined;
    return registry?.find((s) => s.id === id);
  }, [registry]);

  const findSiteRegistryByName = useCallback((
    name: string | undefined
  ): ContentHubSiteRegistry | undefined => {
    if (name === undefined) return undefined;
    return registry?.find(
      (s) =>
        name.localeCompare(s.name, undefined, { sensitivity: "base" }) === 0
    );
  }, [registry]);

  const getListRegistry = useCallback((
    siteId: string | undefined,
    listId: string | undefined
  ): ContentHubListRegistry | undefined => {
    if (siteId === undefined || listId === undefined) return undefined;
    return getSiteRegistry(siteId)?.lists?.find((l) => l.id === listId);
  }, [getSiteRegistry]);

  const getListRegistryFromSiteRegistry = (
    siteRegistry: ContentHubSiteRegistry | undefined,
    listId: string | undefined
  ): ContentHubListRegistry | undefined => {
    if (siteRegistry === undefined || listId === undefined) return undefined;
    return siteRegistry?.lists?.find((l) => l.id === listId);
  };

  const findListRegistryByName = useCallback((
    siteId: string | undefined,
    listName: string | undefined
  ): ContentHubListRegistry | undefined => {
    if (siteId === undefined || listName === undefined) return undefined;
    return getSiteRegistry(siteId)?.lists?.find(
      (l) =>
        listName.localeCompare(l.name, undefined, { sensitivity: "base" }) === 0
    );
  }, [getSiteRegistry]);

  const findListRegistryByNameInSiteRegistry = (
    siteRegistry: ContentHubSiteRegistry | undefined,
    listName: string | undefined
  ): ContentHubListRegistry | undefined => {
    if (siteRegistry === undefined || listName === undefined) return undefined;
    return siteRegistry?.lists?.find(
      (l) =>
        listName.localeCompare(l.name, undefined, { sensitivity: "base" }) === 0
    );
  };

  return {
    registry,
    isLoading,
    getSiteRegistry,
    findSiteRegistryByName,
    getListRegistry,
    getListRegistryFromSiteRegistry,
    findListRegistryByName,
    findListRegistryByNameInSiteRegistry,
  };
};
