import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import ApplicationConfiguration from "../../ApplicationConfiguration";
import * as SharePointDriveService from "../SharePoint/SharePointDriveService";
import { ContentHubAttachments } from "../../Types/ContentHub/ContentHubAttachments";
import {
  ContentHubItemElement,
  ItemChangeSet,
} from "../../Types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../Types/ContentHub/ContentHubListsElement";
import {
  ContentHubApiCollection,
  ContentHubPaginatedCollection,
} from "../../Types/ContentHub/ContentHubPaginatedCollection";
import { ContentHubQueryPagination } from "../../Types/ContentHub/ContentHubQueryPagination";
import { AdvancedSearchItemsRequest } from "../../Types/ContentHub/SearchItemsRequest";
import { ContentHubSiteElement } from "../../Types/ContentHub/ContentHubSiteElement";
import { ContentHubInsertItemRequest } from "../../Types/ContentHub/ContentHubInsertItemRequest";
import { ContentHubUpdateItemRequest } from "../../Types/ContentHub/ContentHubUpdateItemRequest";
import { ListViewDefinition } from "../../Types/ContentHub/ListViewDefinition";

import * as ExcelJS from "exceljs";
import { ContentHubListRegistry } from "../../Types/ContentHub/Registry/ContentHubListRegistry";


const apiBaseUrl = ApplicationConfiguration.apiEndpoint + "/api/contenthub/";

async function getToken(credential: TeamsUserCredential) {
  return (await credential.getToken(""))!.token;
}

function getApiClient(credential: TeamsUserCredential) {
  const apiClient = createApiClient(
    apiBaseUrl,
    new BearerTokenAuthProvider(() => getToken(credential))
  );
  return apiClient;
}

export async function getSites(
  pagination: ContentHubQueryPagination | undefined | null,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubPaginatedCollection<ContentHubSiteElement>> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const queryParamsObj: Record<string, string> = {};
    if (pagination?.MaxItems) {
      queryParamsObj["MaxItems"] = pagination?.MaxItems?.toString();
    }
    if (pagination?.ContinuationString) {
      queryParamsObj["ContinuationString"] = pagination?.ContinuationString;
    }
    const queryParams = new URLSearchParams(queryParamsObj);
    let url = `sites`;
    if (queryParams.size > 0) {
      url = `${url}?${queryParams.toString()}`;
    }
    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getSite(
  siteId: string,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubSiteElement> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(`sites/${siteId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getLists(
  siteId: string,
  pagination: ContentHubQueryPagination | undefined | null,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubPaginatedCollection<ContentHubListsElement>> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const queryParamsObj: Record<string, string> = {};
    if (pagination?.MaxItems) {
      queryParamsObj["MaxItems"] = pagination?.MaxItems?.toString();
    }
    if (pagination?.ContinuationString) {
      queryParamsObj["ContinuationString"] = pagination?.ContinuationString;
    }
    const queryParams = new URLSearchParams(queryParamsObj);
    let url = `sites/${siteId}/lists`;
    if (queryParams.size > 0) {
      url = `${url}?${queryParams.toString()}`;
    }

    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getList(
  siteId: string,
  listId: string,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubListsElement> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(`sites/${siteId}/lists/${listId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getItems(
  siteId: string,
  listId: string,
  searchRequest: AdvancedSearchItemsRequest,
  pagination: ContentHubQueryPagination | undefined | null,
  viewId: string | undefined | null,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubPaginatedCollection<ContentHubItemElement>> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const queryParamsObj: Record<string, string> = {};
    if (pagination?.MaxItems) {
      queryParamsObj["MaxItems"] = pagination?.MaxItems?.toString();
    }
    if (pagination?.ContinuationString) {
      queryParamsObj["ContinuationString"] = pagination?.ContinuationString;
    }
    if (viewId) {
      queryParamsObj["viewId"] = viewId;
    }
    const queryParams = new URLSearchParams(queryParamsObj);
    let url = `sites/${siteId}/lists/${listId}/items/search`;
    if (queryParams.size > 0) {
      url = `${url}?${queryParams.toString()}`;
    }

    const response = await apiClient.post(url, searchRequest);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function exportItems(
  siteId: string,
  listId: string,
  listRegistry: ContentHubListRegistry,
  list: ContentHubListsElement,
  searchRequest: AdvancedSearchItemsRequest,
  viewId: string | undefined | null,
  teamsUserCredential: TeamsUserCredential
) {
  const pagination: ContentHubQueryPagination = {
    MaxItems: 250,
  };
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Export");
  worksheet.columns = listRegistry.columns
    .filter((col) => col.visible !== false)
    .map(
      (col) => ({ header: col.displayName, key: col.key } as ExcelJS.Column)
    );
  do {
    const batchItems = await getItems(
      siteId,
      listId,
      searchRequest,
      pagination,
      viewId,
      teamsUserCredential
    );

    worksheet.addRows(
      batchItems.items.map((item) =>
        Object.fromEntries(
          Object.entries(listRegistry.mapper(item, list)).filter(
            (entry) =>
              listRegistry.columns.find((col) => col.key === entry[0])
                ?.visible !== false
          )
        )
      )
    );

    // todo: Add items to spreadsheet
    pagination.ContinuationString = batchItems.continuationString ?? undefined;
  } while (pagination.ContinuationString !== undefined);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer]);
  downloadFile(blob, "export.xlsx");
}

function downloadFile(blob: Blob, filename: string) {
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window.navigator as any).msSaveOrOpenBlob(blob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = data;
  link.download = filename;
  link.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 400);
}

export async function getItemVersions<T>(
  siteId: string,
  listId: string,
  itemId: string,
  teamsUserCredential: TeamsUserCredential
): Promise<ItemChangeSet[]> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(
      `sites/${siteId}/lists/${listId}/items/${itemId}/versions`
    );
    const item = (await response.data) as ContentHubItemElement<T>;

    return item.changesets;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getItem<T>(
  siteId: string,
  listId: string,
  itemId: string,
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubItemElement<T>> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get(
      `sites/${siteId}/lists/${listId}/items/${itemId}`
    );
    return (await response.data) as ContentHubItemElement<T>;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function createItem<T>(
  siteId: string,
  listId: string,
  request: ContentHubInsertItemRequest,
  teamsUserCredential: TeamsUserCredential,
  driveId?: string,
  folderId?: string,
  files?: File[]
): Promise<ContentHubItemElement<T>> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    if (files) {
      const attachmentValue: ContentHubAttachments = {
        itemFolderId: "UPLOADING FILES",
        filenames: [],
      };

      request.fields["_attachments"] = attachmentValue;
    }

    const response = await apiClient.post(
      `sites/${siteId}/lists/${listId}/items`,
      request
    );
    const itemCreated = response.data as ContentHubItemElement<T>;

    //If there were attachments added
    if (files) {
      let itemFolderId = await uploadDocuments(
        driveId!,
        folderId!,
        itemCreated.id,
        files,
        teamsUserCredential
      );
      const filenames: string[] = files!.map((file: File) => file.name);

      if (itemFolderId) {
        itemFolderId = itemFolderId.replaceAll('"', "");

        const attachmentValue: ContentHubAttachments = {
          itemFolderId: itemFolderId.toString(),
          filenames: filenames?.length === 0 ? [] : filenames,
        };

        const updateRequest: ContentHubUpdateItemRequest = {
          fields: {
            _attachments: attachmentValue,
          },
          ignoreLock: false,
        };
        const itemWithAttachments = await updateItems(
          siteId,
          listId,
          itemCreated.id,
          updateRequest,
          teamsUserCredential,
          driveId,
          folderId
        );
        return itemWithAttachments as ContentHubItemElement<T>;
      }
    }

    return itemCreated;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateItems(
  siteId: string,
  listId: string,
  itemId: string,
  request: ContentHubUpdateItemRequest,
  teamsUserCredential: TeamsUserCredential,
  driveId?: string,
  folderId?: string,
  files?: File[]
): Promise<ContentHubItemElement> {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    //If there are attachments
    const attachments = request.fields["_attachments"] as ContentHubAttachments;

    if ((files?.length ?? 0) > 0) {
      const attachmentValue = attachments ?? {
        itemFolderId: "UPLOADING FILES",
        filenames: [],
      };
      const itemFolderId = await uploadDocuments(
        driveId!,
        folderId!,
        itemId,
        files!,
        teamsUserCredential
      );
      const attachDriveId = attachments.itemFolderId;

      //Update the attachment drive ID if there was none
      if (files && files.length > 0 && attachDriveId && itemFolderId) {
        const filenames: string[] = files!.map((file: File) => file.name);

        attachmentValue.itemFolderId = itemFolderId;
        attachmentValue.filenames ??= [];
        filenames.forEach((f) => attachmentValue.filenames!.push(f));

        request.fields["_attachments"] = { ...attachmentValue };
      }
    }

    const response = await apiClient.patch(
      `sites/${siteId}/lists/${listId}/items/${itemId}`,
      request
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function deleteItem(
  siteId: string,
  listId: string,
  itemId: string,
  teamsUserCredential: TeamsUserCredential
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);
    const response = await apiClient.delete(
      `sites/${siteId}/lists/${listId}/items/${itemId}`
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getPermissions(
  teamsUserCredential: TeamsUserCredential
): Promise<ContentHubApiCollection<string>> {
  try {
    const apiClient = getApiClient(teamsUserCredential);
    const response = await apiClient.get(`permissions`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getAccessibleResources(
  teamsUserCredential: TeamsUserCredential
): Promise<{
  sites: ContentHubSiteElement[];
  lists: ContentHubListsElement[];
  views: ListViewDefinition[];
  permissions: string[];
}> {
  try {
    const apiClient = getApiClient(teamsUserCredential);
    const response = await apiClient.get(`accessibleResources`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getListViews(
  siteId: string,
  listId: string,
  teamsUserCredential: TeamsUserCredential
): Promise<ListViewDefinition[]> {
  try {
    const apiClient = getApiClient(teamsUserCredential);
    const response = await apiClient.get(
      `sites/${siteId}/lists/${listId}/views`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function createListView(
  siteId: string,
  listId: string,
  view: ListViewDefinition,
  teamsUserCredential: TeamsUserCredential
): Promise<ListViewDefinition> {
  try {
    const apiClient = getApiClient(teamsUserCredential);
    const createRequest = {
      displayName: view.displayName,
      sortingOptions: view.sortingOptions,
      filters: view.filters
    };
    const response = await apiClient.post(
      `sites/${siteId}/lists/${listId}/views`,
      createRequest
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function uploadDocuments(
  driveId: string,
  folderId: string,
  itemId: string,
  files: File[],
  teamsUserCredential: TeamsUserCredential
) {
  return await SharePointDriveService.uploadFiles(
    driveId,
    folderId,
    itemId,
    files,
    teamsUserCredential
  );
}
