import {
  Body1Strong,
  Button,
  Field,
  Input,
  Label,
  Subtitle1,
  Textarea,
} from "@fluentui/react-components";
import { ErrorMessage } from "@hookform/error-message";
import { useCallback, useContext, useEffect, useId, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

//Internal
import { FileUploaderCommon } from "../../../Components/Common/FileUploader/FileUploader";
import { NumericInput } from "../../../Components/Common/NumericInput/NumericInput";
import { SelectCounterparty } from "../../../Components/Common/SelectCounterparty/SelectCounterparty";
import { SelectDropDownData } from "../../../Components/Common/SelectDropDownData/SelectDropDownData";
import { UserContext } from "../../../Context/UserContext";
import * as UserService from "../../../Services/General/UserService";
import { ContentHubItemElement } from "../../../Types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../Types/ContentHub/ContentHubListsElement";
import { Counterparty } from "../../../Types/General/Counterparty";
import { BalanceTransfer } from "./BalanceTransfer";

//CSS
import { ContentHubFormControls } from "../../../Components/ContentHub/ContentHubForm/ContentHubForm";
import { useContentHubChoiceField } from "../../../Hooks/useContentHubChoiceField";
import { ApprovalStatus } from "../../../Types/Approvals/ApprovalStatus";
import { ContentHubAttachments } from "../../../Types/ContentHub/ContentHubAttachments";
import "./BalanceTransfer.css";

export const DefaultBalanceTransferFormValue = {
  requestor: "",
  counterpartyFrom: "",
  accountNumberFrom: "",
  transactionAmountFrom: 0,
  counterpartyTo: "",
  accountNumberTo: "",
  transactionAmountTo: 0,
  requestorComments: "",
  status: "Pending",
  clientApprovalAttached: "",
  creditDocumentationAttached: "",
  balanceTransferTemplateAttached: "",
  finalCustomer: "",
  billingAnalyst: null,
  _attachments: null,
};

export const BalanceTransferForm = ({
  item,
  list,
  formControls,
}: {
  item: ContentHubItemElement | undefined;
  list: ContentHubListsElement;
  formControls: ContentHubFormControls;
}) => {
  const form = useFormContext();
  const balanceTransferItem = item as
    | ContentHubItemElement<BalanceTransfer>
    | undefined;

  const { userInfo } = useContext(UserContext);

  const yesNoOptions = useContentHubChoiceField(list, "finalCustomer");

  const status = form.getValues("status");
  const workflowStep = form.getValues("_workflowStep");
  const requestor = form.getValues("requestor");

  const extractContentHubItem = useCallback(
    (itemToConvert: ContentHubItemElement<BalanceTransfer>) => {
      //Info Load
      const emptyAttachment: ContentHubAttachments = {
        itemFolderId: "",
        filenames: [],
      };

      const infoEdit: BalanceTransfer = {
        requestor: itemToConvert.fields.requestor.value,
        counterpartyFrom: itemToConvert.fields.counterpartyFrom.value,
        accountNumberFrom: itemToConvert.fields.accountNumberFrom.value,
        transactionAmountFrom: itemToConvert.fields.transactionAmountFrom.value,
        counterpartyTo: itemToConvert.fields.counterpartyTo.value,
        accountNumberTo: itemToConvert.fields.accountNumberTo.value,
        transactionAmountTo: itemToConvert.fields.transactionAmountTo.value,
        requestorComments: itemToConvert.fields.requestorComments.value,
        status: itemToConvert.fields.status.value,
        clientApprovalAttached:
          itemToConvert.fields.clientApprovalAttached.value,
        creditDocumentationAttached:
          itemToConvert.fields.creditDocumentationAttached.value,
        balanceTransferTemplateAttached:
          itemToConvert.fields.balanceTransferTemplateAttached.value,
        finalCustomer: itemToConvert.fields.finalCustomer.value,
        billingAnalyst: itemToConvert.fields.billingAnalyst.value,
        _attachments:
          itemToConvert.fields._attachments?.value ?? emptyAttachment,
        _workflowStep: itemToConvert.fields._workflowStep?.value ?? "",
      };
      return infoEdit;
    },
    []
  );

  const formFieldsKeySuffix = useId();
  const [formFieldsKey, setFormFieldsKey] = useState<string>("");

  useEffect(() => {
    setFormFieldsKey(new Date().toISOString() + formFieldsKeySuffix);
  }, [item]);

  useEffect(() => {
    formControls.setPanelTabs(["Request"]);
    formControls.setSelectedTab("Request");

    if (balanceTransferItem !== undefined) {
      formControls.setTitle(
        `[${balanceTransferItem.sequentialId}] - Balance Transfer Request - [${balanceTransferItem.fields.counterpartyFrom.value}]`
      );

      //Info Load
      const editItem = extractContentHubItem(balanceTransferItem);
      form.reset(editItem);
      setReloadFiles(true);
    } else {
      formControls.setTitle("New Balance Transfer Request");
      form.reset(DefaultBalanceTransferFormValue as unknown as BalanceTransfer);
      setReloadFiles(false);

      if (userInfo) {
        const user = UserService.userProfileToContentHubUser(userInfo);
        if (user) {
          form.setValue("requestor", user);
        }
      }
    }
  }, [item]);

  const [reloadFiles, setReloadFiles] = useState<boolean>(false);

  //DropDowns Info Counterparties
  const defaultCounterpartyFrom: Counterparty = {
    name: balanceTransferItem?.fields.counterpartyFrom?.value ?? "",
    sic: "",
    counterpartyClass: "",
    counterpartyId: 0,
    status: "ACTIVE",
  };

  const defaultCounterpartyTo: Counterparty = {
    name: balanceTransferItem?.fields.counterpartyTo?.value ?? "",
    sic: "",
    counterpartyClass: "",
    counterpartyId: 0,
    status: "ACTIVE",
  };
  const handleCounterparty = (counterparty: Counterparty, fromOrTo: string) => {
    switch (fromOrTo) {
      case "From":
        form.setValue("counterpartyFrom", counterparty.name);
        break;

      case "To":
        form.setValue("counterpartyTo", counterparty.name);
        break;
    }
  };

  //Handle Submit of Form
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (balanceTransferItem !== undefined) {
      const mandatoryControls: JSX.Element[] = [
        <Label key={0}>
          <Body1Strong>Status: </Body1Strong>
          {status}
        </Label>,
      ];

      if (
        status === "Billing Manager Approved" &&
        workflowStep === "ProcessBalanceTransfer"
      ) {
        formControls.setControls([
          <Button
            key={1}
            disabled={userInfo?.id !== requestor.id}
            appearance="primary"
            onClick={() =>
              formControls.submitWithoutValidation({
                ...extractContentHubItem(balanceTransferItem),
                status: "Completed",
              })
            }
          >
            Complete Request
          </Button>,
          ...mandatoryControls,
        ]);
      } else if (
        status === "Revision Required" &&
        workflowStep === "billingAnalystRequiresMoreInfoSentEmail"
      ) {
        formControls.setControls([
          <Button
            key={1}
            appearance="primary"
            disabled={userInfo?.id !== requestor.id}
            onClick={() =>
              formControls.submitWithoutValidation({
                ...extractContentHubItem(balanceTransferItem),
                status: "Ready for Billing Analyst",
              })
            }
          >
            Ready for Billing Analyst
          </Button>,
          ...mandatoryControls,
        ]);
      } else if (
        status === "Revision Required" &&
        workflowStep === "creditRequiresMoreInfoSentEmail"
      ) {
        formControls.setControls([
          <Button
            key={1}
            appearance="primary"
            disabled={userInfo?.id !== requestor.id}
            onClick={() =>
              formControls.submitWithoutValidation({
                ...extractContentHubItem(balanceTransferItem),
                status: "Ready for Credit Approval",
              })
            }
          >
            Ready for Credit Approval
          </Button>,
          ...mandatoryControls,
        ]);
      } else if (
        status === "Revision Required" &&
        workflowStep === "billingManagerRequiresMoreInfoSentEmail"
      ) {
        formControls.setControls([
          <Button
            key={1}
            appearance="primary"
            disabled={userInfo?.id !== requestor.id}
            onClick={() =>
              formControls.submitWithoutValidation({
                ...extractContentHubItem(balanceTransferItem),
                status: "Ready for Billing Manager",
                billingAnalyst: UserService.userProfileToContentHubUser(
                  userInfo!
                )!,
              })
            }
          >
            Ready for Billing Manager
          </Button>,
          ...mandatoryControls,
        ]);
      } else {
        formControls.setControls([...mandatoryControls]);
      }

      if (workflowStep === "WaitingForBillingAnalyst") {
        const pendingRequest = formControls.approvalItems.find(
          (r) => r.status === ApprovalStatus.Pending
        );
        if (
          pendingRequest &&
          (pendingRequest.approvers.find(
            (x) => x.id === userContext.userInfo?.id
          ) !== undefined ||
            userContext.membershipIds?.some((id) =>
              pendingRequest.approvers.find((x) => x.id === id)
            ))
        ) {
          formControls.setCanEditAttachments(true);
          formControls.setCanEdit(false);
          formControls.setCanSubmit(true);
          return;
        }
      }

      if (balanceTransferItem.isLocked) {
        formControls.setCanSubmit(false);
        formControls.setCanEdit(false);
        formControls.setCanEditAttachments(false);
        return;
      }
    } else {
      formControls.setControls([]);
    }

    switch (status) {
      case "Pending":
        formControls.setCanSubmit(true);
        formControls.setCanEdit(true);
        formControls.setCanEditAttachments(true);
        break;

      case "Revision Required":
        formControls.setCanSubmit(true);
        formControls.setCanEdit(true);
        formControls.setCanEditAttachments(true);
        break;

      default:
        formControls.setCanSubmit(false);
        formControls.setCanEdit(false);
        formControls.setCanEditAttachments(false);
        break;
    }
  }, [status, item, formControls.approvalItems, userInfo]);

  return (
    <>
      <div className="requestAreaTransfer">
        <div className="requestorDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="requestor" />
          </div>
          <Label>
            Requestor:{" "}
            {balanceTransferItem?.fields.requestor.value?.name ??
              userInfo?.displayName}
          </Label>
        </div>

        {/* From  */}
        <div className="counterpartyFromDiv">
          <div className="errorMessage">
            <ErrorMessage
              errors={form.formState.errors}
              name="counterpartyFrom"
            />
          </div>
          <SelectCounterparty
            onError={() => {
              formControls.setMessages([
                {
                  intent: "error",
                  title: "Error",
                  message: "Error while retrieving counterparties.",
                },
              ]);
            }}
            disabled={!formControls.canEdit}
            label="Counterparty (From)"
            showInfo={false}
            defaultCounterparty={defaultCounterpartyFrom}
            control={form.control}
            name="counterpartyFrom"
            handleCounterpartySelection={(data) =>
              handleCounterparty(data, "From")
            }
          />
        </div>

        <div className="accountFromDiv">
          <div className="accountFrom">
            <div className="errorMessage">
              <ErrorMessage
                errors={form.formState.errors}
                name="accountNumberFrom"
              />
            </div>
            <Field label="Account (From)">
              <Controller
                name="accountNumberFrom"
                control={form.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    key={formFieldsKey}
                    disabled={
                      (field?.disabled ?? false) || !formControls.canEdit
                    }
                    className="accountFromInput"
                    type="text"
                  />
                )}
              />
            </Field>
          </div>
        </div>

        <div className="amountFromDiv">
          <div className="amountFrom">
            <div className="errorMessage">
              <ErrorMessage
                errors={form.formState.errors}
                name="transactionAmountFrom"
              />
            </div>
            <NumericInput
              disabled={!formControls.canEdit}
              label="Transaction Amount (From)"
              control={form.control}
              name="transactionAmountFrom"
              isMoney={true}
              defaultValue={
                balanceTransferItem?.fields.transactionAmountFrom.value ?? 0
              }
              handleChange={(data) =>
                form.setValue("transactionAmountFrom", data)
              }
            />
          </div>
        </div>

        {/* To */}

        <div className="counterpartyToDiv">
          <div className="counterpartyTo">
            <div className="errorMessage">
              <ErrorMessage
                errors={form.formState.errors}
                name="counterpartyTo"
              />
            </div>
            <SelectCounterparty
              onError={() => {
                formControls.setMessages([
                  {
                    intent: "error",
                    title: "Error",
                    message: "Error while retrieving counterparties.",
                  },
                ]);
              }}
              disabled={!formControls.canEdit}
              label="Counterparty (To)"
              showInfo={false}
              defaultCounterparty={defaultCounterpartyTo}
              control={form.control}
              name="counterpartyTo"
              handleCounterpartySelection={(data) =>
                handleCounterparty(data, "To")
              }
            />
          </div>
        </div>

        <div className="accountToDiv">
          <div className="accountTo">
            <div className="errorMessage">
              <ErrorMessage
                errors={form.formState.errors}
                name="accountNumberTo"
              />
            </div>
            <Field label="Account (To)">
              <Controller
                name="accountNumberTo"
                control={form.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    key={formFieldsKey}
                    disabled={
                      (field?.disabled ?? false) || !formControls.canEdit
                    }
                    className="accountToInput"
                    type="text"
                  />
                )}
              />
            </Field>
          </div>
        </div>

        <div className="transactionAmountToDiv">
          <div className="amountTo">
            <div className="errorMessage">
              <ErrorMessage
                errors={form.formState.errors}
                name="transactionAmountTo"
              />
            </div>
            <NumericInput
              disabled={!formControls.canEdit}
              label="Transaction Amount (To)"
              control={form.control}
              name="transactionAmountTo"
              isMoney={true}
              defaultValue={
                balanceTransferItem?.fields.transactionAmountTo.value !==
                undefined
                  ? balanceTransferItem?.fields.transactionAmountTo.value
                  : 0
              }
              handleChange={(data) =>
                form.setValue("transactionAmountTo", data)
              }
            />
          </div>
        </div>

        <div className="requestorCommentsDiv">
          <div className="requestorComment">
            <div className="errorMessage">
              <ErrorMessage
                errors={form.formState.errors}
                name="requestorComments"
              />
            </div>
            <Field label="Requestor Comments">
              <Controller
                name="requestorComments"
                control={form.control}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    key={formFieldsKey}
                    disabled={
                      (field?.disabled ?? false) || !formControls.canEdit
                    }
                    className="requestorCommentsInput"
                  />
                )}
              />
            </Field>
          </div>
        </div>

        {/* Other */}

        <div className="clientApprovalDiv">
          <div className="billingManagerReview">
            <div className="errorMessage">
              <ErrorMessage
                errors={form.formState.errors}
                name="clientApprovalAttached"
              />
            </div>
            <SelectDropDownData
              disabled={!formControls.canEdit}
              label="Client Approval Attached:"
              options={yesNoOptions.dropdownOptions}
              control={form.control}
              name="clientApprovalAttached"
            />
          </div>
        </div>

        <div className="creditDocumentDiv">
          <div className="creditDocumentation">
            <div className="errorMessage">
              <ErrorMessage
                errors={form.formState.errors}
                name="creditDocumentationAttached"
              />
            </div>
            <SelectDropDownData
              disabled={!formControls.canEdit}
              label="Credit Documentation Attached:"
              options={yesNoOptions.dropdownOptions}
              control={form.control}
              name="creditDocumentationAttached"
            />
          </div>
        </div>

        <div className="templateDiv">
          <div className="balanceTransfer">
            <div className="errorMessage">
              <ErrorMessage
                errors={form.formState.errors}
                name="balanceTransferTemplateAttached"
              />
            </div>
            <SelectDropDownData
              disabled={!formControls.canEdit}
              label="Balance Transfer Template Attached:"
              options={yesNoOptions.dropdownOptions}
              control={form.control}
              name="balanceTransferTemplateAttached"
            />
          </div>
        </div>

        <div className="finalCustomerDiv">
          <div className="finalCustomer">
            <div className="errorMessage">
              <ErrorMessage
                errors={form.formState.errors}
                name="finalCustomer"
              />
            </div>
            <SelectDropDownData
              disabled={!formControls.canEdit}
              label="Final Customer:"
              options={yesNoOptions.dropdownOptions}
              control={form.control}
              name="finalCustomer"
            />
          </div>
        </div>

        <div className="attachmentsDiv">
          <Subtitle1>Attachments</Subtitle1>
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="_attachments" />
            <ErrorMessage
              errors={form.formState.errors}
              name="_attachments.filenames"
            />
            <ErrorMessage
              errors={form.formState.errors}
              name="_attachments.filesToUpload"
            />
          </div>
          <FileUploaderCommon
            disabled={!formControls.canEdit && !formControls.canEditAttachments}
            reload={reloadFiles}
            attachment={
              reloadFiles &&
              balanceTransferItem?.fields._attachments !== undefined
                ? balanceTransferItem?.fields._attachments.value
                : null
            }
            listDefinition={list!}
            filesOnForm={formControls.handleFiles}
            deletedFile={formControls.deleteFile}
            fileTypes={["pdf", "txt", "xlsx", "xls"]}
          />
        </div>
      </div>
    </>
  );
};
