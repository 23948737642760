import {
  Breadcrumb,
  BreadcrumbDivider,
  Button,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Spinner,
} from "@fluentui/react-components";
import { AddFilled } from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import { ContentHubContext } from "../../../Context/ContentHubContext";
import { ContentHubListContextProvider } from "../../../Context/ContentHubListContext";
import { DeepLinkContext } from "../../../Context/DeepLinkContext";
import { SelectedContentHubListContext } from "../../../Context/SelectedContentHubListContext";
import { TeamsFxContext } from "../../../Context/TeamsFxContext";
import { useContentHubRegistry } from "../../../Hooks/useContentHubRegistry";
import useSelectedContentHub from "../../../Hooks/useSelectedContentHub";
import * as ContentHubService from "../../../Services/ContentHub/ContentHubService";
import { ContentHubItemElement } from "../../../Types/ContentHub/ContentHubItemElement";
import { ListViewDefinition } from "../../../Types/ContentHub/ListViewDefinition";
import { ContentHubListRegistry } from "../../../Types/ContentHub/Registry/ContentHubListRegistry";
import { ContentHubSiteRegistry } from "../../../Types/ContentHub/Registry/ContentHubSiteRegistry";
import { BreadcrumbDropdown } from "../../Common/BreadcrumbDropdown/BreadcrumbDropdown";
import { ContentHubForm } from "../../ContentHub/ContentHubForm/ContentHubForm";
import ContentHubList from "../../ContentHub/ContentHubList/ContentHubList";
import "./WorkflowsTab.css";

//Get Info From MS Graph Function App
export default function WorkflowsTab() {
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
  const [deepLink, setDeepLink] = useContext(DeepLinkContext);
  const contentHubContext = useContext(ContentHubContext);

  const selectedContentHub = useSelectedContentHub();

  const registry = useContentHubRegistry();

  const selectedSiteRegistry =
    selectedContentHub.selectedSite !== undefined
      ? registry.registry.find((s) => s.id === selectedContentHub.selectedSite)
      : undefined;
  const selectedListRegistry =
    selectedContentHub.selectedList !== undefined
      ? selectedSiteRegistry?.lists?.find(
          (l) => l.id === selectedContentHub.selectedList
        )
      : undefined;

  const selectedSite =
    selectedContentHub.selectedSite !== undefined
      ? contentHubContext.sites.find(
          (s) => s.id === selectedContentHub.selectedSite
        )
      : undefined;

  const selectedList =
    selectedContentHub.selectedSite !== undefined &&
    selectedContentHub.selectedList !== undefined
      ? contentHubContext.lists[selectedContentHub.selectedSite].find(
          (s) => s.id === selectedContentHub.selectedList
        )
      : undefined;

  const selectedView =
    selectedContentHub.selectedSite !== undefined &&
    selectedContentHub.selectedList !== undefined &&
    selectedContentHub.selectedView !== undefined
      ? contentHubContext.views[selectedContentHub.selectedList].find(
          (s) => s.id === selectedContentHub.selectedView
        )
      : undefined;

  useEffect(() => {
    if (deepLink === undefined || deepLink === null || registry.isLoading)
      return;
    const locationMatch = matchPath(
      "/workflows/:siteName?/:listName?/:itemId?",
      deepLink
    );
    const siteName = locationMatch?.params["siteName"];
    const listName = locationMatch?.params["listName"];
    const itemId = locationMatch?.params["itemId"];
    const foundSite = registry.findSiteRegistryByName(siteName);
    const foundList = registry.findListRegistryByNameInSiteRegistry(
      foundSite,
      listName
    );
    selectedContentHub.setSelectedSite(foundSite?.id);
    selectedContentHub.setSelectedList(foundList?.id);
    selectedContentHub.setSelectedItem(itemId);
    setDeepLink(undefined);
  }, [
    deepLink,
    registry,
    selectedContentHub.setSelectedSite,
    selectedContentHub.setSelectedList,
    selectedContentHub.setSelectedItem,
    setDeepLink,
  ]);

  useEffect(() => {
    if (selectedContentHub.selectedSite === undefined) {
      setOpenSiteSelect(true);
    } else {
      setOpenSiteSelect(false);
    }
    if (selectedContentHub.selectedList === undefined) {
      setOpenListSelect(true);
    } else {
      setOpenListSelect(false);
    }

    setOpenViewSelect(false);
  }, [selectedContentHub]);

  const selectSite = (siteId: string | undefined) => {
    selectedContentHub.setSelectedSite(siteId);
    selectedContentHub.setSelectedList(undefined);
    setOpenSiteSelect(false);
  };

  const selectList = (listId: string | undefined) => {
    selectedContentHub.setSelectedList(listId);
    selectedContentHub.setSelectedItem(undefined);
    selectedContentHub.setSelectedView(undefined);
    setOpenListSelect(false);
  };

  const selectView = (viewId: string | undefined) => {
    selectedContentHub.setSelectedView(viewId);
    setOpenViewSelect(false);
  };

  const [openListSelect, setOpenListSelect] = useState<boolean>(false);
  const onListSelectOpenChange = (open: boolean) => {
    if (selectedContentHub.selectedList === undefined) {
      setOpenListSelect(true);
    } else {
      setOpenListSelect(open);
    }
  };

  const [openSiteSelect, setOpenSiteSelect] = useState<boolean>(false);
  const onSiteSelectOpenChange = (open: boolean) => {
    if (selectedContentHub.selectedSite === undefined) {
      setOpenSiteSelect(true);
    } else {
      setOpenSiteSelect(open);
    }
  };

  const [openViewSelect, setOpenViewSelect] = useState<boolean>(false);
  const onViewSelectOpenChange = (open: boolean) => {
    setOpenViewSelect(open);
  };

  const [openForm, setOpenForm] = useState<boolean>(false);

  const siteDropdownOptions = registry.registry.map((site) => ({
    value: site,
    displayText: site.text,
  }));
  const listDropdownOptions =
    selectedSiteRegistry?.lists.map((list) => ({
      value: list,
      displayText: list.header,
    })) ?? [];
  const viewDropdownOptions = [
    { value: undefined, displayText: "Default view" },
    ...((selectedList !== undefined
      ? contentHubContext.views[selectedList.id].map((view) => ({
          value: view,
          displayText: view.displayName,
        }))
      : undefined) ?? []),
  ];

  const selectedSiteOption =
    selectedSiteRegistry === undefined
      ? undefined
      : { value: selectedSiteRegistry, displayText: selectedSiteRegistry.text };
  const selectedListOption =
    selectedListRegistry === undefined
      ? undefined
      : {
          value: selectedListRegistry,
          displayText: selectedListRegistry.header,
        };

  const selectedViewOption =
    selectedView !== undefined
      ? {
          value: selectedView,
          displayText: selectedView.displayName,
        }
      : undefined;

  const [selectedItem, setSelectedItem] = useState<ContentHubItemElement>();

  useEffect(() => {
    if (
      selectedContentHub.selectedItem &&
      selectedContentHub.selectedList &&
      selectedContentHub.selectedSite
    ) {
      if (selectedItem?.id !== selectedContentHub.selectedItem) {
        ContentHubService.getItem(
          selectedContentHub.selectedSite,
          selectedContentHub.selectedList,
          selectedContentHub.selectedItem,
          teamsUserCredential!
        ).then((r) => {
          setSelectedItem(r);
        });
      }
    }
  }, [selectedContentHub.selectedItem]);

  useEffect(() => {
    if (selectedContentHub.selectedItem !== selectedItem?.id) {
      selectedContentHub.setSelectedItem(selectedItem?.id);
    }
    if (selectedItem !== undefined) {
      setOpenForm(true);
    }
  }, [selectedItem]);

  return (
    <div
      className={
        themeString === "default"
          ? "light"
          : themeString === "dark"
          ? "dark"
          : "contrast"
      }
    >
      {contentHubContext.isLoading && (
        <Spinner
          style={{ marginTop: "auto", marginBottom: "auto" }}
          size="medium"
          labelPosition="below"
          label="Loading sites and lists..."
        />
      )}
      {!contentHubContext.isLoading &&
        contentHubContext.error !== undefined && (
          <div style={{ margin: "auto" }}>
            <MessageBar intent="error">
              <MessageBarBody>
                <MessageBarTitle>Error</MessageBarTitle>
                {(contentHubContext.error as Error).message}
              </MessageBarBody>
            </MessageBar>
          </div>
        )}
      {!contentHubContext.isLoading &&
        contentHubContext.error === undefined && (
          <SelectedContentHubListContext.Provider value={selectedContentHub}>
            <div className="flexfill nooverflow">
              <div className="narrow page-padding breadcrumb-container">
                <Breadcrumb size="large">
                  <BreadcrumbDropdown
                    open={openSiteSelect}
                    onOpenChange={onSiteSelectOpenChange}
                    options={siteDropdownOptions}
                    placeholder="Select a site"
                    selectedOption={selectedSiteOption}
                    onOptionSelected={(v) =>
                      selectSite((v?.value as ContentHubSiteRegistry)?.id)
                    }
                  />
                  {selectedSiteOption && (
                    <>
                      <BreadcrumbDivider />
                      <BreadcrumbDropdown
                        open={openListSelect}
                        onOpenChange={onListSelectOpenChange}
                        options={listDropdownOptions}
                        placeholder="Select a list"
                        selectedOption={selectedListOption}
                        onOptionSelected={(v) =>
                          selectList((v?.value as ContentHubListRegistry)?.id)
                        }
                      />
                    </>
                  )}
                  {selectedListOption && (
                    <>
                      <BreadcrumbDivider />
                      <BreadcrumbDropdown
                        open={openViewSelect}
                        onOpenChange={onViewSelectOpenChange}
                        options={viewDropdownOptions}
                        placeholder="Default view"
                        selectedOption={selectedViewOption}
                        onOptionSelected={(v) =>
                          selectView((v?.value as ListViewDefinition)?.id)
                        }
                        editable={false}
                        onEdit={(v) => {
                          // trigger view update
                          console.log(v);
                        }}
                      />
                    </>
                  )}
                </Breadcrumb>
                {selectedList &&
                  selectedListRegistry?.id === selectedList.id && (
                    <Button
                      size="large"
                      appearance="primary"
                      onClick={() => {
                        setSelectedItem(undefined);
                        setOpenForm(true);
                      }}
                      disabled={openForm}
                    >
                      <AddFilled />
                      &nbsp;New Item
                    </Button>
                  )}
              </div>
              <div className="flexfill nooverflow">
                {selectedSite !== undefined &&
                  selectedSiteRegistry !== undefined && (
                    <div className="welcome page flexfill nooverflow">
                      <div
                        style={{ paddingTop: 0 }}
                        className="narrow page-padding flexfill nooverflow"
                      >
                        {selectedList !== undefined &&
                          selectedListRegistry != null && (
                            <div className="flexfill nooverflow">
                              <div className="flexfill nooverflow">
                                <div className="flexfill nooverflow">
                                  {selectedList && (
                                    <ContentHubListContextProvider
                                      siteId={selectedList.siteId}
                                      listId={selectedList.id}
                                      viewId={selectedView?.id}
                                    >
                                      <ContentHubList
                                        onItemClicked={(item) => {
                                          setSelectedItem(item);
                                          setOpenForm(true);
                                        }}
                                      />
                                      <ContentHubForm
                                        open={openForm}
                                        selectedItem={
                                          selectedItem?.id ===
                                          selectedContentHub.selectedItem
                                            ? selectedItem
                                            : undefined
                                        }
                                        onSave={(i) => {
                                          setSelectedItem(i);
                                          if (selectedItem === undefined) {
                                            selectedContentHub.setSelectedItem(
                                              i.id
                                            );
                                          }
                                        }}
                                        onOpenChange={(isOpen) => {
                                          if (isOpen == false) {
                                            setOpenForm(false);
                                            setSelectedItem(undefined);
                                          }
                                        }}
                                      />
                                    </ContentHubListContextProvider>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </SelectedContentHubListContext.Provider>
        )}
    </div>
  );
}
