import { ItemTest, ContentHubItemElement } from "./ContentHubItemElement";
import { IContentHubSearchFilter } from "./ContentHubSearchFilter";
import { PropertySort } from "./PropertySort";

export enum FilterModes {
  Any = "Any",
  All = "All",
}

export interface SearchItemsRequest<T = unknown> {
  fieldFilters: Partial<Record<keyof ItemTest<T>, IContentHubSearchFilter>>;
  fieldFiltersMode: FilterModes;
  itemFilters: Partial<
    Record<keyof Omit<ContentHubItemElement, "fields">, IContentHubSearchFilter>
  >;
  itemFiltersMode: FilterModes;
  sortingOptions: PropertySort[];
}

export interface AdvancedSearchItemsRequest<T = unknown> {
  filters: SearchItemsRequestFilter<T>[];
  sortingOptions: PropertySort[];
}

export interface SearchItemsRequestFilter<T = unknown> {
  fieldFilters: Partial<Record<keyof ItemTest<T>, IContentHubSearchFilter>>;
  fieldFiltersMode: FilterModes;
  itemFilters: Partial<
    Record<keyof Omit<ContentHubItemElement, "fields">, IContentHubSearchFilter>
  >;
  itemFiltersMode: FilterModes;
}
