
export enum ApprovalStatus {
  Denied = 0,
  Approved = 1,
  Pending = 2,
  Cancelled = 3,
  New = 4,
  All = 5,
  RevisionRequired = 6
}
