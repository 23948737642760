import { ComparableTypeFilterEditor } from "../../../Components/ContentHub/ContentHubSearchControls/ComparableTypeFilterEditor";
import { PersonFilterEditor } from "../../../Components/ContentHub/ContentHubSearchControls/PersonFilterEditor";
import { StringFilterEditor } from "../../../Components/ContentHub/ContentHubSearchControls/StringFilterEditor";
import { ContentHubItemElement } from "../../../Types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../Types/ContentHub/ContentHubListsElement";
import { SortingDirection } from "../../../Types/ContentHub/PropertySort";
import { ContentHubListRegistry } from "../../../Types/ContentHub/Registry/ContentHubListRegistry";
import { ContentHubUser } from "../../../Types/General/ContentHubUser";
import { formatCurrency, formatDate } from "../../../Utils/Utils";
import { BadDebt, BadDebtSchema } from "./BadDebt";
import { BadDebtForm } from "./BadDebtForm";

export const BadDebtListRegistry: ContentHubListRegistry<BadDebt> = {
  name: "BadDebt",
  header: "Bad Debt",
  schema: BadDebtSchema,
  form: BadDebtForm,
  columns: [
    { key: "id", displayName: "ID", visible: false },
    {
      key: "sequentialId",
      displayName: "ID",
      style: { width: "50px" },
      sort: {
        propertyName: "sequentialId",
      },
      filter: {
        propertyName: "sequentialId",
        renderer(currentValue, callback) {
          return (
            <ComparableTypeFilterEditor
              initialValue={currentValue}
              valueType="int"
              valueChanged={(v) => {
                callback("sequentialId", v);
              }}
            ></ComparableTypeFilterEditor>
          );
        },
      },
    },
    {
      key: "counterparty",
      displayName: "Counterparty",
      sort: {
        propertyName: "counterparty",
      },
      style: { width: "100px", minWidth: "100px" },
      filter: {
        propertyName: "fields.counterparty",
        renderer(currentValue, callback, list) {
          return (
            <StringFilterEditor
              initialValue={currentValue}
              valueType={
                list.fields.find((f) => f.name === "counterparty")!.type
              }
              valueChanged={(v) => {
                callback("counterparty", v);
              }}
            ></StringFilterEditor>
          );
        },
      },
    },
    {
      key: "accountNumber",
      displayName: "Account Number",
      style: { width: "50px" },
      sort: {
        propertyName: "accountNumber",
      },
      filter: {
        propertyName: "fields.accountNumber",
        renderer(currentValue, callback, list) {
          return (
            <ComparableTypeFilterEditor
              initialValue={currentValue}
              valueType={
                list.fields.find((f) => f.name === "accountNumber")!.type
              }
              valueChanged={(v) => {
                callback("accountNumber", v);
              }}
            ></ComparableTypeFilterEditor>
          );
        },
      },
    },
    {
      key: "writeOffTotal",
      displayName: "Write-Off Total",
      style: { width: "50px" },
      sort: {
        propertyName: "writeOffTotal",
      },
      filter: {
        propertyName: "fields.writeOffTotal",
        renderer(currentValue, callback, list) {
          return (
            <ComparableTypeFilterEditor
              initialValue={currentValue}
              valueType={
                list.fields.find((f) => f.name === "writeOffTotal")!.type
              }
              valueChanged={(v) => {
                callback("writeOffTotal", v);
              }}
            ></ComparableTypeFilterEditor>
          );
        },
      },
    },
    {
      key: "requestor",
      displayName: "Requestor",
      style: { width: "50px" },
      sort: {
        propertyName: "requestor.name",
      },
      filter: {
        propertyName: "fields.requestor",
        renderer(currentValue, callback, list) {
          return (
            <PersonFilterEditor
              initialValue={currentValue}
              valueType={list.fields.find((f) => f.name === "requestor")!.type}
              valueChanged={(v) => {
                callback("requestor", v);
              }}
            ></PersonFilterEditor>
          );
        },
      },
    },
    {
      key: "requestStatus",
      displayName: "Request Status",
      style: { width: "50px" },
      sort: {
        propertyName: "requestStatus",
      },
      filter: {
        propertyName: "fields.requestStatus",
        renderer(currentValue, callback, list) {
          return (
            <StringFilterEditor
              initialValue={currentValue}
              valueType={
                list.fields.find((f) => f.name === "requestStatus")!.type
              }
              options={
                list.fields.find((f) => f.name === "requestStatus")!.options ??
                undefined
              }
              valueChanged={(v) => {
                callback("requestStatus", v);
              }}
            ></StringFilterEditor>
          );
        },
      },
    },
    {
      key: "createdDate",
      displayName: "Created Date",
      style: { width: "50px" },
      sort: {
        propertyName: "createdDate",
        defaultSortIndex: 1,
        defaultSortingDirection: SortingDirection.Descending,
      },
      filter: {
        propertyName: "createdDate",
        renderer(currentValue, callback) {
          return (
            <ComparableTypeFilterEditor
              initialValue={currentValue}
              valueType="datetime"
              valueChanged={(v) => {
                callback("createdDate", v);
              }}
            ></ComparableTypeFilterEditor>
          );
        },
      },
    },
  ],
  mapper: function (
    item: ContentHubItemElement<BadDebt>,
    list: ContentHubListsElement
  ) {
    const requestor = item.fields.requestor.value as ContentHubUser;
    return {
      id: item.id,
      sequentialId: `${list.sequentialIdPrefix ?? ""}${item.sequentialId}`,
      counterparty: item.fields.counterparty?.value,
      accountNumber: item.fields.accountNumber?.value,
      writeOffTotal: formatCurrency(item.fields.writeOffTotal?.value ?? 0),
      requestor: requestor.name,
      requestStatus: item.fields.requestStatus.value,
      createdDate: formatDate(new Date(item.createdDate)),
    };
  },
};
