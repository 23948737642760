import {
  Body1Strong,
  Button,
  Field,
  Input,
  Label,
  Subtitle1,
  Switch,
  Textarea,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { ErrorMessage } from "@hookform/error-message";
import { useCallback, useContext, useEffect, useId, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FileUploaderCommon } from "../../../Components/Common/FileUploader/FileUploader";
import { NumericInput } from "../../../Components/Common/NumericInput/NumericInput";
import { SelectCounterparty } from "../../../Components/Common/SelectCounterparty/SelectCounterparty";
import { SelectDropDownData } from "../../../Components/Common/SelectDropDownData/SelectDropDownData";
import { SelectPerson } from "../../../Components/Common/SelectPerson/SelectPerson";
import { ContentHubFormControls } from "../../../Components/ContentHub/ContentHubForm/ContentHubForm";
import { UserContext } from "../../../Context/UserContext";
import { useContentHubChoiceField } from "../../../Hooks/useContentHubChoiceField";
import * as UserService from "../../../Services/General/UserService";
import { ContentHubAttachments } from "../../../Types/ContentHub/ContentHubAttachments";
import { ContentHubItemElement } from "../../../Types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../Types/ContentHub/ContentHubListsElement";
import { Counterparty } from "../../../Types/General/Counterparty";
import { dropDownToString } from "../../../Types/General/DropDownData";
import { formatCurrency, formatDate } from "../../../Utils/Utils";
import { BadDebt } from "./BadDebt";
import "./BadDebt.css";

export const DefaultBadDebtFormValue = {
  counterparty: "",
  counterpartyId: "",
  accountNumber: "",
  rmAccountStatus: "",
  collector: null,
  glNumber: "110510",
  electricity: 0,
  gas: 0,
  terminationValue: 0,
  writeOffTotal: 0,
  writeOffSummary: "",
  creditApprovalDetails: "",
  reasonCode: "",
  filingDate: null,
  eventRelated: false,
  eventDetails: "",
  salesPerson: null,
  rvp: null,
  region: "",
  counterpartyClass: "",
  sicCode: "",
  reserved: "",
  requestor: null,
  requestStatus: "Pending",
  accountingReview: null,
  accountingStatus: "Pending",
  accountingComments: "",
  journalEntryNumber: null,
  dateCompleted: null,
  _attachments: null,
};

export const BadDebtForm = ({
  item,
  list,
  formControls,
}: {
  item: ContentHubItemElement | undefined;
  list: ContentHubListsElement;
  formControls: ContentHubFormControls;
}) => {
  const badDebtItem = item as ContentHubItemElement<BadDebt> | undefined;
  const form = useFormContext();
  const { userInfo } = useContext(UserContext);

  //Dropdown Options
  const rmStatusOptions = useContentHubChoiceField(list, "rmAccountStatus");
  const glOptions = useContentHubChoiceField(list, "glNumber");
  const reasonCodeOptions = useContentHubChoiceField(list, "reasonCode");
  const regionOptions = useContentHubChoiceField(list, "region");
  const reservedOptions = useContentHubChoiceField(list, "reserved");
  const accountingStatusOptions = useContentHubChoiceField(
    list,
    "accountingStatus"
  );

  const extractContentHubItem = useCallback(
    (itemToConvert: ContentHubItemElement<BadDebt>) => {
      //Info Load
      const emptyAttachment: ContentHubAttachments = {
        itemFolderId: "",
        filenames: [],
      };

      const infoEdit: BadDebt = {
        counterparty: itemToConvert.fields.counterparty.value,
        counterpartyId: itemToConvert.fields.counterpartyId.value,
        accountNumber: itemToConvert.fields.accountNumber.value,
        rmAccountStatus: itemToConvert.fields.rmAccountStatus.value,
        collector: itemToConvert.fields.collector.value,
        glNumber: itemToConvert.fields.glNumber.value,
        electricity: itemToConvert.fields.electricity.value,
        gas: itemToConvert.fields.gas.value,
        terminationValue: itemToConvert.fields.terminationValue.value,
        writeOffTotal: itemToConvert.fields.writeOffTotal.value,
        writeOffSummary: itemToConvert.fields.writeOffSummary.value,
        creditApprovalDetails: itemToConvert.fields.creditApprovalDetails.value,
        reasonCode: itemToConvert.fields.reasonCode.value,
        filingDate: itemToConvert.fields.filingDate?.value
          ? new Date(itemToConvert.fields.filingDate?.value)
          : null,
        eventRelated: itemToConvert.fields.eventRelated.value,
        eventDetails: itemToConvert.fields.eventDetails.value,
        salesPerson: itemToConvert.fields.salesPerson.value,
        rvp: itemToConvert.fields.rvp.value,
        region: itemToConvert.fields.region.value,
        counterpartyClass: itemToConvert.fields.counterpartyClass.value,
        sicCode: itemToConvert.fields.sicCode.value,
        reserved: itemToConvert.fields.reserved.value,
        requestor: itemToConvert.fields.requestor.value,
        requestStatus: itemToConvert.fields.requestStatus.value,
        accountingReview: itemToConvert.fields.accountingReview?.value,
        accountingStatus: itemToConvert.fields.accountingStatus.value,
        accountingComments: itemToConvert.fields.accountingComments.value,
        journalEntryNumber: itemToConvert.fields.journalEntryNumber!.value,
        dateCompleted: itemToConvert.fields.dateCompleted?.value
          ? new Date(itemToConvert.fields.dateCompleted?.value)
          : null,
        _attachments:
          itemToConvert.fields._attachments?.value ?? emptyAttachment,
        _workflowStep: itemToConvert.fields._workflowStep?.value ?? "",
      };
      return infoEdit;
    },
    []
  );

  const formFieldsKeySuffix = useId();
  const [formFieldsKey, setFormFieldsKey] = useState<string>("");

  useEffect(() => {
    setFormFieldsKey(new Date().toISOString() + formFieldsKeySuffix);
  }, [item]);

  const status = form.getValues("requestStatus");
  const workflowStep = form.getValues("_workflowStep");
  const requestor = form.getValues("requestor");

  const [reloadFiles, setReloadFiles] = useState<boolean>(false);
  useEffect(() => {
    if (badDebtItem !== undefined) {
      formControls.setTitle(
        `[${badDebtItem.sequentialId}] - Bad Debt Request - [${badDebtItem.fields.counterparty.value}]`
      );
      formControls.setPanelTabs(["Request", "Accounting"]);

      //Info Load
      const infoEdit = extractContentHubItem(badDebtItem);
      form.reset(infoEdit);
      setReloadFiles(true);
      setGas(form.getValues("gas"));
      setElectricity(form.getValues("electricity"));
      setTermination(form.getValues("terminationValue"));
    } else {
      formControls.setTitle("New Bad Debt Request");
      formControls.setPanelTabs(["Request"]);
      form.reset(DefaultBadDebtFormValue as unknown as BadDebt);
      setReloadFiles(false);

      if (userInfo !== undefined) {
        const user = UserService.userProfileToContentHubUser(userInfo);
        if (user !== undefined) {
          form.setValue("requestor", user);
          form.setValue("collector", user, { shouldValidate: true });
        }
      }
    }
    formControls.setSelectedTab("Request");
  }, [item]);

  useEffect(() => {
    if (badDebtItem !== undefined) {
      const mandatoryControls: JSX.Element[] = [
        <Label key={0}>
          <Body1Strong>Status: </Body1Strong>
          {status}
        </Label>,
      ];
      if (status === "Request Completed") {
        formControls.setControls([
          <Label key={1}>
            <Body1Strong>Date Completed: </Body1Strong>
            {formatDate(badDebtItem.fields.dateCompleted?.value ?? new Date())}
          </Label>,
          ...mandatoryControls,
        ]);
      } else if (status === "Pending") {
        formControls.setControls([
          <Button
            key={1}
            disabled={userInfo?.id !== requestor.id}
            appearance="primary"
            onClick={() =>
              formControls.submitWithoutValidation({
                ...extractContentHubItem(badDebtItem),
                requestStatus: "Ready to submit",
              })
            }
          >
            Submit Request
          </Button>,
          ...mandatoryControls,
        ]);
      } else if (
        status === "Revision Required" &&
        workflowStep === "managerRequiredMoreInfoSentEmail"
      ) {
        formControls.setControls([
          <Button
            key={1}
            appearance="primary"
            disabled={userInfo?.id !== requestor.id}
            onClick={() =>
              formControls.submitWithoutValidation({
                ...extractContentHubItem(badDebtItem),
                requestStatus: "Ready for Manager of Credit Risk Review",
              })
            }
          >
            Ready for Manager of Credit Risk Review
          </Button>,
          ...mandatoryControls,
        ]);
      } else if (
        status === "Revision Required" &&
        workflowStep === "managingDirectorRequiredMoreInfoSentEmail"
      ) {
        formControls.setControls([
          <Button
            key={1}
            disabled={userInfo?.id !== requestor.id}
            appearance="primary"
            onClick={() =>
              formControls.submitWithoutValidation({
                ...extractContentHubItem(badDebtItem),
                requestStatus:
                  "Ready for Managing Director of Credit Risk Review",
              })
            }
          >
            Ready for Managing Director of Credit Risk Review
          </Button>,
          ...mandatoryControls,
        ]);
      } else {
        formControls.setControls([...mandatoryControls]);
      }
      if (badDebtItem.isLocked) {
        formControls.setCanSubmit(false);
        formControls.setCanEdit(false);
        formControls.setCanEditAttachments(false);
        return;
      }
    } else {
      formControls.setControls([]);
    }

    switch (status) {
      case "Pending":
        formControls.setCanSubmit(true);
        formControls.setCanEdit(true);
        formControls.setCanEditAttachments(true);
        break;

      case "Approved for Accounting Review":
        formControls.setCanSubmit(true);
        formControls.setCanEdit(false);
        formControls.setCanEditAttachments(false);
        break;

      case "Revision Required":
        formControls.setCanSubmit(true);
        formControls.setCanEdit(true);
        formControls.setCanEditAttachments(true);
        break;

      default:
        formControls.setCanSubmit(false);
        formControls.setCanEdit(false);
        formControls.setCanEditAttachments(false);
        break;
    }
  }, [status, requestor, userInfo]);

  //Set Required For Filing Date
  const reasonCodeSelected = form.watch("reasonCode");
  const [filingDateRequired, setFilingDateRequired] = useState<boolean>(false);
  useEffect(() => {
    const optionSelected = dropDownToString(reasonCodeSelected);
    setFilingDateRequired(optionSelected === "Bankruptcy" ? true : false);
    form.trigger("filingDate", { shouldFocus: true });
  }, [reasonCodeSelected]);

  //Set Required for Event Details
  const eventRelated = form.watch("eventRelated");
  const [eventDetailsRequired, setEventDetailsRequired] =
    useState<boolean>(false);
  useEffect(() => {
    setEventDetailsRequired(eventRelated);
    form.trigger("eventDetails", { shouldFocus: true });
  }, [eventRelated]);

  //Calculate Write Off
  const [electricity, setElectricity] = useState<number>(0.0);
  const [gas, setGas] = useState<number>(0.0);
  const [termination, setTermination] = useState<number>(0.0);
  const [totalWriteOff, setTotalWriteOff] = useState<number>(0);

  const sumValue = electricity + +gas + +termination;

  useEffect(() => {
    form.setValue("electricity", electricity);
    form.setValue("gas", gas);
    form.setValue("terminationValue", termination);

    setTotalWriteOff(sumValue);
    form.setValue("writeOffTotal", sumValue, { shouldValidate: true });
    if (sumValue > 0) {
      form.trigger("reasonCode");
    }
  }, [sumValue]);

  //DropDowns Info Counterparties
  const defaultCounterparty: Counterparty = {
    name: badDebtItem?.fields.counterparty?.value ?? "",
    sic: badDebtItem?.fields.sicCode?.value ?? "",
    counterpartyClass: badDebtItem?.fields.counterpartyClass?.value ?? "",
    counterpartyId: parseInt(badDebtItem?.fields.counterpartyId?.value ?? "0"),
    status: "ACTIVE",
  };
  const handleCounterparty = (counterparty: Counterparty | undefined) => {
    if (counterparty !== undefined) {
      form.setValue("counterparty", counterparty.name);
      form.setValue("counterpartyId", counterparty.counterpartyId.toString());
      form.setValue("sicCode", counterparty.sic);
      form.setValue("counterpartyClass", counterparty.counterpartyClass);
    }
  };

  return (
    <>
      <div
        className="requestArea"
        style={{
          display: formControls.selectedTab === "Request" ? undefined : "none",
        }}
      >
        {/* CounterpartyName */}
        <div className="counterpartyDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="counterparty" />
          </div>
          <SelectCounterparty
            onError={() => {
              formControls.setMessages([
                {
                  intent: "error",
                  title: "Error",
                  message: "Error while retrieving counterparties.",
                },
              ]);
            }}
            disabled={!formControls.canEdit}
            label="Counterparty"
            showInfo={true}
            defaultCounterparty={defaultCounterparty}
            control={form.control}
            name="counterparty"
            handleCounterpartySelection={handleCounterparty}
            required={true}
          />
        </div>

        {/* Account Number */}
        <div className="accountNumberDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="accountNumber" />
          </div>
          <NumericInput
            disabled={!formControls.canEdit}
            label="Account Number"
            control={form.control}
            name="accountNumber"
            defaultValue={badDebtItem?.fields.accountNumber.value ?? 1}
            handleChange={(data) => form.setValue("accountNumber", data)}
            minValue={1}
            required={true}
            decimals={0}
          />
        </div>

        {/* RM Account Status */}
        <div className="rmAccountStatusDiv">
          <div className="errorMessage">
            <ErrorMessage
              errors={form.formState.errors}
              name="rmAccountStatus"
            />
          </div>
          <SelectDropDownData
            disabled={!formControls.canEdit}
            label="RM Account Status"
            options={rmStatusOptions.dropdownOptions}
            control={form.control}
            name="rmAccountStatus"
            required={true}
          />
        </div>

        {/* Collector */}
        <div className="collectorDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="collector" />
          </div>
          <SelectPerson
            onError={() => {
              formControls.setMessages([
                {
                  intent: "error",
                  title: "Error",
                  message: "Error while retrieving counterparties.",
                },
              ]);
            }}
            disabled={!formControls.canEdit}
            label="Collector"
            group="SGG_NES_CREDITRISK"
            control={form.control}
            name="collector"
            required={true}
          />
        </div>

        {/* GL Number */}
        <div className="glNumberDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="glNumber" />
          </div>
          <SelectDropDownData
            disabled={!formControls.canEdit}
            label="GL Number"
            options={glOptions.dropdownOptions}
            control={form.control}
            name="glNumber"
            required={true}
          />
        </div>

        {/* Electricity */}
        <div className="electricityDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="electricity" />
          </div>
          <NumericInput
            disabled={!formControls.canEdit}
            label="Electricity"
            control={form.control}
            name="electricity"
            defaultValue={electricity}
            handleChange={(data) => setElectricity(data)}
            isMoney={true}
          />
        </div>

        {/* Gas */}
        <div className="gasDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="gas" />
          </div>
          <NumericInput
            disabled={!formControls.canEdit}
            label="Gas"
            control={form.control}
            name="gas"
            defaultValue={gas}
            handleChange={(data) => setGas(data)}
            isMoney={true}
          />
        </div>

        {/* Termination Value */}
        <div className="terminationValueDiv">
          <div className="errorMessage">
            <ErrorMessage
              errors={form.formState.errors}
              name="terminationValue"
            />
          </div>
          <NumericInput
            disabled={!formControls.canEdit}
            label="Termination Value"
            control={form.control}
            name="terminationValue"
            defaultValue={termination}
            handleChange={(data) => setTermination(data)}
            isMoney={true}
          />
        </div>

        {/* Write Off Total */}
        <div className="writeOffTotalDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="writeOffTotal" />
          </div>
          <Field label="Write Off Total">
            <Label>
              <b>{formatCurrency(totalWriteOff)}</b>
            </Label>
          </Field>
        </div>

        {/* Write Off Summary */}
        <div className="writeOffSummaryDiv">
          <div className="errorMessage">
            <ErrorMessage
              errors={form.formState.errors}
              name="writeOffSummary"
            />
          </div>
          <Field label="Write Off Summary" required>
            <Controller
              name="writeOffSummary"
              control={form.control}
              render={({ field }) => (
                <Textarea
                  {...field}
                  key={formFieldsKey}
                  disabled={(field?.disabled ?? false) || !formControls.canEdit}
                  className="writeOffSummaryInput"
                  rows={3}
                />
              )}
            />
          </Field>
        </div>

        {/* Credit Approval Details */}
        <div className="creditApprovalDetailsDiv">
          <div className="errorMessage">
            <ErrorMessage
              errors={form.formState.errors}
              name="creditApprovalDetails"
            />
          </div>
          <Field label="Credit Approval Details" required>
            <Controller
              name="creditApprovalDetails"
              control={form.control}
              render={({ field }) => (
                <Textarea
                  {...field}
                  key={formFieldsKey}
                  disabled={(field?.disabled ?? false) || !formControls.canEdit}
                  className="creditApprovalDetailsInput"
                  rows={3}
                />
              )}
            />
          </Field>
        </div>

        {/* Reason Code */}
        <div className="reasonCodeDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="reasonCode" />
          </div>
          <SelectDropDownData
            disabled={!formControls.canEdit}
            label="Reason"
            options={reasonCodeOptions.dropdownOptions}
            control={form.control}
            name="reasonCode"
            required={true}
          />
        </div>

        {/* Filing Date */}
        <div className="filingDateDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="filingDate" />
          </div>
          <Field label="Filing Date" required={filingDateRequired}>
            <Controller
              name="filingDate"
              control={form.control}
              render={({ field: { value, onChange, ref } }) => (
                <DatePicker
                  //onOpenChange={onOpen}
                  key={value}
                  disabled={!formControls.canEdit}
                  onSelectDate={onChange}
                  ref={ref}
                  placeholder="Select a date..."
                  value={value}
                  formatDate={(value) => {
                    return value !== undefined ? formatDate(value) : "";
                  }}
                />
              )}
            />
          </Field>
        </div>

        {/* Event Related */}
        <div className="eventRelatedDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="eventRelated" />
          </div>
          <Field label="Event Related">
            <Controller
              name="eventRelated"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <Switch
                  key={value}
                  disabled={!formControls.canEdit}
                  name="name"
                  checked={value}
                  onChange={onChange}
                />
              )}
            />
          </Field>
        </div>

        {/* Event Details */}
        <div className="eventDetailsDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="eventDetails" />
          </div>
          <Field label="Event Details" required={eventDetailsRequired}>
            <Controller
              name="eventDetails"
              control={form.control}
              render={({ field }) => (
                <Input
                  key={formFieldsKey}
                  disabled={!formControls.canEdit}
                  {...field}
                  className="eventDetailsInput"
                />
              )}
            />
          </Field>
        </div>

        {/* Salesperson */}
        <div className="salespersonDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="salesPerson" />
          </div>
          <SelectPerson
            onError={() => {
              formControls.setMessages([
                {
                  intent: "error",
                  title: "Error",
                  message: "Error while retrieving counterparties.",
                },
              ]);
            }}
            disabled={!formControls.canEdit}
            label="Sales Person"
            control={form.control}
            group="SGG_NES_SALES"
            name="salesPerson"
            required={true}
          />
        </div>

        {/* RVP */}
        <div className="rvpDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="rvp" />
          </div>
          <SelectPerson
            onError={() => {
              formControls.setMessages([
                {
                  intent: "error",
                  title: "Error",
                  message: "Error while retrieving counterparties.",
                },
              ]);
            }}
            disabled={!formControls.canEdit}
            label="RVP"
            control={form.control}
            group="SGG_NES_SALES"
            name="rvp"
            required={true}
          />
        </div>

        {/* Region */}
        <div className="regionDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="region" />
          </div>
          <SelectDropDownData
            disabled={!formControls.canEdit}
            label="Region"
            options={regionOptions.dropdownOptions}
            control={form.control}
            name="region"
            required={true}
          />
        </div>

        {/* Reserved */}
        <div className="reservedDiv">
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="reserved" />
          </div>
          <SelectDropDownData
            disabled={!formControls.canEdit}
            label="Reserved"
            options={reservedOptions.dropdownOptions}
            control={form.control}
            name="reserved"
            required={true}
          />
        </div>

        {/* Attachments */}
        <div className="attachmentsDiv">
          <Subtitle1>Attachments</Subtitle1>
          <div className="errorMessage">
            <ErrorMessage errors={form.formState.errors} name="_attachments" />
            <ErrorMessage
              errors={form.formState.errors}
              name="_attachments.filenames"
            />
            <ErrorMessage
              errors={form.formState.errors}
              name="_attachments.filesToUpload"
            />
          </div>
          <FileUploaderCommon
            disabled={!formControls.canEdit && !formControls.canEditAttachments}
            reload={reloadFiles}
            attachment={
              reloadFiles && badDebtItem?.fields._attachments !== undefined
                ? badDebtItem?.fields._attachments.value
                : null
            }
            listDefinition={list!}
            filesOnForm={formControls.handleFiles}
            deletedFile={formControls.deleteFile}
            fileTypes={["pdf", "txt", "xlsx", "xls"]}
          />
        </div>
      </div>

      {/* eslint-disable-next-line no-constant-binary-expression */}

      <div
        className="accountingArea"
        style={{
          display:
            formControls.selectedTab === "Accounting" ? undefined : "none",
        }}
      >
        {/* Accounting Reviewd By */}
        <div className="accountingReviewDiv">
          <div className="errorMessage">
            <ErrorMessage
              errors={form.formState.errors}
              name="accountingReview"
            />
          </div>
          <SelectPerson
            onError={() => {
              formControls.setMessages([
                {
                  intent: "error",
                  title: "Error",
                  message: "Error while retrieving counterparties.",
                },
              ]);
            }}
            disabled={status !== "Approved for Accounting Review"}
            label="Accounting Reviewed By"
            control={form.control}
            name="accountingReview"
            group="SGG_NES_COMMODITYACCOUNTING"
            required={true}
          />
        </div>

        {/* Accounting Status */}
        <div className="accountingStatusDiv">
          <div className="errorMessage">
            <ErrorMessage
              errors={form.formState.errors}
              name="accountingStatus"
            />
          </div>
          <SelectDropDownData
            disabled={status !== "Approved for Accounting Review"}
            label="Accounting Review Status"
            options={accountingStatusOptions.dropdownOptions}
            control={form.control}
            name="accountingStatus"
            required={true}
          />
        </div>

        {/* Accounting Comments */}
        <div className="accountingCommentsDiv">
          <div className="errorMessage">
            <ErrorMessage
              errors={form.formState.errors}
              name="accountingComments"
            />
          </div>
          <Field label="Accounting Comments">
            <Controller
              name="accountingComments"
              control={form.control}
              render={({ field }) => (
                <Textarea
                  key={formFieldsKey}
                  disabled={status !== "Approved for Accounting Review"}
                  {...field}
                  className="accountingCommentsInput"
                  rows={3}
                />
              )}
            />
          </Field>
        </div>
        <div className="completedDateDiv"></div>
      </div>
    </>
  );
};
