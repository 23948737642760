import {
  Avatar,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Skeleton,
  SkeletonItem,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableHeader,
  TableHeaderCell,
  TableRow,
  createTableColumn,
  useTableFeatures,
} from "@fluentui/react-components";
import { ApprovalHistoryState } from "../../../Types/Approvals/ApprovalHistoryState";
import {
  ApprovalStatusToString,
} from "../../../Types/Approvals/ApprovalRequest";
import { ApprovalStatus } from "../../../Types/Approvals/ApprovalStatus";
import { formatDate } from "../../../Utils/Utils";
import * as ApprovalService from "../../../Services/Approvals/ApprovalService";
import { TeamsFxContext } from "../../../Context/TeamsFxContext";
import "./ApprovalHistory.css";
import { useContext, useState, useEffect, Fragment } from "react";

export const ApprovalHistory = ({
  correlationKey,
  requestId,
  history,
}: {
  correlationKey: string;
  requestId?: string;
  history?: ApprovalHistoryState[];
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemHistory, setItemHistory] = useState<ApprovalHistoryState[]>([]);
  const [historyErrorFlag, setHistoryErrorFlag] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading === false) return;
    (async () => {
      try {
        if (!teamsUserCredential) {
          throw new Error("TeamsFx SDK is not initialized.");
        }
        const _itemHistory = await ApprovalService.getApprovalHistory(
          correlationKey,
          teamsUserCredential
        );
        if (_itemHistory !== undefined) {
          _itemHistory.sort(
            (a, b) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime()
          );
          setItemHistory(_itemHistory);
        }
      } catch {
        setHistoryErrorFlag(true);
      }
      setIsLoading(false);
    })();
  }, [isLoading]);

  useEffect(() => {
    if (history) {
      setItemHistory(history);
    } else {
      setIsLoading(true);
    }
  }, [correlationKey, history]);

  //Define elements
  const tableColumns: TableColumnDefinition<ApprovalHistoryState>[] = [
    createTableColumn<ApprovalHistoryState>({
      columnId: "date",
      renderHeaderCell: () => (
        <TableHeaderCell style={{ width: "21ch" }}>Date</TableHeaderCell>
      ),
      renderCell: (item) => formatDate(item.createdDate),
    }),
    createTableColumn<ApprovalHistoryState>({
      columnId: "change",
      renderHeaderCell: () => <TableHeaderCell>Change</TableHeaderCell>,
      renderCell: (item) => {
        const approvalStatus = ApprovalStatusToString(item.status);

        let message = "Status changed to {status}";
        if (item.messageTemplate && item.status !== ApprovalStatus.Pending) {
          message = item.messageTemplate;
        }
        const messageParts = message.split("{status}");
        if (messageParts.length < 2) {
          return <span>{message}</span>;
        }
        return (
          <span>
            {messageParts
              .filter((p) => p !== "")
              .map((p, i) => (
                <Fragment key={i}>
                  {p}
                  <b>{approvalStatus}</b>
                </Fragment>
              ))}
          </span>
        );
      },
    }),
    createTableColumn<ApprovalHistoryState>({
      columnId: "user",
      renderHeaderCell: () => (
        <TableHeaderCell style={{ width: "30ch" }}>User</TableHeaderCell>
      ),
      renderCell: (item) => {
        const displayedUser = item.modifiedBy;
        return displayedUser ? (
          <TableCellLayout
            media={
              <Avatar
                aria-label={displayedUser.email}
                name={displayedUser.name}
                badge={{}}
              ></Avatar>
            }
          >
            {displayedUser.name}
          </TableCellLayout>
        ) : (
          <TableCellLayout>System</TableCellLayout>
        );
      },
    }),
  ];

  //Comments UI

  const { getRows } = useTableFeatures<ApprovalHistoryState>({
    columns: tableColumns,
    items: itemHistory,
  });
  const rows = getRows();
  return (
    <>
      {!isLoading && !historyErrorFlag && (
        <Table size="extra-small">
          <TableHeader
            style={{ borderBottom: "solid 1px var(--colorNeutralStrokeAlpha)" }}
          >
            <TableRow>{tableColumns.map((c) => c.renderHeaderCell())}</TableRow>
          </TableHeader>
          <TableBody>
            {rows
              .filter(
                (r) => requestId === undefined || r.item.requestId === requestId
              )
              .map((row, i) => (
                <TableRow key={i}>
                  {tableColumns.map((column) => (
                    <TableCell key={column.columnId}>
                      {column.renderCell(row.item)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}

      {historyErrorFlag && (
        <MessageBar intent="error">
          <MessageBarBody>
            <MessageBarTitle>Error</MessageBarTitle>
            The history could not be retrieved from the server.
          </MessageBarBody>
        </MessageBar>
      )}

      {isLoading && (
        <Skeleton>
          {Array(3)
            .fill(null)
            .map((_, i) => (
              <div
                key={i}
                style={{
                  marginBottom: "4px",
                  marginTop: "4px",
                  display: "grid",
                  gridTemplateColumns: "20ch 20ch min-content",
                  justifyContent: "space-between",
                }}
              >
                <SkeletonItem shape="rectangle" />
                <SkeletonItem shape="rectangle" />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "min-content 20ch",
                    gap: "5px",
                    justifyContent: "end",
                  }}
                >
                  <SkeletonItem shape="circle" />
                  <SkeletonItem shape="rectangle" />
                </div>
              </div>
            ))}
        </Skeleton>
      )}
    </>
  );
};
