import { dropDownToString } from "../../../Types/General/DropDownData";
import { counterpartyToSingleValue } from "../../../Types/General/Counterparty";
import { object, string, ObjectSchema, boolean, date, number } from "yup";
import {
  ContentHubUserSchema,
} from "../../../Types/General/User";
import { ContentHubUser } from '../../../Types/General/ContentHubUser';
import {
  ContentHubAttachments,
  ContentHubAttachmentsSchema,
} from "../../../Types/ContentHub/ContentHubAttachments";

export type BadDebtRmAccountStatus = "Active" | "Final" | "Pending Final";
export type BadDebtGlStatus = "110510";
export type BadDebtReasonCode =
  | "Bankruptcy"
  | "Policy Adjustment"
  | "Terminated"
  | "Customer Account Maintenance";
export type BadDebtRegion = "Northeast" | "West" | "Gulf" | "Midwest";
export type BadDebtReserved = "Partial" | "No" | "Fully";
export type BadDebtReviewStatus =
  | "Pending"
  | "Approved"
  | "Revision Required"
  | "Denied"
  | "N/A";

export interface BadDebt {
  counterparty: string;
  counterpartyId: string;
  accountNumber: number;
  rmAccountStatus: BadDebtRmAccountStatus;
  collector: ContentHubUser;
  glNumber: BadDebtGlStatus;
  electricity: number;
  gas: number;
  terminationValue: number;
  writeOffTotal: number;
  writeOffSummary: string;
  creditApprovalDetails: string;
  reasonCode: BadDebtReasonCode;
  filingDate?: Date | null | undefined;
  eventRelated: boolean;
  eventDetails: string;
  salesPerson: ContentHubUser;
  rvp: ContentHubUser;
  region: BadDebtRegion;
  counterpartyClass: string;
  sicCode: string;
  reserved: BadDebtReserved;
  requestor: ContentHubUser;
  requestStatus: string;
  accountingReview?: object | null;
  accountingStatus: BadDebtReviewStatus;
  accountingComments: string;
  journalEntryNumber?: string | undefined | null;
  dateCompleted?: Date | null;
  _attachments?: ContentHubAttachments | null;
  _workflowStep: string;
}

export const BadDebtSchema: ObjectSchema<BadDebt> = object({
  counterparty: string()
    .defined()
    .required("Select a Counterparty")
    .transform((value) => counterpartyToSingleValue(value, "name"))
    .min(1),
  counterpartyId: string().required("Provide Counterparty ID"),
  accountNumber: number()
    .required("Provide account number")
    .integer("Provide a valid account number")
    .min(1, "Provide a valid account number"),
  rmAccountStatus: string<BadDebtRmAccountStatus>()
    .required("Select a Status")
    .transform((value) => dropDownToString(value)),
  collector: ContentHubUserSchema.defined("Select a collector").nonNullable("Select a collector"),
  glNumber: string<BadDebtGlStatus>()
    .required("Select a GL Number")
    .default("110510")
    .transform((value) => dropDownToString(value)),
  electricity: number().required("").default(0.0),
  gas: number().required("").default(0.0),
  terminationValue: number().required("").default(0.0),
  writeOffTotal: number().required("").default(0.0),
  writeOffSummary: string().required("Provide a Summary").default("0"),
  creditApprovalDetails: string().required("Provide Details"),
  reasonCode: string<BadDebtReasonCode>()
    .when("writeOffTotal", {
      is: (value: number) => value < 100,
      then: (schema) =>
        schema.notOneOf(
          ["Policy Adjustment"],
          "Cannot select Policy Adjustment (Write Off Total < 100)"
        ),
      otherwise: (schema) =>
        schema.notOneOf(
          ["Customer Account Maintenance"],
          "Cannot select Customer Account Maintenance (Write Off Total >= 100)"
        ),
    })
    .required("Provide a reason Code")
    .defined()
    .transform((value) => dropDownToString(value)),
  filingDate: date()
    .defined()
    .when("reasonCode", {
      is: "Bankruptcy",
      then: (schema) => schema.required("Provide a filing date"),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
  eventRelated: boolean().default(false).required("SELECT AN OPTION"),
  eventDetails: string()
    .defined()
    .when("eventRelated", {
      is: true,
      then: (schema) => schema.required("Provide Event Details"),
      otherwise: (schema) => schema.notRequired().default(""),
    }),
  salesPerson: ContentHubUserSchema.defined("Select a sales person").nonNullable("Select a sales person"),
  rvp: ContentHubUserSchema.defined("Select an RVP").nonNullable("Select an RVP"),
  region: string<BadDebtRegion>()
    .required("Select a Region")
    .transform((value) => dropDownToString(value)),
  counterpartyClass: string().required("Select a Counterparty Class"),
  sicCode: string().required("Provide a SIC Code"),
  reserved: string<BadDebtReserved>()
    .required("Select a Reserved Option")
    .transform((value) => dropDownToString(value)),
  requestor: ContentHubUserSchema.defined("Select a requestor").nonNullable("Select a requestor"),
  requestStatus: string().required().default("Pending"),
  accountingReview: object().notRequired().defined(),
  accountingStatus: string<BadDebtReviewStatus>()
    .default("N/A")
    .transform((value) => dropDownToString(value)),
  accountingComments: string().default(""),
  journalEntryNumber: string().defined().default("").nullable(),
  dateCompleted: date().notRequired().nullable(),
  _attachments: ContentHubAttachmentsSchema.required("Please add attachments"),
  _workflowStep: string().defined().default("NewRequest"),
});
