import {
  Button,
  CounterBadge,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderTitle,
  Field,
  Input,
  Label,
  Menu,
  MenuButtonProps,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  OverlayDrawer,
  Spinner,
  SplitButton,
} from "@fluentui/react-components";
import {
  Dismiss24Regular,
  FilterRegular,
  SaveEditRegular,
  SaveRegular,
} from "@fluentui/react-icons";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { ContentHubListContext } from "../../../Context/ContentHubListContext";
import * as ContentHubService from "../../../Services/ContentHub/ContentHubService";
import {
  BooleanOperatorSearchFilter,
  IContentHubSearchFilter,
} from "../../../Types/ContentHub/ContentHubSearchFilter";
import { ListViewDefinition } from "../../../Types/ContentHub/ListViewDefinition";
import { FilterModes } from "../../../Types/ContentHub/SearchItemsRequest";
import { TeamsFxContext } from "../../../Context/TeamsFxContext";
import { ContentHubMultilayerFilterEditor } from "./ContentHubMultilayerFilterEditor";
import { ContentHubContext } from "../../../Context/ContentHubContext";

export const ContentHubSearchControls = ({
  enableViewSave,
  enableViewSaveAs,
  disabled,
}: {
  enableViewSave?: boolean;
  enableViewSaveAs?: boolean;
  disabled?: boolean;
}) => {
  const listContext = useContext(ContentHubListContext);
  const contentHubContext = useContext(ContentHubContext);

  const saveCallback = useCallback(() => {}, []);
  const saveAsCallback = useCallback(() => {
    setSaveDialogOpen(true);
  }, []);

  const [filterEditorIsOpen, setFilterEditorIsOpen] = useState<boolean>(false);
  const searchCallback = useDebouncedCallback((inputValue) => {
    if (inputValue !== undefined) {
      listContext.dispatch({
        type: "setParameters",
        filters: listContext.listState.filters,
        sort: listContext.listState.sort,
        searchTerms: inputValue,
      });
      setHasUpdatedFilters(true);
      //searchTrigger!(inputValue);
    }
  }, 1000);

  const [filterFunctions, setFilterFunctions] = useState<
    Record<
      string,
      {
        propertyName: string;
        renderer: (
          currentValue: IContentHubSearchFilter | null,
          callback: (
            key: string,
            newValue: IContentHubSearchFilter | null
          ) => void
        ) => JSX.Element;
      }
    >
  >({});

  const [filterValues, setFilterValues] = useState<
    Record<string, IContentHubSearchFilter>[]
  >([{}]);
  useEffect(() => {
    setFilterValues(
      listContext.listState.filters?.map((layer) => {
        const fieldFilters = Object.entries(layer.fieldFilters).map(
          ([key, filter]) => [`fields.${key}`, filter]
        );
        const itemFilters = Object.entries(layer.fieldFilters).map(
          ([key, filter]) => [`${key}`, filter]
        );

        const filtered = fieldFilters
          .concat(itemFilters)
          .filter(
            ([key]) =>
              listContext.registry?.columns.find(
                (col) => col.filter?.propertyName === key
              ) !== undefined
          )
          .map(([key, value]) => [
            listContext.registry!.columns.find(
              (col) => col.filter?.propertyName === key
            )!.key,
            value,
          ]);

        const reconstructed = Object.fromEntries(filtered) as Record<
          string,
          IContentHubSearchFilter
        >;
        return reconstructed;
      }) ?? [{}]
    );
  }, [listContext.listState.filters]);

  const [dirtyFilterValues, setDirtyFilterValues] = useState<
    Record<string, IContentHubSearchFilter>[]
  >([{}]);
  useEffect(() => {
    if (filterValues.length > 0) {
      setDirtyFilterValues(filterValues);
    } else {
      setDirtyFilterValues([{}]);
    }
  }, [filterValues]);

  useEffect(() => {
    if (
      listContext.registry &&
      listContext.list &&
      listContext.list.id === listContext.registry.id
    ) {
      const filters: Record<
        string,
        {
          propertyName: string;
          renderer: (
            currentValue: IContentHubSearchFilter | null,
            callback: (
              key: string,
              newValue: IContentHubSearchFilter | null
            ) => void
          ) => JSX.Element;
        }
      > = {};
      for (const c of listContext.registry.columns) {
        if (c.filter) {
          filters[c.key] = {
            propertyName: c.filter.propertyName,
            renderer: (currentValue, callback) =>
              c.filter!.renderer(currentValue, callback, listContext.list!),
          };
        }
      }
      setFilterFunctions(filters);
    }
  }, [listContext.registry, listContext.list]);

  const buildView = useCallback(
    (viewId: string, displayName: string) => {
      const filter: ListViewDefinition = {
        id: viewId,
        listId: listContext.list!.id,
        siteId: listContext.list!.siteId,
        displayName: displayName,
        filters: listContext.listState.filters,
        sortingOptions: listContext.listState.sort,
      };
      return filter;
    },
    [listContext]
  );

  const [hasUpdatedFilters, setHasUpdatedFilters] = useState<boolean>(false);
  const updateFilters = useCallback(
    (filters: Record<string, IContentHubSearchFilter>[]) => {
      const newFilters = filters.map((record) => {
        const entries = Object.entries(record);
        const mappedEntries: [string, IContentHubSearchFilter][] = entries
          .filter(
            ([key]) =>
              listContext.registry?.columns.find((col) => col.key === key)
                ?.sort !== undefined
          )
          .filter(
            ([, value]) =>
              value.type === "operator" &&
              (value as BooleanOperatorSearchFilter).filters.length > 0
          )
          .map(([key, value]) => {
            const col = listContext.registry!.columns.find(
              (col) => col.key === key
            )!;
            return [col.filter!.propertyName, value];
          });
        const fieldEntries = mappedEntries.filter(([key]) =>
          key.startsWith("fields.")
        );
        const itemEntries = mappedEntries.filter(
          ([key]) => !key.startsWith("fields.")
        );

        return {
          fieldFilters: Object.fromEntries(
            fieldEntries.map(([key, value]) => [key.substring(7), value])
          ),
          fieldFiltersMode: FilterModes.All,
          itemFilters: Object.fromEntries(itemEntries),
          itemFiltersMode: FilterModes.All,
        };
      });
      listContext.dispatch({
        type: "setParameters",
        filters: newFilters,
        searchTerms: listContext.listState.searchTerms,
        sort: listContext.listState.sort,
      });
      setHasUpdatedFilters(true);
    },
    [listContext]
  );

  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false);
  const [savingDialogOpen, setSavingDialogOpen] = useState<boolean>(false);
  const { teamsUserCredential } = useContext(TeamsFxContext);

  const triggerSave = useCallback(
    (view: ListViewDefinition) => {
      setSavingDialogOpen(true);
      (async () => {
        await ContentHubService.createListView(
          view.siteId,
          view.listId,
          view,
          teamsUserCredential!
        );
        contentHubContext.reload();
        setSavingDialogOpen(false);
      })();
    },
    [teamsUserCredential]
  );

  useEffect(() => {
    if (hasUpdatedFilters) {
      listContext.dispatchSearch();
      setHasUpdatedFilters(false);
    }
  }, [hasUpdatedFilters]);

  const [searchInputValue, setSearchInputValue] = useState<string>("");
  useEffect(() => {
    if (searchInputValue !== listContext.listState.searchTerms) {
      searchCallback(searchInputValue);
    }
  }, [searchInputValue]);

  useEffect(() => {
    if (searchInputValue !== listContext.listState.searchTerms) {
      setSearchInputValue(listContext.listState.searchTerms ?? "");
    }
  }, [listContext.listState.searchTerms]);

  return (
    <div className="searchInputDiv">
      <Field label="Search">
        <Input
          disabled={disabled}
          value={searchInputValue}
          onChange={(event) => {
            setSearchInputValue(event.target.value);
            //
          }}
        />
      </Field>

      <Menu positioning="below-end">
        <MenuTrigger disableButtonEnhancement>
          {(triggerProps: MenuButtonProps) => (
            <SplitButton
              disabled={disabled}
              menuButton={triggerProps}
              icon={<FilterRegular />}
              primaryActionButton={{
                onClick: () => setFilterEditorIsOpen(true),
              }}
            >
              Filters
              {(listContext.listState.filters?.reduce(
                (previous, current) =>
                  previous +
                  Object.entries(current.fieldFilters).length +
                  Object.entries(current.itemFilters).length,
                0
              ) ?? 0) > 0 && (
                <CounterBadge
                  style={{ marginLeft: 5 }}
                  count={
                    listContext.listState.filters?.reduce(
                      (previous, current) =>
                        previous +
                        Object.entries(current.fieldFilters).length +
                        Object.entries(current.itemFilters).length,
                      0
                    ) ?? 0
                  }
                />
              )}
            </SplitButton>
          )}
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem
              disabled={disabled === true || enableViewSave === false}
              icon={<SaveEditRegular />}
              onClick={saveCallback}
            >
              Save view
            </MenuItem>
            <MenuDivider />
            <MenuItem
              disabled={disabled === true || enableViewSaveAs === false}
              icon={<SaveRegular />}
              onClick={saveAsCallback}
            >
              Save as new view
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>

      <OverlayDrawer
        as="aside"
        position="end"
        size="medium"
        modalType="modal"
        open={filterEditorIsOpen}
        onOpenChange={(_, { open }) => setFilterEditorIsOpen(open)}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setFilterEditorIsOpen(false)}
              />
            }
          >
            Filters
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody
          style={{ display: "flex", flexDirection: "column", gap: 10 }}
        >
          <ContentHubMultilayerFilterEditor
            registry={listContext.registry!}
            filterControls={filterFunctions}
            layers={dirtyFilterValues}
            filterChanged={(layer, column, filter) => {
              console.log({ layer, column, filter });
              setDirtyFilterValues((v) => {
                const copy = v;
                let layerCopy = copy[layer]!;
                if (filter) {
                  layerCopy[column] = filter;
                } else {
                  layerCopy = Object.fromEntries(
                    Object.entries(layerCopy).filter(([key]) => key !== column)
                  );
                }
                copy[layer] = layerCopy;
                return [...copy];
              });
            }}
          />
        </DrawerBody>
        <DrawerFooter>
          <Button
            appearance="primary"
            onClick={() => {
              setFilterEditorIsOpen(false);
              updateFilters(dirtyFilterValues);
            }}
          >
            Apply
          </Button>
        </DrawerFooter>
      </OverlayDrawer>
      <Dialog
        modalType="alert"
        open={saveDialogOpen}
        onOpenChange={(_e, d) => setSaveDialogOpen(d.open)}
      >
        <DialogSurface>
          <form
            onSubmit={(ev: React.FormEvent<HTMLFormElement>) => {
              ev.preventDefault();
              setSaveDialogOpen(false);
              const viewNameElement = ev.currentTarget.elements.namedItem(
                "view-name"
              ) as HTMLInputElement;
              const view = buildView("", viewNameElement.value);
              triggerSave(view);
            }}
          >
            <DialogBody>
              <DialogTitle>Save view as...</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Label required htmlFor={"view-name"}>
                  View name
                </Label>
                <Input required type="text" id={"view-name"} />
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary">Cancel</Button>
                </DialogTrigger>
                <Button type="submit" appearance="primary">
                  Save
                </Button>
              </DialogActions>
            </DialogBody>
          </form>
        </DialogSurface>
      </Dialog>
      <Dialog
        modalType="alert"
        open={savingDialogOpen}
        onOpenChange={(_e, d) => setSaveDialogOpen(d.open)}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>
              <Spinner label={"Saving..."} />
            </DialogTitle>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};
