import { Counterparty } from "../../Types/General/Counterparty";
import ApplicationConfiguration from "../../ApplicationConfiguration";
import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";

const apiBaseUrl = ApplicationConfiguration.apiEndpoint + "/api/";

async function getToken(credential: TeamsUserCredential) {
  return (await credential.getToken(""))!.token;
}

function getApiClient(credential: TeamsUserCredential) {
  const apiClient = createApiClient(
    apiBaseUrl,
    new BearerTokenAuthProvider(() => getToken(credential))
  );
  return apiClient;
}

export async function getCounterparties(
  teamsUserCredential: TeamsUserCredential
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.get("getCounterparties");
    return response.data as Counterparty[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}
