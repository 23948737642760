import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
  Spinner,
  ToolbarButton,
} from "@fluentui/react-components";
import {
  ChevronRightFilled,
  DeleteRegular,
  HistoryRegular,
  MoreHorizontal24Filled,
} from "@fluentui/react-icons";
import {
  ActionType,
  EditingMode,
  PagingPosition,
  SortDirection,
  SortingMode,
  Table,
  useTable,
} from "ka-table";
import { updatePageIndex, updatePageSize } from "ka-table/actionCreators";
import { Column } from "ka-table/models";
import { ICellTextProps, IPagingProps } from "ka-table/props";
import { kaPropsUtils } from "ka-table/utils";
import {
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ContentHubListContext } from "../../../Context/ContentHubListContext";
import {
  ContentHubItemElement,
  ItemChangeSet,
} from "../../../Types/ContentHub/ContentHubItemElement";
import {
  PropertySort,
  PropertyType,
  SortingDirection,
} from "../../../Types/ContentHub/PropertySort";
import { ConfirmationDialog } from "../../Common/ConfirmationDialog";
import { TeamsFxContext } from "../../../Context/TeamsFxContext";
import { ContentHubItemHistory } from "../ContentHubItemHistory/ContentHubItemHistory";
import "./CustomTheme.scss";

export function ContentHubTable<T = unknown>({
  onItemClicked,
  canDeleteItems,
}: {
  onItemClicked?: (item: ContentHubItemElement<T>) => void;
  canDeleteItems: boolean;
}) {
  const { themeString } = useContext(TeamsFxContext);
  const [pageSize, setPageSize] = useState<number>(15);
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);

  const listContext = useContext(ContentHubListContext);

  useEffect(() => {
    if (listContext.isWorking) {
      table.showLoading("Loading");
    } else {
      table.hideLoading();
    }
  }, [listContext.isWorking]);

  useEffect(() => {
    if (listContext.registry) {
      const defaultSort = listContext.registry.columns
        .filter((c) => c.sort?.defaultSortIndex !== undefined)
        .sort((a, b) => {
          return Math.sign(
            a!.sort!.defaultSortIndex! - b!.sort!.defaultSortIndex!
          );
        })
        .map((col) => {
          const registryCol = listContext.registry?.columns.find(
            (c) => col.key === c.key
          );
          return {
            direction: registryCol?.sort?.defaultSortingDirection,
            propertyName: registryCol?.sort?.propertyName,
            propertyType:
              registryCol?.sort?.propertyName.startsWith("fields.") === true
                ? PropertyType.Field
                : PropertyType.Item,
          } as PropertySort;
        });
      listContext.dispatch({
        type: "setParameters",
        filters: listContext.listState.filters,
        searchTerms: listContext.listState.searchTerms,
        sort: defaultSort,
      });
    }
  }, [listContext.registry]);

  useEffect(() => {
    if (listContext.registry && listContext.listState) {
      const tableCols = listContext.registry.columns
        .map((c) => {
          const index = c.sort
            ? listContext.listState.sort?.findIndex(
                (s) => s.propertyName === c.sort!.propertyName
              ) ?? -1
            : -1;
          return {
            key: c.key,
            title: c.displayName,
            visible: c.visible ?? true,
            isSortable: c.sort ? true : false,
            isResizable: true,
            sortIndex: c.sort && index !== -1 ? index + 1 : undefined,
            colGroup: { style: c.style },
            sortDirection:
              c.sort && index !== -1
                ? listContext.listState.sort![index].direction ===
                  SortingDirection.Ascending
                  ? SortDirection.Ascend
                  : SortDirection.Descend
                : undefined,
            isFilterable: false,
          } as Column;
        })
        .concat([
          {
            key: "menuClick",
            style: { textAlign: "center", padding: 4 },
            width: 50,
          },
        ]);
      table.changeProps({ ...table.props, columns: tableCols });
      listContext.dispatchSearch();
    }
  }, [listContext.listState.sort]);

  useEffect(() => {
    listContext.dispatchSearch();
  }, [listContext.view]);

  useEffect(() => {
    table.updateData(
      listContext.listState.items.map((i) =>
        listContext.registry!.mapper(i, listContext.list!)
      )
    );
  }, [listContext.listState.items]);

  const table = useTable({
    onDispatch: (action, tableProps) => {
      if (action["type"] === ActionType.SelectSingleRow) {
        const selected = kaPropsUtils.getSelectedData(tableProps).pop();
        if (selected !== undefined) {
          onItemClicked?.(
            listContext.listState.items.find(
              (item) => selected["id"] === item.id
            ) as ContentHubItemElement<T>
          );
        }
      } else if (action["type"] === ActionType.UpdatePageSize) {
        if (action["pageSize"]) {
          setPageSize(action["pageSize"]);
        }
      } else if (action["type"] === ActionType.UpdatePageIndex) {
        if (action["pageIndex"] !== undefined && action["pageIndex"] !== null) {
          setSelectedPageIndex(action["pageIndex"]);
        }
      } else if (action["type"] === ActionType.UpdateSortDirection) {
        const columns = tableProps.columns
          .filter((f) => f.sortDirection)
          .map((f) => ({
            key: f.key,
            direction:
              f.sortDirection === SortDirection.Ascend
                ? SortingDirection.Ascending
                : SortingDirection.Descending,
            sortIndex: f.sortIndex!,
          }))
          .sort((a, b) => {
            return Math.sign(a!.sortIndex - b!.sortIndex);
          });
        const sortProps = columns.map((col) => {
          const registryCol = listContext.registry!.columns.find(
            (c) => c.key === col.key
          )!;
          const fieldName = registryCol.sort!.propertyName.split(".")[0];
          const field = listContext.list!.fields.find(
            (f) => f.name === fieldName
          );
          return {
            direction: col.direction,
            propertyName: registryCol?.sort?.propertyName,
            propertyType:
              field !== undefined ? PropertyType.Field : PropertyType.Item,
          } as PropertySort;
        });
        listContext.dispatch({
          type: "setParameters",
          filters: listContext.listState.filters,
          searchTerms: listContext.listState.searchTerms,
          sort: sortProps,
        });
      }
    },
  });

  const [itemHistoryOpen, setItemHistoryOpen] = useState(false);
  const [itemHistoryData, setItemHistoryData] = useState<ItemChangeSet[]>([]);
  const onCloseHistory = () => {
    setItemHistoryOpen(false);
    setItemHistoryData([]);
  };

  const getItemVersion = async (itemId: string) => {
    const itemVersions = await listContext.getItemVersions(itemId);
    if (itemVersions) {
      setItemHistoryOpen(true);
      setItemHistoryData(itemVersions);
    }
  };

  //Delete Item
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [userDecision, setUserDecision] = useState<string | null>(null);
  const handleConfirmation = (decision: string) => {
    setUserDecision(decision);
    setOpenConfirmation(false);
  };

  const [itemToDelete, setItemToDelete] = useState<string | null>(null);
  useEffect(() => {
    if (itemToDelete !== null && userDecision === null) {
      setOpenConfirmation(true);
    }

    if (userDecision !== null && itemToDelete !== null) {
      if (userDecision === "Yes") {
        deleteItem(itemToDelete);
      } else {
        console.log("Item Not Deleted");
      }

      setUserDecision(null);
      setItemToDelete(null);
    }
  }, [itemToDelete, userDecision]);

  const deleteItem = async (itemId: string) => {
    listContext.dispatchDeleteItem(itemId);
  };

  const [openRowId, setOpenRowId] = useState<string | null>(null);
  const MenuForTable = ({ rowKeyValue }: ICellTextProps) => {
    const isOpen = openRowId === rowKeyValue;

    const onOpenChange: MenuProps["onOpenChange"] = (e, data) => {
      e.stopPropagation();
      setOpenRowId(data.open ? rowKeyValue : null);
    };

    return (
      <Menu open={isOpen} onOpenChange={onOpenChange}>
        <MenuTrigger>
          <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled />} />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem
              icon={<HistoryRegular />}
              onClick={() => getItemVersion(rowKeyValue)}
            >
              Item History
            </MenuItem>
            {canDeleteItems && (
              <MenuItem
                className="delete-item-button"
                icon={<DeleteRegular />}
                onClick={() => setItemToDelete(rowKeyValue)}
              >
                Delete
              </MenuItem>
            )}
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  };

  const pageButton = (
    page: number,
    text: string | number,
    props: PropsWithChildren<IPagingProps>
  ) => (
    <Button
      shape="circular"
      style={{ aspectRatio: 1, minWidth: "unset", height: "100%" }}
      size="small"
      appearance={props.pageIndex !== page ? "subtle" : "outline"}
      onClick={() => {
        props.dispatch(updatePageIndex(page));
      }}
      key={page}
    >
      {text}
    </Button>
  );

  return (
    <div className="flexfill nooverflow">
      <div
        className={
          "contenthubtable flexfill nooverflow " +
          (themeString === "dark" ? "custom-theme-dark" : "custom-theme-light")
        }
      >
        <Table
          table={table}
          columns={[]}
          data={listContext.listState.items.map((i) =>
            listContext.registry!.mapper(i, listContext.list!)
          )}
          editingMode={EditingMode.None}
          paging={{
            enabled: true,
            pageIndex: selectedPageIndex,
            pageSize: pageSize,
            pageSizes: [10, 15, 25],
            position: PagingPosition.Bottom,
          }}
          rowKeyField={"id"}
          sortingMode={SortingMode.MultipleTripleStateRemote}
          childComponents={{
            dataRow: {
              elementAttributes: () => ({
                onClick: (_event, extendedEvent) => {
                  if (extendedEvent.childProps.rowKeyField !== "menuClick") {
                    table.selectSingleRow(extendedEvent.childProps.rowKeyValue);
                  }
                },
              }),
            },
            cellText: {
              content: (props: React.PropsWithChildren<ICellTextProps>) => {
                switch (props.column.key) {
                  case "menuClick":
                    return <MenuForTable {...props} />;
                }
              },
            },
            pagingSizes: {
              content(props) {
                return (
                  <div
                    style={{
                      display: "grid",
                      gridGap: "0.5em",
                      gridAutoFlow: "column",
                      alignItems: "center",
                      gridAutoColumns: "max-content",
                    }}
                  >
                    {props.pageSizes?.map((size, index) => (
                      <Button
                        shape="circular"
                        style={{
                          aspectRatio: 1,
                          minWidth: "unset",
                          height: "100%",
                        }}
                        size="small"
                        appearance={
                          props.pageSize !== size ? "subtle" : "outline"
                        }
                        onClick={() => {
                          props.dispatch(updatePageSize(size));
                        }}
                        key={index}
                      >
                        {size}
                      </Button>
                    ))}
                  </div>
                );
              },
            },
            pagingPages: {
              content(props) {
                if (
                  props.pageIndex === undefined ||
                  props.pagesCount === undefined
                )
                  return <></>;
                const pagesCount: number = props.pagesCount;
                const pageIndex: number = props.pageIndex;
                let previousPage: ReactNode | undefined = undefined;
                let nextPage: ReactNode | undefined = undefined;
                const firstPage = pageButton(0, 1, props);
                const lastPage =
                  pagesCount > 1
                    ? pageButton(pagesCount - 1, pagesCount, props)
                    : undefined;
                const pagination: ReactNode[] = [];
                const startPage = pageIndex - 2;
                const endPage = pageIndex + 2;
                if (startPage > 2) {
                  previousPage = pageButton(startPage - 1, "...", props);
                }
                if (endPage < pagesCount - 2) {
                  nextPage = pageButton(endPage + 1, "...", props);
                }
                for (
                  let i = Math.max(1, startPage);
                  i <= Math.min(endPage, pagesCount - 2);
                  i++
                ) {
                  const btn = pageButton(i, i + 1, props);
                  pagination.push(btn);
                }
                return (
                  <div
                    style={{
                      display: "grid",
                      gridGap: "0.5em",
                      gridAutoFlow: "column",
                      alignItems: "center",
                      gridAutoColumns: "max-content",
                    }}
                  >
                    {firstPage}
                    {previousPage}
                    {pagination}
                    {nextPage}
                    {lastPage}
                    {listContext.listState.continuationString !== undefined && (
                      <Button
                        shape="circular"
                        style={{
                          minWidth: "9em",
                          height: "100%",
                        }}
                        size="small"
                        icon={
                          listContext.isWorking ? (
                            <Spinner size="tiny" />
                          ) : (
                            <ChevronRightFilled />
                          )
                        }
                        onClick={() => {
                          listContext.dispatchLoadItems();
                          if (
                            listContext.listState.items.length % pageSize ===
                            0
                          ) {
                            setSelectedPageIndex((i) => i + 1);
                          }
                        }}
                        disabled={listContext.isWorking}
                      >
                        {listContext.isWorking ? "Loading..." : "Load more"}
                      </Button>
                    )}
                  </div>
                );
              },
            },
          }}
          noData={{
            text: "No Data Found",
          }}
          loading={{
            enabled: listContext.isWorking,
            text: "Loading items...",
          }}
        />
      </div>

      <div>
        <ContentHubItemHistory
          listDefinition={listContext.list!}
          itemChanges={itemHistoryData}
          historyOpen={itemHistoryOpen}
          onClosedDrawer={onCloseHistory}
        />
      </div>
      <div>
        <ConfirmationDialog
          title="Delete Item"
          message="Are you sure you want to delete the item?"
          openDialog={openConfirmation}
          actions={["Yes"]}
          choiceAction={(result) => handleConfirmation(result)}
        />
      </div>
    </div>
  );
}
