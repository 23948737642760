import { AdapterInfo } from "./AdapterInfo";
import { ApprovalAction } from "./ApprovalAction";
import { ApprovalLink } from "./ApprovalLink";
import { ApprovalReminder } from "./ApprovalReminder";
import { ApprovalStatus } from "./ApprovalStatus";
import { UserInformation } from "../UserInformation";

export type ApprovalRequest = {
  id: string;
  createdDate: Date;
  modifiedDate?: Date;
  status: ApprovalStatus;
  comment?: string;
  title: string;
  requestor: UserInformation;
  approvers: UserInformation[];
  updates?: ApprovalAction[];
  links?: ApprovalLink[];
  parameters: object;
  reminder?: ApprovalReminder;
  messageTemplate?: string;
  metadata: object;
  correlationKey?: string;
  sourceWorkflow: string;
  adapter: AdapterInfo;
  schemaVersion: string;
};

export function ApprovalStatusToString(status: ApprovalStatus): string {
  if (typeof status === "string") {
    if (status == "RevisionRequired") return "Revision Required";
    return status;
  }

  if (status == ApprovalStatus.RevisionRequired) return "Revision Required";
  return ApprovalStatus[status];
}
export function ApprovalStatusToInt(status: ApprovalStatus): number {
  return status;
}


