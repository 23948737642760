import { Field } from "@fluentui/react-components";
import { Control, FieldValues, useController, Path } from "react-hook-form";
import { getSelectStyle, getSelectTheme } from "../CommonSelect/CommonSelect";
import { useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "../../../Context/TeamsFxContext";
import { DropDownData } from "../../../Types/General/DropDownData";
import Select from "react-select";

export const SelectDropDownData = <T extends FieldValues>({
  label,
  options,
  control,
  name,
  required,
  disabled,
}: {
  label: string;
  options: DropDownData[];
  control: Control<T>;
  name: Path<T>;
  required?: boolean;
  disabled?: boolean;
}) => {
  const [requiredField, setRequiredField] = useState(required);
  useEffect(() => {
    setRequiredField(required);
  }, [required]);
  const { themeString } = useContext(TeamsFxContext);
  const { field } = useController({
    control,
    name,
  });

  return (
    <Field
      label={label}
      required={requiredField !== undefined ? requiredField : false}
    >
      <Select<DropDownData>
        {...field}
        styles={getSelectStyle(themeString)}
        theme={getSelectTheme(themeString)}
        options={options}
        getOptionValue={(option) => `${option.value}`}
        getOptionLabel={(option) => `${option.label}`}
        value={options.find((c) => c.value === field.value)}
        isSearchable={true}
        isClearable={true}
        isDisabled={disabled}
      />
    </Field>
  );
};
