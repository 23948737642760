import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ContentHubListReducerAction,
  ContentHubListState,
  DefaultContentHubListState,
  useContentHubListReducer,
} from "../Hooks/useContentHubListReducer";
import { useContentHubRegistry } from "../Hooks/useContentHubRegistry";
import * as ContentHubService from "../Services/ContentHub/ContentHubService";
import { ContentHubInsertItemRequest } from "../Types/ContentHub/ContentHubInsertItemRequest";
import { ItemChangeSet } from "../Types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../Types/ContentHub/ContentHubListsElement";
import { ContentHubUpdateItemRequest } from "../Types/ContentHub/ContentHubUpdateItemRequest";
import { ListViewDefinition } from "../Types/ContentHub/ListViewDefinition";
import { ContentHubListRegistry } from "../Types/ContentHub/Registry/ContentHubListRegistry";
import { generateSearchRequest } from "../Utils/Utils";
import { ContentHubContext } from "./ContentHubContext";
import { TeamsFxContext } from "./TeamsFxContext";
type ContentHubListContextType = {
  listState: ContentHubListState;
  list?: ContentHubListsElement;
  view?: ListViewDefinition;
  registry?: ContentHubListRegistry;
  isWorking: boolean;
  dispatch: Dispatch<ContentHubListReducerAction>;
  dispatchUpdateItem: (
    itemId: string,
    fields: Record<string, unknown>,
    files?: File[]
  ) => void;
  dispatchCreateItem: (fields: Record<string, unknown>, files?: File[]) => void;
  dispatchDeleteItem: (itemId: string) => void;
  dispatchClearItems: () => void;
  dispatchSearch: () => void;
  dispatchLoadItems: () => void;
  getItemVersions: (itemId: string) => Promise<ItemChangeSet[] | undefined>;
};
export const ContentHubListContext = createContext<ContentHubListContextType>({
  listState: DefaultContentHubListState,
  isWorking: true,
  dispatch: () => {},
  dispatchUpdateItem: async () => undefined,
  dispatchCreateItem: async () => undefined,
  dispatchDeleteItem: async () => {},
  dispatchClearItems: () => {},
  dispatchLoadItems: () => {},
  dispatchSearch: () => {},
  getItemVersions: async () => undefined,
});

export const ContentHubListContextProvider = ({
  children,
  siteId,
  listId,
  viewId,
}: {
  children?: ReactNode;
  siteId: string;
  listId: string;
  viewId?: string;
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const registry = useContentHubRegistry();
  const contentHubContext = useContext(ContentHubContext);
  const [listState, dispatchListState] = useContentHubListReducer();

  const [listDefinition, setListDefinition] =
    useState<ContentHubListsElement>();

  const [viewDefinition, setViewDefinition] = useState<ListViewDefinition>();
  useEffect(() => {
    if (listState.initialized) {
      setViewDefinition(
        contentHubContext.views[listState.listId!].find(
          (v) => v.id === listState.viewId
        )
      );
    }
  }, [listState.initialized, listState.viewId]);

  const [listRegistry, setListRegistry] = useState<ContentHubListRegistry>();
  useEffect(() => {
    setListRegistry(
      registry.getListRegistry(listState.siteId, listState.listId)
    );
  }, [listState.siteId, listState.listId]);

  const [isWorking, setIsWorking] = useState<boolean>(false);
  useEffect(() => {
    setIsWorking(
      contentHubContext.isLoading ||
        listState.activity !== undefined ||
        registry.isLoading
    );
  }, [contentHubContext, listState, registry]);

  useEffect(() => {
    if (contentHubContext.error) {
      dispatchListState({
        type: "setErrors",
        errors: [
          ...listState.errors,
          { error: contentHubContext.error.message },
        ],
      });
    }
  }, [contentHubContext.error]);

  //#region Hooks
  useEffect(() => {
    dispatchListState({
      type: "startActivity",
      activity: "initializing",
      arguments: { siteId: siteId, listId: listId },
    });
  }, [siteId, listId]);

  useEffect(() => {
    if (listState.initialized) {
      dispatchListState({ type: "setView", viewId: viewId });
    }
  }, [viewId]);

  useEffect(() => {
    if (listState.initialized && viewDefinition?.id === listState.viewId) {
      dispatchListState({
        type: "setParameters",
        sort: viewDefinition?.sortingOptions,
        filters: [
          ...(viewDefinition?.filters?.map((f) => ({
            ...f,
            viewFilter: true,
          })) ?? []),
          ...(listState.filters?.filter((f) => f.viewFilter !== true) ?? []),
        ],
        searchTerms: listState.searchTerms,
      });
    }
  }, [listState.viewId, viewDefinition]);

  //#endregion

  //#region Callbacks
  const dispatchLoadItems = useCallback(async () => {
    if (!isWorking && listDefinition && teamsUserCredential) {
      dispatchListState({
        type: "startActivity",
        activity: "addingItems",
      });
    }
  }, [isWorking, listDefinition, teamsUserCredential, dispatchListState]);

  useEffect(() => {
    if (teamsUserCredential === undefined) {
      return;
    }
    if (listState.activity === "initializing") {
      setListDefinition(
        contentHubContext.lists[listState.activityArguments.siteId].find(
          (l) => l.id === listState.activityArguments.listId
        )
      );
      dispatchListState({
        type: "initialized",
      });
      return;
    } else if (
      listDefinition === undefined ||
      listDefinition?.id !== listState.listId
    ) {
      return;
    }
    switch (listState.activity) {
      case "settingItems":
        (async () => {
          try {
            const items = await ContentHubService.getItems(
              listState.siteId!,
              listState.listId!,
              generateSearchRequest(
                listDefinition,
                listState.searchTerms ?? null,
                listState.filters,
                listState.sort
              ),
              undefined,
              undefined,
              teamsUserCredential
            );
            dispatchListState({
              type: "setItems",
              items: items.items,
              continuationString: items.continuationString ?? undefined,
            });
          } catch (error) {
            console.log(error);
            dispatchListState({
              type: "setErrors",
              errors: [
                {
                  activity: listState.activity,
                  error: "An error has occurred while performing the search.",
                },
              ],
            });
          }
        })();
        break;
      case "addingItems":
        (async () => {
          try {
            const items = await ContentHubService.getItems(
              listState.siteId!,
              listState.listId!,
              generateSearchRequest(
                listDefinition,
                listState.searchTerms ?? null,
                listState.filters,
                listState.sort
              ),
              {
                ContinuationString: listState.continuationString,
              },
              undefined,
              teamsUserCredential
            );
            dispatchListState({
              type: "addItems",
              items: items.items,
              continuationString: items.continuationString ?? undefined,
            });
          } catch (error) {
            console.log(error);
            dispatchListState({
              type: "setErrors",
              errors: [
                {
                  activity: listState.activity,
                  error: "An error has occurred while performing the search.",
                },
              ],
            });
          }
        })();
        break;
      case "creatingItem":
        (async () => {
          try {
            const request: ContentHubInsertItemRequest = {
              fields: listState.activityArguments.fields,
            };

            const attachmentsField = listDefinition.fields.find(
              (f) => f.name === "_attachments"
            );
            let driveId: string | undefined = undefined;
            let folderId: string | undefined = undefined;
            if (attachmentsField) {
              const fId = attachmentsField.folderId!;
              [driveId, folderId] = fId.split("/");
            }

            const createResponse = await ContentHubService.createItem(
              listState.siteId!,
              listState.listId!,
              request,
              teamsUserCredential!,
              driveId,
              folderId,
              listState.activityArguments.files
            );

            dispatchListState({
              type: "addItems",
              items: [createResponse],
              continuationString: listState.continuationString,
              prepend: true,
            });
          } catch (error) {
            console.log(error);
            dispatchListState({
              type: "setErrors",
              errors: [
                {
                  activity: listState.activity,
                  error: "An error has occurred while creating the item.",
                },
              ],
            });
          }
        })();
        break;
      case "updatingItem":
        (async () => {
          try {
            const request: ContentHubUpdateItemRequest = {
              fields: listState.activityArguments.fields,
              ignoreLock: false,
            };
            const attachmentsField = listDefinition.fields.find(
              (f) => f.name === "_attachments"
            );
            let driveId: string | undefined = undefined;
            let folderId: string | undefined = undefined;
            if (attachmentsField) {
              const fId = attachmentsField.folderId!;
              [driveId, folderId] = fId.split("/");
            }
            const updated = await ContentHubService.updateItems(
              listState.siteId!,
              listState.listId!,
              listState.activityArguments.itemId,
              request,
              teamsUserCredential,
              driveId,
              folderId,
              listState.activityArguments.files
            );
            dispatchListState({
              type: "updateItem",
              item: updated,
            });
          } catch (error) {
            console.log(error);
            dispatchListState({
              type: "setErrors",
              errors: [
                {
                  activity: listState.activity,
                  error: "An error has occurred while updating the item.",
                },
              ],
            });
          }
        })();
        break;
      case "deletingItem":
        (async () => {
          try {
            await ContentHubService.deleteItem(
              listState.siteId!,
              listState.listId!,
              listState.activityArguments.itemId,
              teamsUserCredential
            );
            dispatchListState({
              type: "deleteItem",
              itemId: listState.activityArguments.itemId,
            });
          } catch (error) {
            console.log(error);
            dispatchListState({
              type: "setErrors",
              errors: [
                {
                  activity: listState.activity,
                  error: "An error has occurred while deleting the item.",
                },
              ],
            });
          }
        })();
        break;
    }
  }, [listState.activity, listDefinition, teamsUserCredential]);

  const dispatchCreateItem = useCallback(
    async (fields: Record<string, unknown>, files?: File[]) => {
      if (
        listState.activity === undefined &&
        listDefinition &&
        teamsUserCredential
      ) {
        dispatchListState({
          type: "startActivity",
          activity: "creatingItem",
          arguments: {
            fields,
            files,
          },
        });
      }
    },
    [listState, listDefinition, teamsUserCredential, dispatchListState]
  );
  const dispatchUpdateItem = useCallback(
    async (itemId: string, fields: Record<string, unknown>, files?: File[]) => {
      if (
        listState.activity === undefined &&
        listDefinition &&
        teamsUserCredential
      ) {
        dispatchListState({
          type: "startActivity",
          activity: "updatingItem",
          arguments: {
            itemId,
            fields,
            files,
          },
        });
      }
    },
    [listState, listDefinition, teamsUserCredential, dispatchListState]
  );

  const dispatchDeleteItem = useCallback(
    async (itemId: string) => {
      if (
        listState.activity === undefined &&
        listDefinition &&
        teamsUserCredential
      ) {
        dispatchListState({
          type: "startActivity",
          activity: "deletingItem",
          arguments: {
            itemId,
          },
        });
      }
    },
    [listState, listDefinition, teamsUserCredential, dispatchListState]
  );

  const dispatchClearItems = useCallback(() => {
    if (
      listState.activity === undefined &&
      listDefinition &&
      teamsUserCredential
    ) {
      dispatchListState({ type: "clearItems" });
    }
  }, [listState, listDefinition, teamsUserCredential, dispatchListState]);

  const dispatchSearch = useCallback(async () => {
    if (
      listState.activity === undefined &&
      listDefinition &&
      teamsUserCredential
    ) {
      dispatchListState({ type: "startActivity", activity: "settingItems" });
    }
  }, [listState, listDefinition, teamsUserCredential, dispatchListState]);

  const getItemVersions = useCallback(
    async (itemId: string) => {
      if (
        listState.activity === undefined &&
        listDefinition &&
        teamsUserCredential
      ) {
        const data = (await ContentHubService.getItemVersions(
          listState.siteId!,
          listState.listId!,
          itemId,
          teamsUserCredential!
        )) as ItemChangeSet[];
        return data;
      }
    },
    [listState, listDefinition, teamsUserCredential, dispatchListState]
  );

  const [contextValue, setContextValue] = useState<ContentHubListContextType>({
    list: listDefinition,
    view: viewDefinition,
    registry: listRegistry,
    isWorking: isWorking,
    listState: listState,
    dispatch: dispatchListState,
    dispatchUpdateItem: dispatchUpdateItem,
    dispatchCreateItem: dispatchCreateItem,
    dispatchDeleteItem: dispatchDeleteItem,
    dispatchClearItems: dispatchClearItems,
    dispatchLoadItems: dispatchLoadItems,
    dispatchSearch: dispatchSearch,
    getItemVersions: getItemVersions,
  });

  useEffect(() => {
    setContextValue({
      list: listDefinition,
      view: viewDefinition,
      registry: listRegistry,
      isWorking: isWorking,
      listState: listState,
      dispatch: dispatchListState,
      dispatchUpdateItem: dispatchUpdateItem,
      dispatchCreateItem: dispatchCreateItem,
      dispatchDeleteItem: dispatchDeleteItem,
      dispatchClearItems: dispatchClearItems,
      dispatchLoadItems: dispatchLoadItems,
      dispatchSearch: dispatchSearch,
      getItemVersions: getItemVersions,
    });
  }, [
    listDefinition,
    viewDefinition,
    listRegistry,
    isWorking,
    listState,
    dispatchListState,
    dispatchUpdateItem,
    dispatchCreateItem,
    dispatchDeleteItem,
    dispatchClearItems,
    dispatchLoadItems,
    dispatchSearch,
    getItemVersions,
  ]);

  //#endregion

  return (
    <ContentHubListContext.Provider value={contextValue}>
      {children}
    </ContentHubListContext.Provider>
  );
};
