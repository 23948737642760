import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Body1Stronger,
} from "@fluentui/react-components";
import { useCallback } from "react";
import { IContentHubSearchFilter } from "../../../Types/ContentHub/ContentHubSearchFilter";
import { ContentHubListRegistry } from "../../../Types/ContentHub/Registry/ContentHubListRegistry";

export const ContentHubFilterLayerEditor = ({
  filterControls,
  filters,
  registry,
  filterChanged,
}: {
  filterControls: Record<
    string,
    {
      propertyName: string;
      renderer: (
        currentValue: IContentHubSearchFilter | null,
        callback: (
          key: string,
          newValue: IContentHubSearchFilter | null
        ) => void
      ) => JSX.Element;
    }
  >;
  filters: Record<string, IContentHubSearchFilter | null>;
  registry: ContentHubListRegistry;
  filterChanged?: (
    columnKey: string,
    filter: IContentHubSearchFilter | null
  ) => void;
}) => {
  const onFilterChanged = useCallback(
    (columnKey: string, filter: IContentHubSearchFilter | null) => {
      filterChanged?.(columnKey, filter);
    },
    [filterChanged]
  );
  return (
    <Accordion multiple collapsible>
      {Object.keys(filterControls).map((k, i) => (
        <AccordionItem value={i} key={i}>
          <AccordionHeader>
            {filters[k] ? (
              <Body1Stronger>
                {registry.columns.find((col) => col.key === k)?.displayName}
              </Body1Stronger>
            ) : (
              <>{registry.columns.find((col) => col.key === k)?.displayName}</>
            )}
          </AccordionHeader>
          <AccordionPanel>
            {filterControls[k]?.renderer(filters[k] ?? null, (key, newValue) =>
              onFilterChanged(key, newValue)
            )}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
