import { ApprovalStatus } from "../Types/Approvals/ApprovalStatus";
import { ContentHubListField, ContentHubListsElement } from "../Types/ContentHub/ContentHubListsElement";
import { ComparableTypeSearchFilter, ComparableTypeSearchTypes, IContentHubSearchFilter, PersonArraySearchFilter, PersonSearchFields, PersonSearchFilter, StringSearchFilter, StringSearchTypes } from "../Types/ContentHub/ContentHubSearchFilter";
import { PropertySort, PropertyType, SortingDirection } from "../Types/ContentHub/PropertySort";
import {
  AdvancedSearchItemsRequest,
  FilterModes,
  SearchItemsRequestFilter,
} from "../Types/ContentHub/SearchItemsRequest";

const formatDate = (date: Date) => {
  const d = new Date(date);
  const day = (d.getMonth() + 1).toString().padStart(2, "0");
  const month = d.getDate().toString().padStart(2, "0");
  const year = d.getFullYear();
  const hourModulo = d.getHours() % 12 === 0 ? 12 : d.getHours() % 12;
  const hour = hourModulo.toString().padStart(2, "0");
  const period = d.getHours() >= 12 ? "PM" : "AM";
  const minutes = d.getMinutes().toString().padStart(2, "0");
  return `${day}/${month}/${year} ${hour}:${minutes} ${period}`;
};

const formatCurrency = (value: number | bigint) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

const getStatusBadgeColor = (status: ApprovalStatus) => {
  switch (status) {
    case ApprovalStatus.Pending:
      return "warning";
    case ApprovalStatus.Approved:
      return "success";
    case ApprovalStatus.Denied:
      return "danger";
    case ApprovalStatus.Cancelled:
      return "brand";
    case ApprovalStatus.RevisionRequired:
      return "important";
  }
  return "subtle";
};

const generateSearchRequest = (
  listDefinition: ContentHubListsElement,
  query: string | null,
  filters: SearchItemsRequestFilter[] = [],
  sortingOptions: PropertySort[] = []
): AdvancedSearchItemsRequest => {
  const searchableFields = listDefinition.fields.filter((f) => f.isSearchable);
  const searchFilters: Record<string, IContentHubSearchFilter> = {};
  if (query) {
    for (const field of searchableFields) {
      let filter: IContentHubSearchFilter | null = null;
      switch (field.type) {
        case "string":
        case "choice":
        case "string[]":
          filter = new StringSearchFilter(
            field.type,
            query,
            StringSearchTypes.Contains
          );
          break;
        case "person":
          filter = new PersonSearchFilter(
            query,
            StringSearchTypes.Contains,
            PersonSearchFields.Name |
            PersonSearchFields.Email |
            PersonSearchFields.UserPrincipalName
          );
          break;
        case "person[]":
          filter = new PersonArraySearchFilter(
            query,
            StringSearchTypes.Contains,
            PersonSearchFields.Name |
            PersonSearchFields.Email |
            PersonSearchFields.UserPrincipalName
          );
          break;
        case "double": {
          const parsed = parseFloat(query);
          if (!isNaN(parsed)) {
            filter = new ComparableTypeSearchFilter(
              field.type,
              parsed,
              ComparableTypeSearchTypes.IsExactly
            );
          }
          break;
        }
        case "int": {
          const parsed = parseInt(query);
          if (!isNaN(parsed) && Number.isInteger(parsed)) {
            filter = new ComparableTypeSearchFilter(
              field.type,
              parsed,
              ComparableTypeSearchTypes.IsExactly
            );
          }
          break;
        }
      }
      if (filter == null) continue;
      searchFilters[field.name] = filter!;
    }
  }
  const sorting = sortingOptions;
  if (sorting.length === 0) {
    sorting.push({
      direction: SortingDirection.Descending,
      propertyType: PropertyType.Item,
      propertyName: "createdDate",
    });
  }
  return {
    filters: [
      {
        fieldFiltersMode: FilterModes.Any,
        fieldFilters: searchFilters,
        itemFilters: {},
        itemFiltersMode: FilterModes.All,
      },
      ...filters,
    ],
    sortingOptions: sorting,
  };
};
const getChoiceFieldOptions = (field: ContentHubListField) => {
  if (field.type !== "choice") return [];

  return field.options!.map((value) => ({
    label: value,
    value: value,
  }));
};

export {
  formatCurrency, formatDate, generateSearchRequest, getChoiceFieldOptions, getStatusBadgeColor
};

