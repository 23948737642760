import {
  Button,
  Dropdown,
  Option,
  SpinButton,
} from "@fluentui/react-components";
import {
  BooleanOperatorSearchFilter,
  IContentHubSearchFilter,
  ComparableTypeSearchFilter,
  ComparableTypeSearchTypes,
} from "../../../Types/ContentHub/ContentHubSearchFilter";
import {
  AddFilled,
  BackspaceRegular,
  BranchFilled,
  FilterAddRegular,
  DeleteRegular,
} from "@fluentui/react-icons";
import { useCallback, useEffect, useState } from "react";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { TimePicker } from "@fluentui/react-timepicker-compat";
import { formatDate } from "../../../Utils/Utils";

export const ComparableTypeFilterEditor = ({
  operator,
  removable = false,
  valueType,
  initialValue,
  valueChanged,
}: {
  operator?: "And" | "Or";
  removable?: boolean;
  valueType: string;
  initialValue?: IContentHubSearchFilter | null;
  valueChanged?: (filterVal: IContentHubSearchFilter | null) => void;
}) => {
  const [numericField, setNumericField] = useState<number | null>(null);
  const [dateField, setDateField] = useState<Date | null>(null);
  const [dropdownVal, setDropdownVal] = useState<ComparableTypeSearchTypes>(
    ComparableTypeSearchTypes.IsExactly
  );

  const [currentFilter, setCurrentFilter] = useState<IContentHubSearchFilter>(
    initialValue ??
      ({
        type: "operator",
        operator: operator ?? "Or",
        filters: [],
      } as BooleanOperatorSearchFilter)
  );

  const addItem = useCallback(
    (f: IContentHubSearchFilter) => {
      const casted = currentFilter as BooleanOperatorSearchFilter;
      casted.filters.push(f);
      setCurrentFilter({ ...casted });
      setDateField(null);
      setNumericField(null);
    },
    [currentFilter]
  );

  const removeItem = useCallback((index: number) => {
    setCurrentFilter((f) => {
      const v = f as BooleanOperatorSearchFilter;
      v.filters.splice(index, 1);
      return { ...v };
    });
  }, []);
  useEffect(() => {
    if (currentFilter.type !== "operator") {
      setCurrentFilter(
        (cf) =>
          ({
            operator: "And",
            type: "operator",
            filters: [cf],
          } as BooleanOperatorSearchFilter)
      );
    } else {
      valueChanged?.(currentFilter);
    }
  }, [currentFilter, valueChanged]);

  const dropdownToString = (value: ComparableTypeSearchTypes) => {
    switch (value) {
      case ComparableTypeSearchTypes.IsExactly:
        return "=";
      case ComparableTypeSearchTypes.IsGreaterThan:
        return ">";
      case ComparableTypeSearchTypes.IsGreaterThanOrEqual:
        return ">=";
      case ComparableTypeSearchTypes.IsLessThan:
        return "<";
      case ComparableTypeSearchTypes.IsLessThanOrEqual:
        return "<=";
      default:
        return "";
    }
  };
  const convertOperatorToString = (op: string) => {
    switch (op) {
      case "And":
        return "Meet all conditions";
      case "Or":
        return "Meet one or more conditions";
      default:
        return "";
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--colorNeutralBackground1)",
        gap: 5,
      }}
    >
      <div style={{ gap: 5, display: "inline-flex" }}>
        {removable && (
          <Button
            size="small"
            icon={<DeleteRegular />}
            onClick={() => valueChanged?.(null)}
          />
        )}
        <Dropdown
          size="small"
          defaultValue={convertOperatorToString(
            (currentFilter as BooleanOperatorSearchFilter).operator ?? "Or"
          )}
          onOptionSelect={(_e, d) => {
            setCurrentFilter(
              (f) =>
                ({
                  ...f,
                  operator: d.optionValue,
                } as BooleanOperatorSearchFilter)
            );
          }}
        >
          <Option value="Or">{convertOperatorToString("Or")}</Option>
          <Option value="And">{convertOperatorToString("And")}</Option>
        </Dropdown>
      </div>
      <div
        style={{
          borderLeft: "solid",
          gap: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ flex: 1, paddingLeft: 10 }}>
          {currentFilter.type === "operator" &&
            (currentFilter as BooleanOperatorSearchFilter).filters.map(
              (f, i) => {
                if (f.type === "operator") {
                  const op = (f as BooleanOperatorSearchFilter).operator;

                  return (
                    <ComparableTypeFilterEditor
                      key={i}
                      initialValue={f}
                      operator={op}
                      removable={true}
                      valueType={valueType}
                      valueChanged={(newVal) => {
                        if (newVal) {
                          setCurrentFilter((f) => {
                            const newV = f as BooleanOperatorSearchFilter;
                            newV.filters[i] = newVal;
                            return { ...newV };
                          });
                        } else {
                          removeItem(i);
                        }
                      }}
                    ></ComparableTypeFilterEditor>
                  );
                } else if (f.type === valueType) {
                  const filterType = (f as ComparableTypeSearchFilter)
                    .searchType;
                  let filterTypeText = "";
                  switch (filterType) {
                    case ComparableTypeSearchTypes.IsExactly:
                      filterTypeText = "= ";
                      break;
                    case ComparableTypeSearchTypes.IsGreaterThan:
                      filterTypeText = "> ";
                      break;
                    case ComparableTypeSearchTypes.IsGreaterThanOrEqual:
                      filterTypeText = ">= ";
                      break;
                    case ComparableTypeSearchTypes.IsLessThan:
                      filterTypeText = "<  ";
                      break;
                    case ComparableTypeSearchTypes.IsLessThanOrEqual:
                      filterTypeText = "<=  ";
                      break;
                  }
                  return (
                    <div key={i}>
                      <Button
                        size="small"
                        icon={<DeleteRegular />}
                        title="Remove filter"
                        onClick={() => removeItem(i)}
                      />
                      {" value "}
                      {filterTypeText}
                      {(valueType === "datetime" ||
                        valueType === "datetime[]") &&
                        formatDate(
                          (f as ComparableTypeSearchFilter).value as Date
                        )}
                      {valueType !== "datetime" &&
                        valueType !== "datetime[]" &&
                        ((f as ComparableTypeSearchFilter).value as number)}
                    </div>
                  );
                }
                return undefined;
              }
            )}
        </div>
        <div
          style={{ flex: 1, display: "inline-flex", gap: 5, marginLeft: 10 }}
        >
          <Button
            size="small"
            icon={<FilterAddRegular />}
            title="Add filter"
            onClick={() => {
              addItem({
                searchType: dropdownVal,
                value: dateField ?? numericField,
                type: valueType,
              } as ComparableTypeSearchFilter);
            }}
          />
          <Button
            size="small"
            icon={<BranchFilled />}
            title="Add condition group"
            onClick={() => {
              addItem({
                type: "operator",
                operator: "Or",
                filters: [],
              } as BooleanOperatorSearchFilter);
            }}
          />
          <Dropdown
            style={{ minWidth: "fit-content", width: 60 }}
            size="small"
            defaultValue={dropdownToString(dropdownVal)}
            onOptionSelect={(_e, d) =>
              setDropdownVal(d.optionValue as ComparableTypeSearchTypes)
            }
          >
            {Object.values(ComparableTypeSearchTypes).map((t) => (
              <Option key={t} value={t}>
                {dropdownToString(t)}
              </Option>
            ))}
          </Dropdown>
          {(valueType === "datetime" || valueType === "datetime[]") && (
            <div style={{ display: "inline-flex", flex: 1, gap: 5 }}>
              <DatePicker
                size="small"
                placeholder="Select a date..."
                value={dateField}
                onSelectDate={(d) => setDateField(d ?? null)}
                style={{ flex: 1 }}
              />
              <TimePicker
                size="small"
                style={{ minWidth: 0, flex: 1 }}
                placeholder="Select a time..."
                dateAnchor={dateField ?? undefined}
                disabled={dateField === null}
                //selectedTime={fieldText === 0 ? undefined : (fieldText as Date)}
                // value={
                //   fieldText === 0
                //     ? undefined
                //     : (fieldText as Date).toTimeString()
                // }
                onTimeChange={(_e, d) => {
                  if (d.selectedTime) {
                    setDateField(d.selectedTime);
                  }
                }}
                positioning={{ autoSize: "width" }}
              />
            </div>
          )}
          {(valueType === "int" ||
            valueType === "double" ||
            valueType === "int[]" ||
            valueType === "double[]") && (
            <SpinButton
              placeholder="Search terms"
              style={{ flex: 1 }}
              size="small"
              step={
                valueType === "int" || valueType === "int[]" ? 1 : undefined
              }
              precision={
                valueType === "int" || valueType === "int[]" ? 0 : undefined
              }
              onChange={(_e, d) => {
                if (d.value !== undefined) {
                  setNumericField(d.value ?? null);
                } else if (d.displayValue !== undefined) {
                  if (valueType === "int" || valueType === "int[]") {
                    const parsed = parseInt(d.displayValue);
                    if (!isNaN(parsed)) {
                      setNumericField(parsed);
                    }
                  } else {
                    if (valueType === "double" || valueType === "double[]") {
                      const parsed = parseFloat(d.displayValue);
                      if (!isNaN(parsed)) {
                        setNumericField(parsed);
                      }
                    }
                  }
                }
              }}
              displayValue={numericField?.toString()}
              value={numericField}
            ></SpinButton>
          )}
          <Button
            size="small"
            icon={<BackspaceRegular />}
            disabled={dateField === null && numericField === null}
            title="Clear"
            onClick={() => {
              setDateField(null);
              setNumericField(null);
            }}
          />
          <Button
            size="small"
            icon={<AddFilled />}
            disabled={dateField === null && numericField === null}
            title="Add filter"
            onClick={() => {
              addItem({
                searchType: dropdownVal,
                value: dateField ?? numericField,
                type: valueType,
              } as ComparableTypeSearchFilter);
            }}
          />
        </div>
      </div>
    </div>
  );
};
