import React from "react";
import ApplicationConfiguration from "../../ApplicationConfiguration";
/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
class TermsOfUse extends React.Component {
  render() {
    return (
      <div>
        <h1>Terms of Use</h1>
        <h1>Endpoint: {ApplicationConfiguration.apiEndpoint}</h1>
        <h1>apiName: {ApplicationConfiguration.apiName}</h1>
        <h1>clientId: {ApplicationConfiguration.clientId}</h1>
        <h1>initiateLoginEndpoint: {ApplicationConfiguration.initiateLoginEndpoint}</h1>
      </div>
    );
  }
}

export default TermsOfUse;
