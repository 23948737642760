import ApplicationConfiguration from "../../ApplicationConfiguration";
import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import { AttachmentFile } from "../../Types/SharePoint/SharePointDriveItems";

const apiBaseUrl = ApplicationConfiguration.apiEndpoint + "/api/";

async function getToken(credential: TeamsUserCredential) {
  return (await credential.getToken(""))!.token;
}

function getApiClient(credential: TeamsUserCredential) {
  const apiClient = createApiClient(
    apiBaseUrl,
    new BearerTokenAuthProvider(() => getToken(credential))
  );
  return apiClient;
}

export async function uploadFiles(
  driveId: string,
  folderId: string,
  itemId: string,
  files: File[],
  teamsUserCredential: TeamsUserCredential
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append(files[i].name, files[i]);
    }

    const response = await apiClient.post(
      `drive/${driveId}/folder/${folderId}/item/${itemId}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data as string;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getAttachments(
  driveId: string,
  folderId: string,
  teamsUserCredential: TeamsUserCredential
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);
    const response = await apiClient.get(`drive/${driveId}/folder/${folderId}`);

    return response.data as AttachmentFile[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function deleteAttachment(
  driveId: string,
  fileId: string,
  teamsUserCredential: TeamsUserCredential
) {
  try {
    const apiClient = getApiClient(teamsUserCredential);

    await apiClient.delete(`drive/${driveId}/file/${fileId}`);

    return true;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
