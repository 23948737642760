import { Button, Dropdown, Input, Option } from "@fluentui/react-components";
import {
  BooleanOperatorSearchFilter,
  IContentHubSearchFilter,
  PersonSearchFields,
  PersonSearchFilter,
  StringSearchTypes,
} from "../../../Types/ContentHub/ContentHubSearchFilter";
import {
  AddFilled,
  BackspaceRegular,
  BranchFilled,
  FilterAddRegular,
  DeleteRegular,
} from "@fluentui/react-icons";
import { useCallback, useEffect, useState } from "react";

export const PersonFilterEditor = ({
  operator,
  removable = false,
  initialValue,
  valueType,
  valueChanged,
}: {
  operator?: "And" | "Or";
  removable?: boolean;
  initialValue?: IContentHubSearchFilter | null;
  valueType: string;
  valueChanged?: (filterVal: IContentHubSearchFilter | null) => void;
}) => {
  const [fieldText, setFieldText] = useState<string>("");
  const [selectedFields, setSelectedFields] = useState<number[]>([
    PersonSearchFields.Name,
  ]);
  const [dropdownVal, setDropdownVal] = useState<StringSearchTypes>(
    StringSearchTypes.IsExactly
  );

  const [currentFilter, setCurrentFilter] = useState<IContentHubSearchFilter>(
    initialValue ??
      ({
        type: "operator",
        operator: operator ?? "Or",
        filters: [],
      } as BooleanOperatorSearchFilter)
  );
  const addItem = useCallback(
    (f: IContentHubSearchFilter) => {
      const casted = currentFilter as BooleanOperatorSearchFilter;
      casted.filters.push(f);
      setCurrentFilter({ ...casted });
      setFieldText("");
    },
    [currentFilter, valueChanged]
  );

  const removeItem = useCallback((index: number) => {
    setCurrentFilter((f) => {
      const v = f as BooleanOperatorSearchFilter;
      v.filters.splice(index, 1);
      return { ...v };
    });
  }, []);

  useEffect(() => {
    if (currentFilter.type !== "operator") {
      setCurrentFilter(
        (cf) =>
          ({
            operator: "And",
            type: "operator",
            filters: [cf],
          } as BooleanOperatorSearchFilter)
      );
    } else {
      valueChanged?.(currentFilter);
    }
  }, [currentFilter]);

  const convertOperatorToString = (op: string) => {
    switch (op) {
      case "And":
        return "Meet all conditions";
      case "Or":
        return "Meet one or more conditions";
      default:
        return "";
    }
  };

  const convertFieldName = (field: PersonSearchFields) => {
    switch (field) {
      case PersonSearchFields.Department:
        return "Department";

      case PersonSearchFields.Name:
        return "Name";

      case PersonSearchFields.UserPrincipalName:
        return "User Principal Name";

      case PersonSearchFields.JobTitle:
        return "Job Title";

      case PersonSearchFields.Email:
        return "Email";

      case PersonSearchFields.Id:
        return "Id";

      default:
        return "";
    }
  };

  const dropdownToString = (value: StringSearchTypes) => {
    switch (value) {
      case StringSearchTypes.IsExactly:
        return "Is exactly";
      case StringSearchTypes.Contains:
        return "Contains";
      case StringSearchTypes.StartsWith:
        return "Starts With";
      case StringSearchTypes.EndsWith:
        return "Ends With";
      default:
        return "";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--colorNeutralBackground1)",
        gap: 5,
      }}
    >
      <div style={{ gap: 5, display: "inline-flex" }}>
        {removable && (
          <Button
            size="small"
            icon={<DeleteRegular />}
            title="Remove filter"
            onClick={() => valueChanged?.(null)}
          />
        )}
        <Dropdown
          size="small"
          defaultValue={convertOperatorToString(
            (currentFilter as BooleanOperatorSearchFilter).operator ?? "Or"
          )}
          onOptionSelect={(_e, d) => {
            setCurrentFilter(
              (f) =>
                ({
                  ...f,
                  operator: d.optionValue,
                } as BooleanOperatorSearchFilter)
            );
          }}
        >
          <Option value="Or">{convertOperatorToString("Or")}</Option>
          <Option value="And">{convertOperatorToString("And")}</Option>
        </Dropdown>
      </div>
      <div
        style={{
          borderLeft: "solid",
          gap: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ flex: 1, paddingLeft: 10 }}>
          {currentFilter.type === "operator" &&
            (currentFilter as BooleanOperatorSearchFilter).filters.map(
              (f, i) => {
                if (f.type === "operator") {
                  const op = (f as BooleanOperatorSearchFilter).operator;

                  return (
                    <>
                      <PersonFilterEditor
                        key={i}
                        initialValue={f}
                        operator={op}
                        removable={true}
                        valueType={valueType}
                        valueChanged={(newVal) => {
                          if (newVal) {
                            setCurrentFilter((f) => {
                              const newV = f as BooleanOperatorSearchFilter;
                              newV.filters[i] = newVal;
                              return { ...newV };
                            });
                          } else {
                            removeItem(i);
                          }
                        }}
                      ></PersonFilterEditor>
                    </>
                  );
                } else if (f.type === valueType) {
                  const filterType = (f as PersonSearchFilter).searchType;
                  let filterTypeText = "";
                  switch (filterType) {
                    case StringSearchTypes.IsExactly:
                      filterTypeText = "is ";
                      break;
                    case StringSearchTypes.Contains:
                      filterTypeText = "contains ";
                      break;
                    case StringSearchTypes.StartsWith:
                      filterTypeText = "starts with ";
                      break;
                    case StringSearchTypes.EndsWith:
                      filterTypeText = "ends with  ";
                      break;
                  }

                  const selectedFieldNames: string[] = [];
                  const fields = (f as PersonSearchFilter).fields;
                  for (const [, mask] of Object.entries(
                    PersonSearchFields
                  ).filter(([e, m]) => typeof m === "number" && e !== "All")) {
                    if (fields & (mask as number)) {
                      selectedFieldNames.push(
                        convertFieldName(mask as PersonSearchFields)
                      );
                    }
                  }

                  return (
                    <div key={i}>
                      <Button
                        size="small"
                        icon={<DeleteRegular />}
                        title="Remove filter"
                        onClick={() => removeItem(i)}
                      />{" "}
                      {selectedFieldNames.map((t, i) => (
                        <>
                          <b key={i}>{t}</b>
                          {i < selectedFieldNames.length - 2 && <>, </>}
                          {i === selectedFieldNames.length - 2 &&
                            selectedFieldNames.length > 1 && <> or </>}
                        </>
                      ))}{" "}
                      {filterTypeText}
                      <q>{(f as PersonSearchFilter).term}</q>
                    </div>
                  );
                }
                return undefined;
              }
            )}
        </div>
        <div
          style={{ flex: 1, display: "inline-flex", gap: 5, marginLeft: 10 }}
        >
          <Button
            size="small"
            icon={<FilterAddRegular />}
            title="Add filter"
            onClick={() => {
              addItem({
                searchType: dropdownVal,
                term: fieldText,
                fields: selectedFields.reduce(
                  (previous, current) => current | previous,
                  0
                ),
                type: valueType,
              } as PersonSearchFilter);
            }}
          />
          <Button
            size="small"
            icon={<BranchFilled />}
            title="Add condition group"
            onClick={() => {
              addItem({
                type: "operator",
                operator: "Or",
                filters: [],
              } as BooleanOperatorSearchFilter);
            }}
          />
          <div>
            <Dropdown
              style={{ minWidth: 150, width: 150 }}
              size="small"
              multiselect={true}
              selectedOptions={selectedFields.map((o) => o.toString())}
              value={selectedFields
                .map((o) => PersonSearchFields[o].toString())
                .join(", ")}
              onOptionSelect={(_e, d) => {
                setSelectedFields(d.selectedOptions.map((o) => parseInt(o)));
              }}
            >
              {Object.entries(PersonSearchFields)
                .filter(([e, m]) => typeof m === "number" && e !== "All")
                .map(([, f]) => (
                  <Option key={f} value={(f as PersonSearchFields).toString()}>
                    {convertFieldName(f as PersonSearchFields)}
                  </Option>
                ))}
            </Dropdown>
          </div>
          <Dropdown
            style={{ minWidth: "fit-content", width: 100 }}
            size="small"
            defaultValue={dropdownToString(dropdownVal)}
            onOptionSelect={(_e, d) =>
              setDropdownVal(d.optionValue as StringSearchTypes)
            }
          >
            {Object.values(StringSearchTypes).map((t) => (
              <Option key={t} value={t}>
                {dropdownToString(t)}
              </Option>
            ))}
          </Dropdown>
          <Input
            type="text"
            placeholder="Search terms"
            style={{ flex: 1 }}
            value={fieldText}
            size="small"
            onChange={(v) => setFieldText(v.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter")
                addItem({
                  searchType: dropdownVal,
                  term: fieldText,
                  fields: selectedFields.reduce(
                    (previous, current) => current | previous,
                    0
                  ),
                  type: valueType,
                } as PersonSearchFilter);
            }}
          />
          <Button
            size="small"
            icon={<BackspaceRegular />}
            title="Clear"
            onClick={() => {
              setFieldText("");
            }}
          />
          <Button
            size="small"
            icon={<AddFilled />}
            title="Add filter"
            onClick={() => {
              addItem({
                searchType: dropdownVal,
                term: fieldText,
                fields: selectedFields.reduce(
                  (previous, current) => current | previous,
                  0
                ),
                type: valueType,
              } as PersonSearchFilter);
            }}
          />
        </div>
      </div>
    </div>
  );
};
