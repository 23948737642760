import {
  Button,
  Caption1,
  Card,
  CardHeader,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Skeleton,
  SkeletonItem,
  Tooltip,
} from "@fluentui/react-components";
import { Dismiss12Filled, FluentIconsProps } from "@fluentui/react-icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import * as SharePointDriveService from "../../../Services/SharePoint/SharePointDriveService";
import { ContentHubAttachments } from "../../../Types/ContentHub/ContentHubAttachments";
import { ContentHubListsElement } from "../../../Types/ContentHub/ContentHubListsElement";
import { AttachmentFile } from "../../../Types/SharePoint/SharePointDriveItems";
import { TeamsFxContext } from "../../../Context/TeamsFxContext";
import "./FileUploaderCommon.css";

export const FileUploaderCommon = ({
  reload,
  attachment,
  filesOnForm,
  deletedFile,
  fileTypes,
  listDefinition,
  disabled,
  onError,
}: {
  reload: boolean;
  attachment: ContentHubAttachments | null | undefined;
  filesOnForm?: (value: File[]) => void;
  deletedFile?: (value: string) => void;
  fileTypes?: string[] | null | undefined;
  listDefinition: ContentHubListsElement;
  disabled?: boolean;
  onError?: (error: unknown) => void;
}) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);

  const [driveId, setDriveId] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setDriveId(
      listDefinition.fields
        .find((f) => f.name === "_attachments")
        ?.folderId?.split("/")[0]
    );
  }, [listDefinition]);
  //Files
  const [attachmentNames, setAttachmentsName] = useState<File[]>([]); //Files to be uploaded
  const [attachedFiles, setAttachedFiles] = useState<
    AttachmentFile[] | undefined
  >(undefined); //Files already in Drive

  //On Load
  useEffect(() => {
    loadAttachments();
  }, [driveId]);

  //Reload Files
  useEffect(() => {
    if (reload) {
      loadAttachments();
    }
  }, [attachment]);

  //File Uploader Methods
  const filesAttached = useCallback(
    (filesData: FileList) => {
      //Attach Names to Display
      const arrayName: File[] = [];
      for (let i = 0; i < filesData.length; i++) {
        arrayName.push(filesData[i]);
      }
      setAttachmentsName(arrayName);

      //Set Files Objects on Form
      filesOnForm?.(arrayName);
    },
    [filesOnForm]
  );

  //Load Files
  const loadAttachments = useCallback(async () => {
    if (!driveId) return;
    setAttachedFiles(undefined);
    setAttachmentsName([]);
    setErrorMessage(undefined);

    if (attachment) {
      const itemFolderId = attachment.itemFolderId;

      if (
        itemFolderId !== null &&
        itemFolderId !== undefined &&
        itemFolderId !== ""
      ) {
        try {
          const filesInDrive = await SharePointDriveService.getAttachments(
            driveId!,
            itemFolderId,
            teamsUserCredential!
          );
          setAttachedFiles(filesInDrive);
        } catch (error) {
          onError?.(error);
          setAttachedFiles([]);
          setErrorMessage(
            "An error occurred while retrieving the attachments."
          );
        }
      } else {
        const empty: AttachmentFile[] = [];
        setAttachedFiles(empty);
      }
    } else {
      const empty: AttachmentFile[] = [];
      setAttachedFiles(empty);
    }
  }, [attachment, driveId, teamsUserCredential]);

  //Delete Files from Drive
  const deleteAttachedFile = useCallback(
    async (event: React.MouseEvent, fileId: string, fileName: string) => {
      event.stopPropagation();

      await SharePointDriveService.deleteAttachment(
        driveId!,
        fileId,
        teamsUserCredential!
      );
      loadAttachments();
      deletedFile?.(fileName);
    },
    [driveId, loadAttachments, teamsUserCredential]
  );

  //Remove File From Array
  const removeFile = useCallback(
    (filenames: string) => {
      const currentFiles = attachmentNames;

      const newFiles = currentFiles?.filter((file) => {
        return file.name !== filenames;
      });
      setAttachmentsName(newFiles);
      filesOnForm?.(newFiles);
    },
    [attachmentNames, filesOnForm]
  );

  const iconStyleProps: FluentIconsProps = {
    primaryFill: "red",
    className: "iconClass",
  };

  return (
    <div className="attachmentsArea">
      <div id="filesDiv">
        {errorMessage === undefined &&
          (attachedFiles ?? []).map((file) => {
            return (
              <div id={file.id} key={file.id}>
                <Card
                  className="fileCard"
                  onClick={() => window.open(file.webUrl, "_blank")}
                >
                  <CardHeader
                    header={file.name}
                    description={<Caption1>Download</Caption1>}
                    action={
                      !disabled ? (
                        <Tooltip content="Delete File" relationship="label">
                          <Button
                            icon={
                              <Dismiss12Filled
                                {...iconStyleProps}
                              ></Dismiss12Filled>
                            }
                            onClick={(event) =>
                              deleteAttachedFile(event, file.id, file.name)
                            }
                          ></Button>
                        </Tooltip>
                      ) : undefined
                    }
                  ></CardHeader>
                </Card>
              </div>
            );
          })}
        {errorMessage !== undefined && (
          <MessageBar intent="error">
            <MessageBarBody>
              <MessageBarTitle>Error</MessageBarTitle>
              {errorMessage}
            </MessageBarBody>
          </MessageBar>
        )}
        {errorMessage === undefined && (attachedFiles?.length ?? 0) === 0 && (
          <MessageBar key="info-attachments" intent="info">
            <MessageBarBody>
              <MessageBarTitle>Attachments Files: </MessageBarTitle>
              No attachments have been added to this ticket.
            </MessageBarBody>
          </MessageBar>
        )}
        {errorMessage === undefined && attachedFiles === undefined && (
          <Skeleton>
            {Array(3)
              .fill(null)
              .map((_, i) => (
                <div
                  key={i}
                  style={{
                    marginBottom: "4px",
                    marginTop: "4px",
                    display: "grid",
                    gridTemplateColumns: "20ch 20ch min-content",
                    justifyContent: "space-between",
                  }}
                >
                  <SkeletonItem shape="rectangle" />
                  <SkeletonItem shape="rectangle" />
                </div>
              ))}
          </Skeleton>
        )}
      </div>

      <div className="fileUploaderDiv">
        <FileUploader
          disabled={disabled}
          fileOrFiles={null}
          multiple={true}
          handleChange={filesAttached}
          Name="attachments"
          types={fileTypes}
          dropMessageStyle={{ backgroundColor: "#464EB8" }}
        />
      </div>

      {!disabled && attachmentNames.length > 0 && (
        <div id="newfiles">
          <div>
            <h3>Files To Upload:</h3>
          </div>
          <div>
            {attachmentNames.map((file, index) => {
              return (
                <Card className="newFile" key={index}>
                  <CardHeader
                    header={file.name}
                    description={file.size}
                    action={
                      <Tooltip content="Remove attachment" relationship="label">
                        <Button
                          icon={<Dismiss12Filled {...iconStyleProps} />}
                          appearance="transparent"
                          aria-label="More options"
                          shape="circular"
                          onClick={() => removeFile(file.name)}
                        ></Button>
                      </Tooltip>
                    }
                  ></CardHeader>
                </Card>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
