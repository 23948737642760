import * as axios from "axios";
import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import ApplicationConfiguration from "../ApplicationConfiguration";
import { User } from "../Types/General/User";

export async function callUserInfoFunction(
  teamsUserCredential: TeamsUserCredential
): Promise<User> {
  try {
    const apiBaseUrl = ApplicationConfiguration.apiEndpoint + "/api/";
    const apiClient = createApiClient(
      apiBaseUrl,
      new BearerTokenAuthProvider(
        async () => (await teamsUserCredential.getToken(""))!.token
      )
    );
    const response = await apiClient.get("profile");
    return response.data;
  } catch (err) {
    if (axios.default.isAxiosError(err)) {
      let funcErrorMsg = "";

      if (err?.code === "ERR_NETWORK") {
        funcErrorMsg = "Unable to retrieve user information.";
      } else {
        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ": " + err.response.data.error;
        }
      }

      throw new Error(funcErrorMsg);
    }
    throw err;
  }
}

export async function getUserMembershipIds(
  teamsUserCredential: TeamsUserCredential
): Promise<string[]> {
  try {
    const apiBaseUrl = ApplicationConfiguration.apiEndpoint + "/api/";
    const apiClient = createApiClient(
      apiBaseUrl,
      new BearerTokenAuthProvider(
        async () => (await teamsUserCredential.getToken(""))!.token
      )
    );
    const response = await apiClient.get("memberships");
    return response.data;
  } catch (err) {
    if (axios.default.isAxiosError(err)) {
      let funcErrorMsg = "";

      if (err?.code === "ERR_NETWORK") {
        funcErrorMsg = "Unable to retrieve user information.";
      } else {
        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ": " + err.response.data.error;
        }
      }

      throw new Error(funcErrorMsg);
    }
    throw err;
  }
}
