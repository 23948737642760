import {
  Button,
  CounterBadge,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderTitle,
  Field,
  MessageBar,
  MessageBarBody,
  MessageBarIntent,
  MessageBarTitle,
  OverlayDrawer,
  ProgressBar,
  SelectTabData,
  SelectTabEvent,
  Spinner,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { CommentNoteFilled, Dismiss24Regular } from "@fluentui/react-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { AnyObjectSchema } from "yup";
import { ContentHubListContext } from "../../../Context/ContentHubListContext";
import usePrevious from "../../../Hooks/usePrevious";
import { ApprovalRequest } from "../../../Types/Approvals/ApprovalRequest";
import { ApprovalStatus } from "../../../Types/Approvals/ApprovalStatus";
import { ContentHubAttachments } from "../../../Types/ContentHub/ContentHubAttachments";
import { ContentHubItemElement } from "../../../Types/ContentHub/ContentHubItemElement";
import { ContentHubListsElement } from "../../../Types/ContentHub/ContentHubListsElement";
import { ContentHubItemApprovals } from "../ContentHubItemApprovals/ContentHubItemApprovals";
export type ContentHubPanel = {
  render: (
    item: ContentHubItemElement | undefined,
    list: ContentHubListsElement,
    form: UseFormReturn,
    formControls: ContentHubFormControls
  ) => JSX.Element;
};
export type ContentHubFormControls = {
  setTitle: (title: string) => void;
  isSubmitting: boolean;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  handleFiles: (files: File[]) => void;
  deleteFile: (fileName: string) => void;
  setPanelTabs: (tabs: string[]) => void;
  canSubmit: boolean;
  setCanSubmit: (value: boolean) => void;
  canEdit: boolean;
  setCanEdit: (value: boolean) => void;
  canEditAttachments: boolean;
  setCanEditAttachments: (value: boolean) => void;

  setControls: (controls: JSX.Element[]) => void;
  submitWithoutValidation: (data: FieldValues) => void;
  setMessages: (
    messages: { intent?: MessageBarIntent; title?: string; message: string }[]
  ) => void;
  approvalItems: ApprovalRequest[];
};

export function ContentHubForm({
  selectedItem,
  open,
  onOpenChange,
  onSave,
}: {
  selectedItem?: ContentHubItemElement;
  open: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  onSave?: (item: ContentHubItemElement) => void;
}) {
  const listContext = useContext(ContentHubListContext);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");

  const [selectedTab, setSelectedTab] = useState<string>("Approvals");

  const [tabs, setTabs] = useState<string[]>([]);
  const [controls, setControls] = useState<JSX.Element[]>([]);
  const onTabSelect = (_event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value as string);
  };

  const [approvalItems, setApprovalItems] = useState<ApprovalRequest[]>([]);
  const [isLoadingApprovalHistory, setIsLoadingApprovalHistory] =
    useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const [canEditRequest, setCanEditRequest] = useState<boolean>(true);
  const [canEditAttachments, setCanEditAttachments] = useState<boolean>(true);

  const [messages, setMessages] = useState<
    { intent?: MessageBarIntent; title?: string; message: string }[]
  >([]);

  useEffect(() => {
    setSelectedTab(tabs.length > 0 ? tabs[0] : "Approvals");
    if (selectedItem === undefined) {
      setCanSubmit(true);
      setCanEditRequest(true);
      setCanEditAttachments(true);
    } else {
      setCanSubmit(false);
      setCanEditRequest(false);
      setCanEditAttachments(false);
    }
  }, [selectedItem]);

  const form = useForm({
    mode: "all",
    resolver: yupResolver(
      listContext.registry?.schema ?? ({} as AnyObjectSchema)
    ),
  });

  const [filesToUpload, setFilesToUpload] = useState<File[] | undefined>(
    undefined
  );
  const handleFiles = (files: File[]) => {
    const attachments: ContentHubAttachments = form.getValues(
      "_attachments"
    ) ?? {
      itemFolderId: "",
      filenames: [""],
    };

    if (files.length !== 0) {
      form.setValue("_attachments", attachments, { shouldValidate: true });
    } else {
      form.setValue("_attachments", null, {
        shouldValidate: true,
      });
    }
    setFilesToUpload(files);
  };

  //Delete File on Array
  const deleteFile = (fileName: string) => {
    const filesInForm = form.getValues("_attachments");

    if (filesInForm !== undefined && filesInForm !== null) {
      if (
        filesInForm.filenames !== undefined &&
        filesInForm.filenames !== null &&
        filesInForm.filenames.length > 0
      ) {
        const newFiles = filesInForm.filenames.filter(
          (i: string) => i !== fileName
        );
        filesInForm.filenames = newFiles;
        form.setValue("_attachments", filesInForm, {
          shouldValidate: true,
        });
      }
    }
  };

  const previousState = usePrevious(listContext.listState);

  const submitForm: SubmitHandler<FieldValues> = useCallback(
    (data: FieldValues) => {
      if (selectedItem === undefined) {
        listContext.dispatchCreateItem(data, filesToUpload);
      } else {
        listContext.dispatchUpdateItem(selectedItem.id, data, filesToUpload);
      }
    },
    [selectedItem, filesToUpload]
  );

  useEffect(() => {
    switch (listContext.listState.activity) {
      case "creatingItem":
      case "updatingItem":
        setSubmitting(true);
        break;
      default:
        if (listContext.listState.errors.length > 0) {
          setMessages(
            listContext.listState.errors.map((e) => ({
              intent: "error",
              title: "Error",
              message: e.error,
            }))
          );
          setSubmitting(false);
        } else {
          if (previousState?.activity === "creatingItem") {
            onSave?.(listContext.listState.items[0]);
            setMessages([
              {
                intent: "success",
                title: "Success",
                message: "Item created successfully.",
              },
            ]);
          } else if (previousState?.activity === "updatingItem") {
            onSave?.(
              listContext.listState.items.find(
                (item) => item.id === selectedItem?.id
              )!
            );
            setMessages([
              {
                intent: "success",
                title: "Success",
                message: "Item updated successfully.",
              },
            ]);
          }
          setFilesToUpload(undefined);
          setSubmitting(false);
        }
    }
  }, [listContext.listState.activity]);

  useEffect(() => {
    if (open === false) {
      setMessages([]);
      setFilesToUpload(undefined);
      setSubmitting(false);
    }
  }, [open]);

  const render = useCallback(
    (args: {
      item: ContentHubItemElement | undefined;
      list: ContentHubListsElement;
      formControls: ContentHubFormControls;
    }) =>
      listContext.registry?.form !== undefined ? (
        createElement(listContext.registry!.form, args)
      ) : (
        <></>
      ),
    [listContext.registry]
  );
  return (
    <OverlayDrawer
      size="large"
      position="end"
      modalType="alert"
      open={open}
      onOpenChange={(evt, data) => onOpenChange?.(data.open)}
    >
      {open && (
        <>
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular></Dismiss24Regular>}
                  onClick={() => {
                    onOpenChange?.(false);
                  }}
                ></Button>
              }
            >
              {title}
            </DrawerHeaderTitle>
            <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
              {tabs.map((tab, index) => (
                <Tab key={index} value={tab}>
                  {tab}
                </Tab>
              ))}

              {selectedItem !== undefined && (
                <Tab
                  id="Approvals"
                  value="Approvals"
                  icon={
                    isLoadingApprovalHistory ? (
                      <Spinner size="extra-tiny" />
                    ) : undefined
                  }
                >
                  Approvals
                  {!isLoadingApprovalHistory &&
                    approvalItems.filter((a) => a.comment).length > 0 && (
                      <>
                        {" "}
                        <CommentNoteFilled />
                      </>
                    )}
                  {!isLoadingApprovalHistory && (
                    <>
                      {" "}
                      <CounterBadge count={approvalItems.length} />
                    </>
                  )}
                </Tab>
              )}
            </TabList>
          </DrawerHeader>
          <DrawerBody style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div
              style={{
                display: selectedTab !== `Approvals` ? undefined : "none",
              }}
            >
              <FormProvider {...form}>
                {form && listContext.list ? (
                  render({
                    item: selectedItem,
                    list: listContext.list,
                    formControls: {
                      setTitle,
                      isSubmitting: submitting,
                      deleteFile,
                      handleFiles,
                      setSelectedTab,
                      setPanelTabs: setTabs,
                      selectedTab,
                      canEdit: canEditRequest,
                      setCanEdit: setCanEditRequest,
                      canEditAttachments,
                      setCanEditAttachments,
                      canSubmit,
                      setCanSubmit,
                      setControls,
                      submitWithoutValidation: submitForm,
                      setMessages,
                      approvalItems,
                    },
                  })
                ) : (
                  <></>
                )}
              </FormProvider>
            </div>
            {selectedItem !== undefined && (
              <div
                style={{
                  display: selectedTab === "Approvals" ? undefined : "none",
                }}
              >
                <ContentHubItemApprovals
                  siteId={selectedItem.siteId}
                  listId={selectedItem.listId}
                  itemId={selectedItem.id}
                  status={ApprovalStatus.All}
                  onLoadStatusChange={(s) => setIsLoadingApprovalHistory(s)}
                  onLoadComplete={(_, items) => {
                    setApprovalItems(items);
                  }}
                />
              </div>
            )}
          </DrawerBody>
          <DrawerFooter>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                gap: 10,
              }}
            >
              {messages.map((m, i) => (
                <MessageBar key={i} intent={m.intent}>
                  <MessageBarBody>
                    {m.title && <MessageBarTitle>{m.title}</MessageBarTitle>}
                    {m.message}
                  </MessageBarBody>
                </MessageBar>
              ))}
              <div
                style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  gap: 10,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div className="submitbutton">
                  {submitting === false ? (
                    <Button
                      appearance="primary"
                      onClick={() => {
                        form.handleSubmit(submitForm, () =>
                          console.log(form.formState.errors)
                        )();
                      }}
                      disabled={
                        !canSubmit ||
                        (form.formState.isValid === false &&
                          Object.keys(form.formState.errors).length > 0)
                      }
                    >
                      Save
                    </Button>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <Field
                        validationMessage="Please Wait... Saving and Uploading Files."
                        validationState="none"
                      >
                        <ProgressBar />
                      </Field>
                    </div>
                  )}
                </div>
                {controls}
              </div>
            </div>
          </DrawerFooter>
        </>
      )}
    </OverlayDrawer>
  );
}
