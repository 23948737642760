import {
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
} from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import { DeepLinkContext } from "../../../Context/DeepLinkContext";
import { TeamsFxContext } from "../../../Context/TeamsFxContext";
import { UserContext } from "../../../Context/UserContext";
import { ApprovalsComponent } from "../../Approvals/Approvals/Approvals";

//Get Info From MS Graph Function App

export default function ApprovalRequestsTab() {
  const { themeString } = useContext(TeamsFxContext);
  const user = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState<TabValue>("received");
  const [selectedItemId, setSelectedItemId] = useState<string>("");
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value);
  };

  const [deepLink, setDeepLink] = useContext(DeepLinkContext);

  useEffect(() => {
    if (deepLink == null) return;
    setDeepLink(undefined);
    const locationMatch = matchPath(
      "/requests/:selectedTab/:requestId?",
      deepLink
    );
    const selectedTabName = locationMatch?.params["selectedTab"];
    const requestId = locationMatch?.params["requestId"];
    setSelectedTab(selectedTabName);
    if (requestId) {
      setSelectedItemId(requestId);
    }
  }, [deepLink, setDeepLink]);

  const clearSelectedItemId = () => setSelectedItemId("");
  return (
    <div
      className={
        themeString === "default"
          ? "light"
          : themeString === "dark"
          ? "dark"
          : "contrast"
      }
    >
      <div className="welcome page flexfill nooverflow">
        <div className="narrow page-padding flexfill nooverflow">
          <>
            <div className="flexfill nooverflow">
              <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                <Tab id="Approvals" value="received">
                  Approvals
                </Tab>
                <Tab id="Requests" value="sent">
                  Requests
                </Tab>
              </TabList>
              <div
                style={{ marginTop: "15px" }}
                className="flexfill nooverflow"
              >
                <div
                  style={{
                    display: selectedTab === "received" ? "flex" : "none",
                  }}
                  className="flexfill nooverflow"
                >
                  <ApprovalsComponent
                    email={user.userInfo!.mail}
                    listType={"Approvers"}
                    selectedId={
                      selectedTab === "received" ? selectedItemId : ""
                    }
                    onDrawerClosed={clearSelectedItemId}
                  />
                </div>
                <div
                  style={{
                    display: selectedTab === "sent" ? "flex" : "none",
                  }}
                  className="flexfill nooverflow"
                >
                  <ApprovalsComponent
                    email={user.userInfo!.mail}
                    listType={"Requestor"}
                    selectedId={selectedTab === "sent" ? selectedItemId : ""}
                    onDrawerClosed={clearSelectedItemId}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}
