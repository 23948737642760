import {
  MessageBar,
  MessageBarBody,
  MessageBarGroup,
  MessageBarTitle,
} from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { ContentHubContext } from "../../../Context/ContentHubContext";
import { ContentHubListContext } from "../../../Context/ContentHubListContext";
import { ContentHubItemElement } from "../../../Types/ContentHub/ContentHubItemElement";
import { ContentHubSearchControls } from "../ContentHubSearchControls/ContentHubSearchControls";
import { ContentHubTable } from "../ContentHubTable/ContentHubTable";
export default function ContentHubList<T = unknown>({
  onItemClicked,
}: {
  onItemClicked?: (item: ContentHubItemElement<T>) => void;
}) {
  const contentHubContext = useContext(ContentHubContext);
  const listContext = useContext(ContentHubListContext);

  const [canDeleteItems, setCanDeleteItems] = useState<boolean>(false);
  const [canManageViews, setCanManageViews] = useState<boolean>(false);
  useEffect(() => {
    if (
      listContext.registry &&
      listContext.list &&
      contentHubContext.isLoading === false
    ) {
      const indexCanDeleteItems = contentHubContext.permissions.findIndex(
        (p) =>
          p === "ContentHub.Admin" ||
          p === "Items.Manage" ||
          p === `${listContext.listState.siteId}/Items.Manage` ||
          p ===
            `${listContext.listState.siteId}/${listContext.listState.listId}/Items.Manage`
      );
      const indexCanManageViews = contentHubContext.permissions.findIndex(
        (p) =>
          p === "ContentHub.Admin" ||
          p === "Lists.Manage" ||
          p === `${listContext.listState.siteId}/Lists.Manage` ||
          p ===
            `${listContext.listState.siteId}/${listContext.listState.listId}/Lists.Manage`
      );

      setCanDeleteItems(indexCanDeleteItems >= 0);
      setCanManageViews(indexCanManageViews >= 0);
      listContext.dispatchLoadItems();
    }
  }, [listContext.registry, listContext.list, contentHubContext.isLoading]);

  return (
    <div className="flexfill nooverflow">
      <MessageBarGroup>
        {listContext.listState.errors
          .filter((err) => {
            return (
              err.activity !== "creatingItem" && err.activity !== "updatingItem"
            );
          })
          .map((err, errorIndex) => (
            <MessageBar key={errorIndex} intent="error">
              <MessageBarBody>
                <MessageBarTitle>Error</MessageBarTitle>
                {err.error}
              </MessageBarBody>
            </MessageBar>
          ))}
      </MessageBarGroup>
      {listContext.list && (
        <>
          <div className="flexfill nooverflow">
            <ContentHubSearchControls
              disabled={listContext.isWorking}
              enableViewSave={
                canManageViews &&
                listContext.isWorking === false &&
                listContext.listState.viewId !== undefined
              }
              enableViewSaveAs={
                canManageViews && listContext.isWorking === false
              }
            />
            <ContentHubTable<T>
              onItemClicked={onItemClicked}
              canDeleteItems={canDeleteItems}
            ></ContentHubTable>
          </div>
        </>
      )}
    </div>
  );
}
