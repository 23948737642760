import { useState, useEffect } from "react";
type SelectedContentHubType = {
  selectedSite?: string;
  selectedList?: string;
  selectedItem?: string;
  selectedView?: string;
  setSelectedSite: (value?: string) => void,
  setSelectedList: (value?: string) => void,
  setSelectedItem: (value?: string) => void,
  setSelectedView: (value?: string) => void
};
export default function useSelectedContentHub(): SelectedContentHubType {
  const [site, setSite] = useState<string | undefined>(undefined);
  const [list, setList] = useState<string | undefined>(undefined);
  const [item, setItem] = useState<string | undefined>(undefined);
  const [view, setView] = useState<string | undefined>(undefined);
  const [selectedContentHub, setSelectedContentHub] =
    useState<SelectedContentHubType>({
      selectedSite: site,
      selectedList: list,
      selectedItem: item,
      selectedView: view,
      setSelectedSite: setSite,
      setSelectedList: setList,
      setSelectedItem: setItem,
      setSelectedView: setView,
    });

  useEffect(() => {
    setSelectedContentHub({
      selectedSite: site,
      selectedList: list,
      selectedItem: item,
      selectedView: view,
      setSelectedSite: setSite,
      setSelectedList: setList,
      setSelectedItem: setItem,
      setSelectedView: setView,
    });
  }, [site, list, item, view, setSite, setList, setView, setItem]);

  return selectedContentHub;
}
