import { object, string, ObjectSchema, array } from 'yup';

export interface ContentHubAttachments {
    itemFolderId?: string | null,
    filenames?: string[] | null,
    filesToUpload?: string[] | null
}

export const ContentHubAttachmentsSchema: ObjectSchema<ContentHubAttachments> = object({    
    itemFolderId: string().nullable(),    
    filenames: array().when("itemFolderId", {
        is: (value: string | null) => (value !== undefined && value !== null && value !== ""),
        then: (schema) => schema.min(1, "Must have at least one attachment.").of(string().defined()),
        otherwise: (schema) => schema.of(string().defined()).nullable().defined()
    }),
    filesToUpload: array().when("filenames", {
        is: (value: string | null) => (value === undefined || value?.length === 0),
        then: (schema) => schema.min(1, "Must have attachments queued to upload.").of(string().defined()),
        otherwise: (schema) => schema.of(string().defined()).nullable()
    })
})
