import {
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
} from "@fluentui/react-components";
import { useCallback, useState } from "react";
import { IContentHubSearchFilter } from "../../../Types/ContentHub/ContentHubSearchFilter";
import { ContentHubFilterLayerEditor } from "./ContentHubFilterLayerEditor";
import { ContentHubListRegistry } from "../../../Types/ContentHub/Registry/ContentHubListRegistry";

export const ContentHubMultilayerFilterEditor = ({
  filterControls,
  layers,
  registry,
  filterChanged,
}: {
  filterControls: Record<
    string,
    {
      propertyName: string;
      renderer: (
        currentValue: IContentHubSearchFilter | null,
        callback: (
          key: string,
          newValue: IContentHubSearchFilter | null
        ) => void
      ) => JSX.Element;
    }
  >;
  layers: Record<string, IContentHubSearchFilter | null>[];
  registry: ContentHubListRegistry;
  filterChanged?: (
    layer: number,
    columnKey: string,
    filter: IContentHubSearchFilter | null
  ) => void;
}) => {
  const [selectedValue, setSelectedValue] = useState<TabValue>(0);

  const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const onFilterChanged = useCallback(
    (
      layer: number,
      columnKey: string,
      filter: IContentHubSearchFilter | null
    ) => {
      filterChanged?.(layer, columnKey, filter);
    },
    [filterChanged]
  );
  return (
    <div>
      <TabList
        selectedValue={selectedValue}
        onTabSelect={onTabSelect}
      >
        {layers.map((_, index) => (
          <Tab key={index} value={index}>
            Layer {index + 1}
          </Tab>
        ))}
      </TabList>
      {layers.map((layer, index) => (
        <div
          key={index}
          style={{ display: selectedValue !== index ? "none" : undefined }}
        >
          <ContentHubFilterLayerEditor
            filterControls={filterControls}
            filters={layer}
            registry={registry}
            filterChanged={(key, filter) =>
              onFilterChanged?.(index, key, filter)
            }
          />
        </div>
      ))}
    </div>
  );
};
