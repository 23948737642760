import { Field } from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { useDebouncedCallback } from "use-debounce";
import * as UserService from "../../../Services/General/UserService";
import { ContentHubUser } from '../../../Types/General/ContentHubUser';
import { TeamsFxContext } from "../../../Context/TeamsFxContext";
import { getSelectStyle, getSelectTheme } from "../CommonSelect/CommonSelect";

export const SelectPerson = <T extends FieldValues>({
  label,
  group,
  control,
  name,
  multiple,
  required,
  disabled,
  onError,
}: {
  label: string;
  group?: string | undefined;
  control: Control<T>;
  name: Path<T>;
  multiple?: boolean;
  required?: boolean;
  disabled?: boolean;
  onError?: (error: unknown) => void;
}) => {
  const [requiredField, setRequiredField] = useState(required);
  useEffect(() => {
    setRequiredField(required);
  }, [required]);
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
  const controller = useController({
    control,
    name,
  });

  const searchCallback = useDebouncedCallback((inputValue, callback) => {
    (async () => {
      let options: ContentHubUser[];
      try {
        if (group !== undefined) {
          options = await UserService.getUserInGroup(
            inputValue,
            group,
            teamsUserCredential!
          );
        } else {
          options = await UserService.getUser(inputValue, teamsUserCredential!);
        }
      } catch (error) {
        console.log(error);
        options = [];
        onError?.(error);
      }
      callback(options);
    })();
  }, 330);
  return (
    <Field
      label={label}
      required={requiredField !== undefined ? requiredField : false}
    >
      <AsyncSelect<ContentHubUser, boolean>
        {...controller.field}
        cacheOptions={true}
        styles={getSelectStyle(themeString)}
        theme={getSelectTheme(themeString)}
        loadOptions={searchCallback}
        getOptionValue={(option) => `${option.name}`}
        getOptionLabel={(option) => `${option.name}`}
        isMulti={multiple}
        isClearable={true}
        noOptionsMessage={() => "Search for a User"}
        isDisabled={disabled}
        //onBlur={onBlur}
      />
    </Field>
  );
};
